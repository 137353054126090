// // Import TinyMCE
// import tinymce from '@ses-education/content-editor';


// this is forbackward compatibility, so that we don't have to change the import in all plugins
import { tinymce } from "./editor";
export default tinymce;

// // Default icons are required for TinyMCE 5.3 or above
// import 'tinymce/icons/default/icons';

// // A theme is also required
// import 'tinymce/themes/silver/theme';

// // Any plugins you want to use has to be imported
// import 'tinymce/plugins/help'
// import 'tinymce/plugins/advlist'
// import 'tinymce/plugins/autolink'
// import 'tinymce/plugins/lists'
// import 'tinymce/plugins/link'
// import 'tinymce/plugins/image'
// import 'tinymce/plugins/print'
// // import 'tinymce/plugins/preview'
// import 'tinymce/plugins/anchor'
// import 'tinymce/plugins/searchreplace'
// import 'tinymce/plugins/visualblocks'
// import 'tinymce/plugins/code'
// import 'tinymce/plugins/fullscreen'
// import 'tinymce/plugins/insertdatetime'
// import 'tinymce/plugins/media'
// import 'tinymce/plugins/table'
// import 'tinymce/plugins/paste'
// import 'tinymce/plugins/code'
// import 'tinymce/plugins/wordcount'
// import 'tinymce/plugins/charmap'

// // import './plugins/example/index'
// // Initialize the app
// // tinymce.init({
// //   // selector: '#tiny',
// //   plugins: ['paste', 'link', 'example']
// // });

// export default tinymce
import { Language, Translate } from "@material-ui/icons";
import React from "react";
import {
  // Grid,
  Button,
  ButtonGroup,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import "./translation-select.scss"
import TranslationsService from "../../../services/translations-service";

const AddTranslationDialog = ({
  onAdd,
  languages,
  translationMenuTarget,
  onClose,
}) => {
  console.debug(
    "AddTranslationDialog translationMenuTarget:",
    translationMenuTarget
  );
  return Array.isArray(languages) ? (
    <Menu
      anchorEl={translationMenuTarget}
      open={Boolean(translationMenuTarget)}
      {...{ onClose }}
    >
      {languages.map((l) => (
        <MenuItem
          key={l["iso639-1"]}
          value={l["iso639-1"]}
          onClick={() => onAdd(l["iso639-1"])}
        >
          {l["local_name"]}
        </MenuItem>
      ))}
    </Menu>
  ) : (
    <span>no languages</span>
  );
};

/**
 * Renders a translation selector using an array of translations (objects that contain field "language_id" that is an iso369-1 (two-letter) language representation)
 * E.g. [ {title: "English", language_id: "en"}, {title: "Spanish", language_id: "es"}]
 */
class TranslationSelect extends React.Component {
  state = {
    translationMenuTarget: null,
    languages: null
  };

  componentDidMount(){
    this.fetchLanguages();
  }

  async fetchLanguages(){
    const languages = await TranslationsService.getLanguages() 
    this.setState({languages})
  }

  render() {
    const {
      icon = <Language  color="secondary"/>,
      loading = <Button>loading...</Button>,
      translations, // array of translations
      translation, // current translation code (two letters, e.g. 'zh')
      onAdd, // callback to call when language to add is selected. Receives the language code as argument
      onSelect, // callback to call when language is selected. Receives the language code as argument
      showEnglish = false,
      hideIcon,
    } = this.props;
    const {languages} = this.state // array of available languages (objects that contain field 'language_id')

    const { translationMenuTarget } = this.state;

    const translationLanguages = Array.isArray(translations) ? translations.map( t => t.language_id ) : []

    // Languages to show under Add button. Showing only languages not used
    const languagesToShow =  (
      showEnglish ? 
      // if show english - leave everything in
      languages : 
      // show english is false - let's filter english out...
      Array.isArray(languages) ? 
      // ...but only if languages is an array
      languages.filter( l => l["iso639-1"] !== "en" )     
      // plus show only languages not yet used in translations
      .filter( l =>  !translationLanguages.includes( l["iso639-1"] ) )
      // languages is not an array - return as is
      : languages)

    const translationsToShow = showEnglish ? translations : Array.isArray(translations) ? translations.filter( t => t.language_id !== "en") : translations; 
    return (
      <div className="translation-select">
        <ButtonGroup>
          {!hideIcon && 
            <Button variant="outlined" disableRipple size="small">
              {icon}
            </Button>
          }
          {Array.isArray(translationsToShow) &&
            translationsToShow.map((t) => (
              <Button
                onClick={() => onSelect(t.language_id) }
                variant="contained"
                color={translation === t.language_id ? "primary" : null} size="small"
                className={translation === t.language_id ? "active" : ""}
                data-translation={`${translation} - ${t.language_id}`}
              >
                {t.language_id}
              </Button>
            ))}
          {Array.isArray(languagesToShow) && languagesToShow.length > 0 && (
            <Button
              onClick={({ currentTarget: translationMenuTarget }) => {
                console.debug("add button clicked", translationMenuTarget);
                // set translation menu target to this button
                this.setState({ translationMenuTarget });
              }}
              variant="contained" size="small"
            >
              <Add />
            </Button>
          )}
          {!Array.isArray(translations) && { loading }}
        </ButtonGroup>

        { Array.isArray(languagesToShow) && (
          <AddTranslationDialog
            {...{
              languages: languagesToShow,
              onAdd: (l) => this.setState({ translationMenuTarget: null }, () => onAdd(l) ) ,
              translationMenuTarget,
              onClose: () => this.setState({ translationMenuTarget: null }),
            }}
          />
        )
        }
      </div>
    );
  }
}

export default TranslationSelect;

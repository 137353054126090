import tinymce from '../../tiny';
import Service from '../../../../../../services/experiments';
import { charMap, copyToClipboard } from '../charMap';
import { valueOf } from '../utils';

tinymce.PluginManager.add('question', function (editor) {
  let contextMenuQuestionId = null,
    contextMenuQuestionType = null;


  /**
  * Parse the content for elements of text-field type and add click listener to them that opens editor
  */
  editor.on('init', function() {
    console.debug("Initializing text field plugin");
    // Access the editor's content body
    var contentBody = editor.getBody();

    // Query for elements with 'data-question-id'
    var questionElements = contentBody.querySelectorAll('[data-question-type="text-field"]');
    console.debug("...found", questionElements.length, "questions on page");
    // Add click listener to each element
    questionElements.forEach(function(element) {
        element.addEventListener('click', function(event) {
            // Prevent default click behavior
            event.preventDefault();
            event.stopPropagation();

            const questionId = this.getAttribute('data-question-id');
            
            // Implement your logic to handle the click event
            console.debug('Edit question:', questionId);
            editQuestion(questionId);
        });
    });
  });

  /**
   * This opens the dialog to edit an existing question
   * @param {*} question_tag 
   */
  const editQuestion = async ( question_tag) => {

    const { course_id, course_experiment_id } = editor.settings;
    const serverRes = await Service.getQuestion(
      course_id,
      course_experiment_id,
      question_tag
    );
    if (serverRes) {
      const { question_data, question_id } = serverRes;

      openDialog({
        // have to mix in the question ID.
        // Also had to cast it to string for it was giving problems with input type for some reason.
        ...question_data,

        question_id: question_id.toString(), // -> { desc, title, minVal, maxVal, question_id }
      });
    } else {
      // TODO: show notification or tinymce alert
      console.error('Error fetching question data');
    }
  };

  const openDialog = function (config) {
    return editor.windowManager.open({
      title: 'Question',
      body: {
        type: 'panel',

        items: [
          {
            type: 'collection',
            name: 'charMaps',
            label: 'Special characters',
          },
          {
            type: 'input',
            label: 'Question ID (empty if creating a new question)',
            disabled: true,
            inputMode: 'numeric',
            name: 'question_id',
          },
          {
            type: 'input',
            name: 'title',
            label: 'Question Title',
          },
          {
            type: 'input',
            name: 'desc',
            label: 'Units',
          },
          {
            type: 'input',
            inputMode: 'decimal',
            name: 'minVal',
            label: 'Minimum answer value (real)',
          },
          {
            type: 'input',
            inputMode: 'decimal',
            name: 'maxVal',
            label: 'Maximum answer value (real)',
          },
        ],
      },
      buttons: [
        {
          type: 'cancel',
          text: 'Close',
        },
        {
          type: 'submit',
          text: config ? 'Update' : 'Save',
          primary: true,
        },
      ],
      initialData: {
        // use valueOf() function to fallback to default value.

        // add question id so that if it's an existing record, it will be updated on api call
        question_id: valueOf('question_id', config),
        title: valueOf('title', config),
        desc: valueOf('desc', config),
        minVal: valueOf('minVal', config),
        maxVal: valueOf('maxVal', config),

        // question_id: config ? config['question_id'] : '',
        // title: config ? config['title'] : '',
        // desc:  config ? config['desc'] : '',
        // minVal: config ? config['minVal'] : '',
        // maxVal: config ? config['maxVal'] : '',

        charMaps: charMap(),
      },

      onSubmit: async function (api) {
        const data = api.getData();

        const { question_id, desc, title, minVal, maxVal } = data;
        const { course_id, course_experiment_id } = editor.settings;
        let questionData = {
          desc,
          title,
          minVal,
          maxVal,
          question_type: 'text-field',
        };

        // add question id ONLY if it's not empty. Otherwise an "undefined" or "null" string is sent and an error returns.
        // NB: question_id value in qustion data triggers update instead of adding record.
        if (parseInt(question_id) > 0)
          questionData = {
            ...questionData,
            question_id,
          };

        const questionID = await Service.updateQuestion(
          course_id,
          course_experiment_id,
          questionData
        );

        // Insert content when the window form is submitted.
        // data-updated inserts current timestamp and show the editor that the value was changed.
        const result = `<span data-question-id="${questionID}" data-question-type="text-field" class="question q-value" data-updated="${Date.now()}">${'-----'}</span> `;

        if (config && config.question_id) {
          // when editing, delete the old question

          // find the old question
          const elm = editor.dom.select(
            `[data-question-id="${config.question_id}"]`
          )[0];
          // this removes only the found element
          if (elm) editor.dom.remove(elm);
        }
        editor.insertContent(result);
        api.close();
      },
      onAction: function (api, details) {
        if (details.value !== '') {
          copyToClipboard(details.value);
        }
      },
    });
  };

  // Add a button that opens a window
  editor.ui.registry.addButton('question', {
    text: 'Value Question',
    //function fires when Value Question is pressed in menu
    onAction: function () {
      // Open window
      openDialog();
    },
  });

  //adds the ability to click the Edit question option in the context menu
  editor.ui.registry.addContextMenu('question', {
    //function fires when context menu is opened (right clicking an element)
    update: function (element) {
      // parse the ID && TYPE from the element, save in global variables
      contextMenuQuestionId = element.dataset['questionId'];
      contextMenuQuestionType = element.dataset['questionType'];

      //open context menu only if questionId is present and questionType is value
      //no backwards compatibility for old questions
      const result =
        Boolean(contextMenuQuestionId) &&
        contextMenuQuestionType === 'text-field'
          ? 'question'
          : '';

      return result;
    },
  });

  // Adds the Edit question option when right clicked
  editor.ui.registry.addMenuItem('question', {
    text: 'Edit Question',

    //function fires when question context menu selected
    onAction: async function () {
      const { course_id, course_experiment_id } = editor.settings;
      const serverRes = await Service.getQuestion(
        course_id,
        course_experiment_id,
        contextMenuQuestionId
      );
      if (serverRes) {
        const { question_data, question_id } = serverRes;

        openDialog({
          // have to mix in the question ID.
          // Also had to cast it to string for it was giving problems with input type for some reason.
          ...question_data,

          question_id: question_id.toString(), // -> { desc, title, minVal, maxVal, question_id }
        });
      } else {
        // TODO: show notification or tinymce alert
        console.error('Error fetching question data');
      }
    },
  });

  return {
    getMetadata: function () {
      return {
        name: 'Question',
      };
    },
  };
});

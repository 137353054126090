import { Box, Container, List, ListItem, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import Error from "../../template/Error";
import NotificationsSettings from "./notifications-settings";
import CourseTypes from "./course-types";
import {AutoBreadcrumbs} from "@ses-education/courses-components";
import { Home, Message, SettingsApplications } from "@material-ui/icons";
import ListIcon from "@material-ui/icons/List";
import MenuBlock from "../../template/menu-block";
import { connect } from "react-redux";

const pages = {
  notifications: {
    id: "notifications",
    component: NotificationsSettings,
    title: "Notifications",
    icon: <Message />,
    credentials: ["*"],
    to: "/settings/notifications",
  },
  "course-types": {
    id: "course-types",
    component: CourseTypes,
    title: "Course Types",
    icon: <ListIcon />,
    credentials: [],
    to: "/settings/course-types",
  },
};

class Settings extends React.Component {
  render() {
    const { page } = this.props.match.params;
    const { user } = this.props;
    const PageComponent = page ? pages[page].component || Error : null;

    let breadcrumbs = [
      { icon: <Home />, to: "/" },
      { to: "/settings", icon: <SettingsApplications />, text: "Settings" },
    ];

    if (PageComponent) {
      breadcrumbs = [
        ...breadcrumbs,
        { text: pages[page].title, to: pages[page].to, icon: pages[page].icon },
      ];
    }

    return (
      <div className="settings page">
        {PageComponent ? (
          <PageComponent breadcrumbs={breadcrumbs} match={this.props?.match} />
        ) : (
          <div>
            <AutoBreadcrumbs items={breadcrumbs} />
            <Typography variant="h1">Settings</Typography>
            <MenuBlock items={Object.values(pages)} user={user} />
            {/* <List component={Box} sx={{ bgcolor: "background.paper" }}>
              <ListItem button component={Link} to="/settings/notifications">
                <Message /> Notifications
              </ListItem>
              <ListItem button component={Link} to="/settings/course-types">
                <ListIcon /> Course types
              </ListItem>
            </List> */}
          </div>
        )}
      </div>
    );
  }
}

export default connect((state) => ({ user: state.user }))(Settings);

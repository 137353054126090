import React from "react";
import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@material-ui/core";
import {
  ChevronLeft,
  Mail,
  Dashboard,
  Settings,
  School,
  ViewList,
  People,
  AccountBalance,
  Translate,
} from "@material-ui/icons";
import { NavLink } from "react-router-dom";
import "./sidebar.scss";
import { connect } from "react-redux";
import A from "../../../redux/actionTypes";
import { arraysIntersect } from "../../../utils/functions";

const menu = [
  {
    text: "Dashboard",
    link: "/",
    icon: <Dashboard />,
    exact: true,
    credentials: ["content-translator", "interface-translator", "admin"],
  },
  {
    text: "Courses",
    link: "/courses",
    icon: <School />,
    credentials: ["admin"],
  },
  {
    text: "Programs",
    link: "/programs",
    icon: <ViewList />,
    credentials: ["admin"],
  },
  {
    text: "Users",
    link: "/users",
    icon: <People />,
    credentials: ["admin"],
  },
  {
    text: "Organizations",
    link: "/organizations",
    icon: <AccountBalance />,
    credentials: ["admin"],
  },
  {
    text: "Translations",
    link: "/translations",
    icon: <Translate />,
    credentials: ["content-translator", "interface-translator", "admin"],
  },
  {
    text: "Settings",
    link: "/settings",
    icon: <Settings />,
    credentials: ["content-translator", "interface-translator", "admin"],
  },
];

class Sidebar extends React.Component {
  render() {
    const { open = true, toggleDrawer, user } = this.props;
    let { credentials } = user || {};

    // prepare to check credentials for menu
    credentials = Array.isArray(credentials) ? credentials : [];
    const hasCredentials = (cred) =>
      Array.isArray(cred) && arraysIntersect(cred, credentials);

    return (
      <Drawer
        variant="persistent"
        anchor="left"
        open={open}
        className="sidebar"
      >
        <div className="drawer-header">
          <IconButton onClick={toggleDrawer}>
            <ChevronLeft />
          </IconButton>
        </div>
        <Divider />
        <List>
          {menu.map((item, index) => {
            const { text, icon = <Mail />, link = "#", exact = false } = item;

            return (
              hasCredentials(item.credentials) && (
                <ListItem
                  className="drawer-menu-item"
                  exact={exact}
                  button
                  key={text}
                  component={NavLink}
                  to={link}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              )
            );
          })}
        </List>
        <Divider />
      </Drawer>
    );
  }
}

export default connect(
  (state) => ({ open: state.showDrawer, user: state.user }),
  (dispatch) => ({
    toggleDrawer: () => dispatch({ type: A.TOGGLE_DRAWER }),
  })
)(Sidebar);

import uniquid from 'uniquid';
import tinymce from '../../tiny';
import Service from '../../../../../../services/experiments';
import { charMap, copyToClipboard } from '../charMap';
import { fixUrl } from '../../utils';

const defaultImageData = {
  value: '',
  meta: {
    text: '',
  },
};

const {
  REACT_APP_MEDIA_URL
} = process.env;

const emptyOption = {
  option: '',
  text: '',
  image: { ...defaultImageData },
};

const emptyQuestionData = {
  desc: '',
  answer: 'A',
  direction: 'horizontal',
  options: [
    { ...emptyOption, option: 'A', text: 'Option A text' },
    { ...emptyOption, option: 'B', text: 'Option B text' },
  ],
};

tinymce.PluginManager.add('multiple', function (editor) {
  console.debug("multipleQuestion plugin is added")
  // inner fields to store id and type on right click
  let contextMenuQuestionId = null,
    contextMenuQuestionType = null;

  editor.on("init", () => {
    console.debug("initializing Multiple Question")
  });

  // Add a button that opens a window
  editor.ui.registry.addButton('multiple', {
    text: 'Multiple question',
    onAction: function () {
      // Open window
      showMainDialog();
    },
  });

  //adds the Edit question option in the context menu
  editor.ui.registry.addContextMenu('multiple', {
    //function fires when context menu is opened (right clicking an element)
    update: function (element) {
      console.debug('multiple update');
      // parse the ID && TYPE from the element, save in global variables
      contextMenuQuestionId = element.dataset['questionId'];
      contextMenuQuestionType = element.dataset['questionType'];

      console.debug(
        'multiple update:',
        contextMenuQuestionId,
        contextMenuQuestionType
      );

      //open context menu only if questionId is present and questionType is multiple or binary
      const result =
        (Boolean(contextMenuQuestionId) &&
          contextMenuQuestionType === 'multiple') ||
        contextMenuQuestionType === 'binary'
          ? 'multiple'
          : '';

      return result;
    },
  });

  const addClickListener = (element) => {
    element.addEventListener('click', onItemClick );
  }

  editor.on('init', function() {
    console.debug("Initializing multiple plugin");
    // Access the editor's content body
    var contentBody = editor.getBody();
  
    // Query for elements with 'data-question-id'
    var questionElements = contentBody.querySelectorAll('[data-question-id][data-question-type="multiple"]');
    console.debug("...found", questionElements.length, "'multiple' questions on page");
    // Add click listener to each element
    questionElements.forEach( addClickListener );
  });

  const onItemClick = async function ( event ) {
    const { course_id, course_experiment_id } = editor.settings;

    // Prevent default click behavior
    event.preventDefault();
    event.stopPropagation();

    contextMenuQuestionId = this.getAttribute('data-question-id');
    
    console.debug('Edit "multiple" question:', contextMenuQuestionId);

    if (!contextMenuQuestionId) {
      editor.windowManager.alert('Error: question ID was not extracted!');
      return;
    }

    const serverRes = await Service.getQuestion(
      course_id,
      course_experiment_id,
      contextMenuQuestionId
    );

    if (!serverRes) {
      editor.windowManager.alert('Error: ' + Service.error);
      return;
    }

    // Open window
    showMainDialog(serverRes);
  }

  // Adds a menu item, which can then be included in any menu via the menu/menubar configuration
  editor.ui.registry.addMenuItem('multiple', {
    text: 'Multiple question',

    // this fires when menu or context menu item is clicked on
    onAction: onItemClick
  });

  const showMainDialog = function (config = {}) {
    console.debug('multiple config:', config);

    let question_data = {
      ...emptyQuestionData,
      ...(config.question_data || {}),
    };

    // if it's a binary question, convert to 'multiple' format.
    if (contextMenuQuestionType === 'binary') {
      console.debug(
        'converted binary format to multiple. \n original:\n',
        question_data
      );
      question_data = convertBinaryToMultiple(question_data);
      console.debug(
        'converted binary format to multiple. \n result:\n',
        question_data
      );
    }

    // this will be the panel items for each one of the questions.
    let questionButtons = [];

    // deal with wrong data type
    if (!Array.isArray(question_data.options)) {
      showMessage('Wrong data type!');
      return false;
    }

    // build question buttons
    questionButtons = question_data.options.map((q) => {
      // console.debug("question data:", q);

      const has_image = q.image && q.image.value;
      const q_text = has_image ? '' : q.text ? q.text : '-no text-';
      const q_image = has_image
        ? `<img src="${REACT_APP_MEDIA_URL}${fixUrl(q.image.value)}" class="que" title="${q_text}"/>`
        : '';

      return {
        // each question is a panel
        type: 'panel',
        items: [
          // ...that contains a bar with text and two buttons (edit and delete)
          {
            type: 'bar',
            items: [
              {
                type: 'htmlpanel',
                html: `<span class="quiz-question">${q_text}${q_image}</span>`,
              },
              {
                type: 'button',
                primary: false,
                text: `edit`,
                name: `edit-${q.option}`,
                borderless: true,
                icon: 'edit-block',
                classes: 'q-btn edit-button',
                class: 'q-btn edit-button',
              },
              {
                type: 'button',
                primary: false,
                text: `delete`,
                name: `delete-${q.option}`,
                icon: 'close',
                borderless: true,
                classes: 'q-btn delete-button',
              },
            ],
          },
        ],
      };
    });

    // build answers dropdown
    const answerIds =
      question_data.options && question_data.options.map
        ? question_data.options.map((q) => ({
            text: q.text || q.meta.text || 'no text',
            value: q.option,
          }))
        : [];

    return editor.windowManager.open({
      title: 'Multiple choice question',
      body: {
        type: 'panel',
        items: [
          {
            type: 'textarea', // component type
            name: 'desc', // identifier
            label: 'Description (optional) ',
            placeholder: 'type in description here',
            maximized: true, // grow width to take as much space as possible
          },
          {
            type: 'label',
            label: 'Right answer',
            items: [
              {
                name: 'answer',
                type: 'selectbox',
                items: answerIds,
                label: 'Set correct answer',
              },
            ],
          },
          {
            type: 'label', // component type
            label: 'Questions:', // text for the group label
            items: [
              {
                type: 'button', // component type
                text: 'Add option',
                icon: 'plus',
                name: 'add',
              },
              ...questionButtons,
            ],
          },
        ],
      },

      buttons: [
        {
          type: 'cancel',
          text: 'Close',
        },
        {
          type: 'submit',
          text: 'Save',
          primary: true,
        },
      ],

      initialData: {
        desc: question_data.desc,
        answer: question_data.answer,
      },

      onSubmit: async function (api) {
        var data = api.getData();

        const { desc, answer } = data;
        const { question_id, blocks_next = 1 } = config;

        question_data = { ...question_data, desc, answer };

        console.debug(
          'uptate question_data:',
          question_data,
          '\nquestion_id:',
          question_id
        );

        // the spread here is awkward, but it's like this because of the way the API works.
        // the API extracts question_id, question_type and blocks_next from data, the rest of it is considered question_data.
        const updatedId = await updateQuestion({
          question_id,
          question_type: 'multiple',
          blocks_next,
          ...question_data,
        });
        if (!updatedId) {
          this.showMessage(Service.error);
          return false;
        }

        // Insert content when the window form is submitted
        insertElement(updatedId);



        api.close();
      },

      onAction: function (api, details) {
        console.debug(details);

        let [action, optionId] = details.name.split('-');

        switch (action) {
          case 'delete':
            {
              // find option index by
              const index = question_data.options.findIndex(
                (o) => o.option === optionId
              );

              if (index < 0) {
                console.error(
                  "Could not delete option because couldn't find its index: ",
                  optionId
                );
                return;
              }

              // remove one item
              question_data.options.splice(index, 1);
              // redraw
              Redraw(api, config, question_data);
            }
            break;

          case 'edit':
            {
              // find option index by
              const index = question_data.options.findIndex(
                (o) => o.option === optionId
              );

              if (index < 0) {
                console.error(
                  "Could not update option because couldn't find its index: ",
                  optionId
                );
                return;
              }

              editOption(question_data.options[index], (updatedOption) => {
                // update data in options
                question_data.options[index] = updatedOption;
                Redraw(api, config, question_data);
              });
            }
            break;

          case 'add':
            editOption({}, (newOption) => {
              // update data in options
              question_data.options = [...question_data.options, newOption];
              Redraw(api, config, question_data);
            });
            break;
        }
      },
    });
  };

  /**
   * Removes existing element with same id (if any) than inserts a new one
   * @param {*} id
   */
  const insertElement = (id) => {
    // find element with this id and remove it
    let elm = editor.dom.select(`[data-question-id="${id}"]`)[0];

    // this removes only the found element
    if (elm) editor.dom.remove(elm);

    // prepare element
    const html = `<span data-question-id="${id}" 
    data-question-type="multiple" 
    class="question multiple" 
    data-updated="${Date.now()}">-----</span>`;

    // insert element
    editor.insertContent(html);

    // find the newly inserted element
    elm = editor.dom.select(`[data-question-id="${id}"]`)[0];

    // add listener
    addClickListener(elm);
  };

  /**
   * Shows an (error) message in a pop-up window
   * @param {*} message
   * @param {*} type
   */
  const showMessage = (message, type = 'error') => {
    editor.windowManager.open({
      title: type === 'error' ? 'Error' : 'Notification',
      body: {
        type: 'panel',
        items: [
          {
            type: 'htmlpanel', // component type
            html: `<p>${message}</p>`,
          },
        ],
      },
    });
  };

  const editOption = function (option = {}, callback) {
    // in case no data was passed, the empty answer data is sublayed
    option = { ...emptyOption, ...option };

    return editor.windowManager.open({
      title: option.option !== null ? 'Edit option' : 'Add option',
      size: '300px',
      classes: 'edit-answer',
      body: {
        type: 'panel',
        items: [
          {
            type: 'collection',
            name: 'charMapAnswers',
            label: 'Special characters',
          },
          {
            type: 'input',
            name: 'text',
            label: 'Option text',
          },
          {
            type: 'urlinput', // component type
            name: 'image', // identifier
            filetype: 'file', // allow any file types
            label: 'Option image (optional)', // text for component label
          },
        ],
      },
      buttons: [
        {
          type: 'cancel',
          name: 'cancel',
          text: 'Cancel',
        },
        {
          type: 'submit',
          name: 'addIt',
          text: option.option !== null ? 'Save' : 'Add',
          primary: true,
        },
      ],
      initialData: {
        text: option.text || '',
        charMapAnswers: charMap(),
        // if option.image is an object...
        image:
          option.image && typeof option.image === 'object'
            ? // override default image data with passed object (to avoid null values)
              { ...defaultImageData, ...option.image }
            : defaultImageData,
      },

      onSubmit: (api) => {
        let data = api.getData();

        // todo: fetch image as well
        const { text, image } = data;

        // if option.option not passed, create it.
        const o = option.option || uniquid('option_');

        // call callback passed through config
        if (typeof callback === 'function') {
          // overwrite option, text and image
          callback({ ...option, option: o, text, image });
        } else {
          console.debug('Edit option submit: Callback not passed');
        }

        // close the dialog
        api.close();
      },

      onCancel: (api) => api.close(),
      onAction: (api, details) => {
        if (details.value !== '') {
          copyToClipboard(details.value);
        }
      },
    });
  };

  const convertBinaryToMultiple = (question_data) => {
    // find option_A and option_B keys in question data.
    const options = Object.keys(question_data).reduce((res, option) => {
      // option key is option_A, option_B
      if (!option.startsWith('option_')) return res;

      // get option index (A o B)
      const option_index = option.substr(7, 1);
      console.debug('option:', option, ', option index:', option_index);

      // image is binary_image_A or binary_image_B. Find it, or return empty object
      const image = question_data[`binary_image_${option_index}`] || {};

      return [...res, { option, text: question_data[option], image }];
    }, []);

    let { desc, answer } = question_data;
    // find option for the answer
    answer = options.reduce(
      (res, o) =>
        // if text equals answer - return option id (o.option), otherwise return previous value.
        o.text === answer ? o.option : res,
      ''
    );

    // update question type so that next time it won't convert the data
    contextMenuQuestionType = 'multiple';

    return { ...emptyQuestionData, desc, answer, options };
  };

  const Redraw = (api, config, question_data) => {
    // close multiple question dialog
    api.close();

    // open new dialog with updated data
    showMainDialog({ ...config, question_data });
  };

  const updateQuestion = async (data) => {
    const { course_id, course_experiment_id } = editor.settings;
    return await Service.updateQuestion(course_id, course_experiment_id, data);
  };

  return {
    getMetadata: function () {
      return {
        name: 'Multiple options question plugin for SES Courses',
      };
    },
  };
});

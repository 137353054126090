import {
  // Breadcrumbs,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Container,
  // Drawer,
  // Grid,
  IconButton,
  List,
  ListItem,
  // ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  // TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import { Link, NavLink, Redirect } from "react-router-dom";
// import Loader from "../../../template/common/loader";
import Service from "../../../../services/course";
import Form from "../form";
import Spinner from "../../../template/spinner";
import "./at5000.scss";
// import ModalContent from "./modalContent";
import { defaultCoursesConfig } from "../../../../data/tinymce-configs";
import TinyMceEditor from "@ses-education/content-editor";
// import ModalWindow from "@ses-education/courses-components/modal-window";
import Configuration from "./configuration";
import ConfirmDialog from "../../../template/ConfirmDialog";
import {
  // Add, ChevronLeft,
  Edit,
  Home,
} from "@material-ui/icons";

// import { getObjectDifference } from "../../../../utils/functions";
import ContentCategoryList from "./content-category-list";
import ContentCategories from "./content-categories";
import { connect } from "react-redux";
import { showMessage } from "@ses-education/courses-components";
import {AutoBreadcrumbs} from "@ses-education/courses-components";
const tabid = "tab-";
const tabpanelid = "tab-field-";

const TabPanel = (props) => {
  const { id, children, value = 0, className } = props;

  return (
    <div
      role="tabpanel"
      aria-labelledby={`${tabid}${id}`}
      id={`${tabpanelid}${id}`}
      hidden={value !== id}
      className={value === id && className}
    >
      {children}
    </div>
  );
};

const tab_data = [
  { id: "properties", title: "Properties" },
  { id: "content", title: "Content" },
];

const category_tab_data = [
  { id: "categories", title: "Categories", count: 0 },
  { id: "pages", title: "Pages", count: 0 },
  { id: "configure", title: "Configure" },
];

const defaultPage = {
  config: null,
  content: null,
  page_id: null,
  ordering: null,
};

const tinymceKey = process.env.REACT_APP_TINYMCE;

const Editor = ({ init = {}, ...props }) => {
  return (
    <TinyMceEditor init={{ ...defaultCoursesConfig, ...init }} {...props} />
  );
};

const TabComponent = ({ children, count, ...other }) => (
  <Link {...other}>
    {children}
    {count > 0 && <span className="badge">{count}</span>}
  </Link>
);

class AT5000Course extends React.Component {
  state = {
    course: null,
    errorText: null,
    categories: null,
    currentCategory: null,
    currentPage: null,
    currentPageIndex: null,
    redirect: null,
    editElement: null,
    del_element: null,
    contentModel: false,
    categoryModel: false,
    configureModel: false,
    confirmExistEditDialog: false,
    del_category: -1,
    NewCategoryTitle: "",
    editCategoryTitle: false,
    confirmHeader: "",
    confirmPrompt: "",
    onConfirm: null,
  };
  async componentDidMount() {
    // get course data and call other fetch functions
    await this.fetchData();
  }
  async componentDidUpdate(PrevProps) {
    const { id, category_id, page_id } = this.props.match.params;
    // if course have change fetch data
    if (id !== PrevProps.match.params.id) {
      await this.fetchCategories();
    }
    // if category have change select new category
    if (category_id !== PrevProps.match.params.category_id) {
      this.selectCategory();
    }
    //if page have change select new page
    if (page_id !== PrevProps.match.params.page_id) {
      this.selectPage();
    }
    // if you got here from a redirect from same component the nullify redirect on state
    // it will stuck on an infinit redirect if not do that
    if (this.state.redirect === this.props.match.url) {
      this.setState({ redirect: null });
    }
  }
  // fetch course data
  fetchData = async () => {
    console.debug("\n\n\nfetching course data\n\n");
    //get the id of the selected course
    const { id } = this.props.match.params;
    // fetch course
    const course = await Service.getCourse(id);
    // if didnt get a result show error
    if (!course) {
      this.setState({ course, errorText: Service.error });
      this.props.onShowMessage(
        "Error fetching course: " + Service.error,
        "error"
      );
      return;
    }
    // put course data to state and fetch course categories
    this.setState({ course }, this.fetchCategories);
  };
  // fetch course categories
  fetchCategories = async () => {
    console.debug("\n\n\nfetching course categories data\n\n");
    //get the id of the selected course
    const { id } = this.props.match.params;
    // get the course categories data
    const categories = await Service.getCourseCategories(id);
    // if didnt get a result show error
    if (!categories) {
      this.setState({ categories, errorText: Service.error });
      this.props.onShowMessage(
        "Error fetching course: " + Service.error,
        "error"
      );
      return;
    }
    // put course categories to state and call select category
    this.setState({ categories }, this.selectCategory);
  };

  /**
   * select the current category if it exists and store it in state
   * @returns
   */
  selectCategory = () => {
    // get category path from url and categories list from state
    const { category_id } = this.props.match.params;
    const { categories } = this.state;
    let currentCategory = null,
      path = [];

    // if there's no category id or no categories, set currentCategory to null
    if (!(category_id && Array.isArray(categories))) {
      return this.setState({ currentCategory }, this.selectPage);
    }

    // check if the path correct if is return last category else show error
    const category_ids = category_id.split("|");

    let parent_cat_id = null;
    for (let i = 0; i < category_ids.length; i++) {
      const id = category_ids[i];

      // check if the current category id from path exist in list of categories
      // if exist fetch the current category data from categories list
      currentCategory = categories.find(
        (c) =>
          c.content_cat_id === parseInt(id) && c.parent_id === parent_cat_id
      );

      // if current category not exist show error and break
      if (!currentCategory) {
        this.setState({
          currentCategory: null,
          errorText: "could not find category",
        });
        this.props.onShowMessage("Error fetching category: ", "error");
        // _pathIsWrong = true;
        return; // stop processing the route
      }

      // add category to path
      path = [...path, { ...currentCategory }];

      // update previous category id to current cat id
      parent_cat_id = currentCategory.content_cat_id;
    }

    // add nested categories and path, and spread the currentCategory so the original object isn't overwritten
    currentCategory = {
      ...currentCategory,
      categories: categories.filter(
        (c) => c.parent_id === currentCategory.content_cat_id
      ),
      path,
    };

    // put current category to state and select page in current category if exist
    this.setState({ currentCategory }, this.selectPage);
  };
  // select a page in category if exist
  selectPage = () => {
    // get page_id from url and currentCategory from state
    const { page_id } = this.props.match.params;
    const { currentCategory } = this.state;
    let currentPage = null;

    // if no page_id or no current category or no pages in it, set page to null
    if (!page_id || !currentCategory || !Array.isArray(currentCategory.pages)) {
      return this.setState({ currentPage });
    }

    // find the current page index
    const currentPageIndex = currentCategory.pages.findIndex(
      (p) => p.page_id === parseInt(page_id)
    );

    // get the current page and clone it
    currentPage =
      currentPageIndex >= 0
        ? { ...currentCategory.pages[currentPageIndex] }
        : false;

    // put current page to state
    this.setState({ currentPage, currentPageIndex });
  };
  // add new page to current Category
  addPage = async () => {
    // get course id and category path from url and current category data from state
    const { currentCategory } = this.state;
    const { id, category_id } = this.props.match.params;
    const { onShowMessage } = this.props;

    // return console.debug("addPage called", id, category_id, currentCategory);

    // if course id and current category exist add page
    if (!(currentCategory && id)) {
      return onShowMessage("no ID  or no current category found");
    }

    // We need to set ordering number of the page.
    // find the maximum ordering number within existing pages and add 1, or, if none - use 0
    const ordering = Array.isArray(currentCategory.pages)
      ? currentCategory.pages.reduce(
          // run through each page and compare its ordering value to current maximum
          (maxOrdering, page) =>
            page.ordering > maxOrdering ? page.ordering : maxOrdering,
          // starting maxOrdering is 0
          0
        ) + 1 // when found the maximum ordering value, add 1
      : 0;

    // adding new page to current category
    const page_id = await Service.addContentPage(
      id,
      currentCategory.content_cat_id,
      { ordering }
    );
    // if didnt get a result show error
    if (!page_id) {
      this.setState({ errorText: Service.error });
      this.props.onShowMessage(
        "Error fetching course: " + Service.error,
        "error"
      );
      return;
    }
    // succesfuly added new page then refrash view and redirect to new page
    await this.fetchData();
    this.setState({
      confirmHeader: "",
      confirmPrompt: "",
      onConfirm: null,
      redirect: `/courses/${id}/content/${category_id}/pages/${page_id}`,
    });
  };
  // delete current page from category
  deletePage = async (delete_page_id) => {
    // get current page and current category from state and course id and categories path from url
    const { currentPage, currentCategory } = this.state;
    const { id, category_id } = this.props.match.params;
    // if course id and current category and page exist delete page
    if (currentPage && id && currentCategory) {
      // delete current page from current category
      // const page_id = await Service.deletePage(
      //   id,
      //   currentCategory.content_cat_id,
      //   // currentPage.page_id
      //   delete_page_id
      // );
      // if didnt get a result show error
      // if (!page_id) {
      if (
        !(await Service.deletePage(
          id,
          currentCategory.content_cat_id,
          // currentPage.page_id
          delete_page_id
        ))
      ) {
        this.setState({ errorText: Service.error });
        this.props.onShowMessage(
          "Error fetching course: " + Service.error,
          "error"
        );
        return;
      }
      // succsesfuly delete page then refrash page and redirect to category
      await this.fetchData();
      this.setState({
        redirect: `/courses/${id}/content/${category_id}/pages`,
      });
    }
  };

  /**
   * delete content element from current page
   * @param {*} element_id
   * @returns
   */
  deleteContentElement = async (element_id) => {
    const { currentCategory } = this.state;
    const { id, page_id } = this.props.match.params;
    if (page_id && id && currentCategory && element_id) {
      // delete content element with element_id from current page
      // const result = await Service.unassignContentElement(
      //   id,
      //   currentCategory.content_cat_id,
      //   page_id,
      //   element_id
      // );
      // if didnt get a result show error
      if (
        !(await Service.unassignContentElement(
          id,
          currentCategory.content_cat_id,
          page_id,
          element_id
        ))
      ) {
        console.debug("Should show error message:", Service.error);
        this.props.onShowMessage(
          "Error fetching course: " + Service.error,
          "error"
        );
        this.setState({ errorText: Service.error });
        return false;
      }
      // if succsessfuly deleted content element refresh view
      await this.fetchData();
      // this.setState({
      //   confirmHeader: "",
      //   confirmPrompt: "",
      //   onConfirm: null,
      // });
      return true;
    }
  };

  /**
   * Delete category
   * @param {*} del_category
   * @returns
   */
  deleteCategory = async (del_category) => {
    const { onShowMessage } = this.props;

    if (!del_category || del_category <= 0) {
      console.error("deleteCategory received:", del_category);
      return onShowMessage("Wrong category ID passed");
    }

    // get delete category id from state and course id from url
    // const { del_category } = this.state;
    const { id, category_id } = this.props.match.params;
    const category_path = category_id.split("|");

    if (category_path.length === 0) {
      return onShowMessage("Cannot delete base category");
    }

    // remove last element in path
    category_path.pop();

    const previous_category_slug =
      category_path.length === 0 ? "" : `/${category_path.join("|")}`;

    // prepare redirect link to jump to, once current directory is deleted
    // It's the previous category in stack
    const redirect = `/courses/${id}/content${previous_category_slug}`;

    // return console.debug("category id", category_id, redirect);

    // if course id and delete category id exist delete the category
    if (id && del_category !== -1) {
      // delete category
      // const result = await Service.deleteCategory(id, del_category);
      // if didnt get a result show error
      // if (!result) {
      if (!(await Service.deleteCategory(id, del_category))) {
        // this.setState({ errorText: Service.error });
        return onShowMessage(
          "Error deleting category: " + Service.error,
          "error"
        );
      }
      // succsesfuly delete category then refresh data and redirect to category parent
      onShowMessage("Category was successfully deleted", "success");
      await this.fetchData();
      this.setState({
        // confirmHeader: "",
        // confirmPrompt: "",
        // onConfirm: null,
        redirect,
      });
    }
  };

  /**
   * Update content element with passed data
   * @param {*} element_id
   * @param {*} data
   * @returns
   */
  updateContentElement = async (element_id, data) => {
    // return console.debug("updateContentElement", element_id, data);
    const { onShowMessage } = this.props;
    if (!(await Service.updateContentElement(element_id, data))) {
      return onShowMessage(Service.error || "Unknown error");
    }

    // reload data
    this.fetchCategories();
  };

  // updateContentElement = async (html) => {
  //   console.debug("updateContentElement:", html);
  //   // get content element data from state
  //   const { editElement } = this.state;
  //   const { element_id } = editElement;
  //   // update content element with reciving html
  //   const element = await Service.updateContentElement(element_id, {
  //     html,
  //   });
  //   //  if didnt get a result show error
  //   if (!element) {
  //     this.setState({ errorText: Service.error });
  //     this.props.onShowMessage(
  //       "Error updating element: " + Service.error,
  //       "error"
  //     );
  //     return;
  //   }
  //   // cleare state current element and refrash view
  //   this.setState({ editElement: null });
  //   await this.fetchData();
  // };
  // save page configuration close configuration modal and refrash view
  saveConfig = async () => {
    this.setState({ configureModel: false });
    await this.fetchData();
  };
  // save new category
  addCategory = async (categoryData) => {
    if (!categoryData || typeof categoryData !== "object") {
      console.debug("saveCategory: expected object, got:", categoryData);
      return this.props.onShowMessage("Error in category data");
    }

    // return console.debug("addCategory", categoryData);

    //  get current course and category data from state
    const { course, currentCategory } = this.state;
    // get the ordaring for the new category for current category categories list if not exist defult 1
    const ordering =
      // if there's a non-emptu array currentCategory.categories
      currentCategory &&
      Array.isArray(currentCategory.categories) &&
      currentCategory.categories.length > 0
        ? // &&
          // currentCategory.categories[currentCategory.categories.length - 1]
          //   .ordering &&
          // currentCategory.categories[currentCategory.categories.length - 1]
          //   .ordering + 1) ||

          // find maximum value of ordering within its elements and add 1
          Math.max(...currentCategory.categories.map((c) => c.ordering)) + 1
        : // otherwise it's 1
          1;

    // add new category to current category with the title from state parent id as the current category id and ordering
    const category = await Service.addCategory(course.course_id, {
      ...categoryData,
      parent_id: currentCategory.content_cat_id,
      ordering,
    });
    // if didnt get result show error
    if (!category) {
      this.setState({ errorText: Service.error });
      this.props.onShowMessage(
        "Error adding category: " + Service.error,
        "error"
      );
      return;
    }
    // succcessfully added new category then cloes add category model and refrash view
    this.setState({ categoryModel: false });
    await this.fetchData();
  };

  /**
   * update current category with passed data
   * @param {*} categoryData
   */
  updateCategory = async (categoryData) => {
    const { onShowMessage } = this.props;

    if (!categoryData || typeof categoryData !== "object") {
      console.debug("updateCategory: expected object, got:", categoryData);
      return onShowMessage("Error in category data");
    }

    //  get current course and category data from state
    const { course, currentCategory } = this.state;
    const course_id = course.course_id;
    const { content_cat_id } = currentCategory;

    if (!course_id || !content_cat_id) {
      console.debug(
        "updateCategory: Error in course or current category data:",
        course,
        currentCategory
      );
      return onShowMessage("Error in course or current category data");
    }

    if (
      !(await Service.updateCategory(course_id, content_cat_id, categoryData))
    ) {
      return onShowMessage(Service.error || "Unknown error");
    }

    // reload data
    this.fetchCategories();
  };

  // updateCategory = async (categoryData) => {
  //   let { currentCategory, categories, course } = this.state;
  //   let path, innerCategories; // this is needed
  //   // to mix declared and not yet declared values in spread

  //   // filter out path and categories props that were added in selectCategory:
  //   ({
  //     path, // need to declare path beforehand
  //     categories: innerCategories, // need to declare innerCategories beforehand.
  //     // we case categories to innerCategories here to avoid name collision with state variable
  //     ...currentCategory
  //   } = currentCategory);

  //   const catPath = this.props.match.params.category_id.split("|");
  //   const cat_id = parseInt(catPath[catPath.length - 1]);
  //   const originCat = categories.find((cat) => cat.content_cat_id === cat_id);

  //   // checking if current category is diffrent then the original category
  //   // if (JSON.stringify(currentCategory) === JSON.stringify(originCat)) {
  //   //   console.debug("No changes were made in category data");
  //   //   return;
  //   // }

  //   // get the difference
  //   const difference = getObjectDifference(originCat, currentCategory);

  //   console.debug("difference is:", difference);
  //   // if nothing has changed, do nothing
  //   if (Object.keys(difference).length === 0) {
  //     console.debug("No changes were made in category data");
  //     return;
  //   }

  //   // update current category data
  //   // const category = await Service.updateCategory(
  //   //   course.course_id,
  //   //   currentCategory.content_cat_id,
  //   //   currentCategory
  //   // );

  //   // if didnt get result show error
  //   if (
  //     !(await Service.updateCategory(
  //       course.course_id,
  //       currentCategory.content_cat_id,
  //       difference
  //     ))
  //   ) {
  //     this.setState({ errorText: Service.error });
  //     this.props.onShowMessage(
  //       "Error updating category: " + Service.error,
  //       "error"
  //     );
  //     return;
  //   }

  //   // succcessfully update category then cloes edit mode and refrash view
  //   await this.fetchData();

  //   this.setState({ editCategoryTitle: false });
  // };
  render() {
    const {
      course,
      categories,
      currentCategory,
      currentPage,
      currentPageIndex,
      redirect,
      errorText,
      contentModel,
      configureModel,
      confirmExistEditDialog,
      editElement,
      NewCategoryTitle,
      categoryModel,
      editCategoryTitle,
      confirmHeader,
      confirmPrompt,
      onConfirm,
    } = this.state;
    const {
      id,

      // we remove default value here so that there's no default tab
      // and we can redirect to /properties which is a *separate* route from /courses/:id
      // This way the page is not reloaded when switching from /properties to /content - it's within the same route
      tab_id, // = "properties",
      category_id,
      cat_tab = "pages",
      page_id,
    } = this.props.match.params;

    const { onShowMessage } = this.props;

    console.log("test render", this.state, this.props);
    console.log(
      "currentCategory.categories",
      currentCategory ? currentCategory.categories : []
    );

    if (redirect) {
      return <Redirect to={redirect} />;
    }

    // course id is set but no tab is selected - redirect to /properties
    if (id && !tab_id) return <Redirect to={`/courses/${id}/properties`} />;

    // prepare counts for categories and pages
    category_tab_data[0].count =
      currentCategory && Array.isArray(currentCategory.categories)
        ? currentCategory.categories.length
        : 0;
    category_tab_data[1].count =
      currentCategory && Array.isArray(currentCategory.pages)
        ? currentCategory.pages.length
        : 0;

    console.debug("category_tab_data", category_tab_data);

    if (!course) return <Spinner />;

    const { course_id, title, course_code } = course;

    // build breadcrumbs
    let breadcrumbs = [
      {
        to: "/",
        icon: <Home />,
      },
      {
        to: "/courses",
        text: "Courses",
      },
      {
        to: `/courses/${id}${tab_id === "properties" ? "" : `/${tab_id}`}`,
        text: tab_id === "properties" ? title : `${title} : ${tab_id}`,
      },
      // {
      //   to: `/courses/${id}/${tab_id}`,
      //   text: tab_id,
      // },
    ];

    // add breadcrumbs from path
    if (currentCategory && Array.isArray(currentCategory.path)) {
      // we'll increment this slug on each step
      let slug = `/courses/${id}/${tab_id}`;
      currentCategory.path.forEach((cat, ind) => {
        slug = `${slug}/${cat.content_cat_id}`; // add current category id to slug
        breadcrumbs = [
          ...breadcrumbs,
          {
            to: slug,
            text: cat.title,
          },
        ];
      });
    }

    const categoriesIsArray = Array.isArray(categories);

    return (
      <Container key={`course-${course_id}`}>
        <AutoBreadcrumbs items={breadcrumbs} />
        <Typography
          variant="h1"
          className="flex align-items-center justify-content-space-between"
        >
          <span>
            {course_code} : {title}
          </span>
          <Tabs value={tab_id}>
            {tab_data.map((t) => (
              <Tab
                label={t.title}
                value={t.id}
                component={Link}
                to={`/courses/${course_id}/${t.id}`}
                // to={`/courses/${course_id}${
                //   t.id === "properties" ? "" : `/${t.id}`
                // }`}
                id={`${tabid}${t.id}`}
                aria-controls={`${tabpanelid}${t.id}`}
              />
            ))}
          </Tabs>
        </Typography>

        <div>
          {/* -----------------------------------PROPERTIES--------------------------------------- */}
          <TabPanel id={`properties`} value={tab_id}>
            <Form {...course} onUpdate={this.fetchData} />
          </TabPanel>

          {/* -----------------------------------CONTENT--------------------------------------- */}
          <TabPanel id={`content`} className="content" value={tab_id}>
            <ContentCategories
              {...{
                category_id, // category id(s) from route
                page_id, // page id from route
                cat_tab, // category inner tab from route
                categories, // the complete list of all categories
                currentCategory, // current category object
                baseUrl: `/courses/${course_id}/${tab_id}`, // base url for every link within the component

                addCategory: this.addCategory,
                updateCategory: this.updateCategory,
                deleteCategory: this.deleteCategory,
                updateContentElement: this.updateContentElement,
                deleteContentElement: this.deleteContentElement,
                addPage: this.addPage,
                deletePage: this.deletePage,

                onShowMessage, // pass the function to show messages

                onUpdate: this.fetchCategories,
              }}
            />
          </TabPanel>

          {/* -------------------------------------OLD VERSION OF CONTENT PANEL--------------------------------- */}
          <TabPanel id={`content-old`} className="content" value={tab_id}>
            <div className="flex row grid-container">
              <div
                className="flex column grid-item left-panel"
                key={`left-panel`}
              >
                {/* categories side bar */}
                <ContentCategoryList
                  {...{
                    currentCategory,
                    categories,
                    className: "content-category-list",
                    NewCategoryTitle,
                    baseUrl: `/courses/${course_id}/content`,
                  }}
                />
              </div>
              <div
                className={`flex column grid-item right-panel`}
                key={`right-panel`}
              >
                {!category_id && "Please select a category to display"}
                {category_id && !currentCategory && <Spinner />}
                {category_id && currentCategory && (
                  <div className="category-view">
                    <div className="category-header">
                      <div className="title">
                        <div
                          id="outlined-title"
                          variant="outlined"
                          contentEditable={editCategoryTitle}
                          onInput={(event) =>
                            this.setState({
                              currentCategory: {
                                ...currentCategory,
                                title: event.target.value,
                              },
                            })
                          }
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              this.updateCategory();
                            }
                          }}
                          onBlur={(e) => this.updateCategory()}
                        >
                          {currentCategory.title}
                        </div>
                      </div>
                      <IconButton
                        color="secondary"
                        variant=""
                        aria-label="edit"
                        onClick={() =>
                          this.setState({
                            editCategoryTitle: !editCategoryTitle,
                          })
                        }
                      >
                        <Edit />
                      </IconButton>
                      <Tabs value={cat_tab} centered className="m-bottom-10">
                        {category_tab_data.map((t) => (
                          <Tab
                            label={t.title}
                            value={t.id}
                            // component={Link}
                            component={TabComponent}
                            to={`/courses/${course_id}/${tab_id}/${category_id}${
                              t.id === "categories" ? "" : `/${t.id}`
                            }`}
                            id={`${tabid}${t.id}`}
                            aria-controls={`${tabpanelid}${t.id}`}
                            count={t.count}
                          />
                        ))}
                      </Tabs>
                    </div>
                    {
                      // -----------------------------------PAGES---------------------------------------
                    }
                    <TabPanel id={`pages`} value={cat_tab}>
                      <div className="flex flex-grid row">
                        <div
                          className="grid-item left-panel"
                          key={`left-panel`}
                        >
                          {
                            // pages side bar
                          }
                          <List
                            className="page-list"
                            variant="persistent"
                            anchor="left"
                          >
                            {Array.isArray(currentCategory.pages) &&
                              currentCategory.pages.map((item, pageNumber) => {
                                const { page_id } = item;

                                return (
                                  <ListItem
                                    className="page-icon"
                                    button
                                    key={page_id}
                                    component={NavLink}
                                    to={`/courses/${course_id}/${tab_id}/${category_id}/pages/${page_id}`}
                                  >
                                    <span className="page-number">
                                      {pageNumber + 1}
                                    </span>
                                    <span className="page-id">
                                      id:{page_id}
                                    </span>
                                  </ListItem>
                                );
                              })}
                            <ListItem
                              className="page-icon add-button"
                              button
                              key={"pages-add"}
                              onClick={() => {
                                this.setState({
                                  confirmHeader: "Add Page",
                                  confirmPrompt:
                                    "Are you sure you want to add a new page?",
                                  onConfirm: this.addPage,
                                });
                              }}
                            >
                              <ListItemText primary={"Add"} />
                            </ListItem>
                          </List>
                        </div>
                        <div
                          className="grid-item right-panel"
                          key={`right-panel`}
                        >
                          {(!Array.isArray(currentCategory.pages) ||
                            (Array.isArray(currentCategory.pages) &&
                              currentCategory.pages.length === 0)) &&
                            "There are no pages in category use add button to add a page"}
                          {Array.isArray(currentCategory.pages) &&
                            currentCategory.pages.length !== 0 &&
                            !page_id &&
                            "Please select a page to display"}
                          {Array.isArray(currentCategory.pages) &&
                            currentCategory.pages.length !== 0 &&
                            page_id &&
                            !currentPage && <Spinner />}
                          {currentPage && (
                            <div className="page-header">
                              <div className="title">
                                <span className="page-index">
                                  Page {currentPageIndex + 1}
                                </span>
                              </div>
                              <ButtonGroup>
                                <Button
                                  variant="contained"
                                  onClick={() =>
                                    this.setState({ contentModel: true })
                                  }
                                >
                                  Add content element
                                </Button>
                                <Button
                                  variant="contained"
                                  onClick={() =>
                                    this.setState({ configureModel: true })
                                  }
                                >
                                  configure
                                </Button>
                                <Button
                                  variant="contained"
                                  onClick={this.deletePage}
                                >
                                  delete
                                </Button>
                              </ButtonGroup>
                            </div>
                          )}
                          {currentPage &&
                            (!Array.isArray(currentPage.content) ||
                              (Array.isArray(currentPage.content) &&
                                currentPage.content.length === 0)) &&
                            "There are no content elements on this page.\nUse Add content element button to add one."}
                          {currentPage &&
                            Array.isArray(currentPage.content) &&
                            currentPage.content.map((content) => {
                              const { html, element_title, element_id } =
                                content;
                              return (
                                <div className={"page-content"}>
                                  <div className="page-content-header">
                                    {element_title}
                                    <ButtonGroup>
                                      <Button
                                        variant="contained"
                                        onClick={() =>
                                          this.setState({
                                            editElement: { ...content },
                                          })
                                        }
                                      >
                                        Edit
                                      </Button>
                                      <Button
                                        variant="contained"
                                        onClick={() =>
                                          this.setState({
                                            confirmHeader: "Unassign Element",
                                            confirmPrompt: `Are you sure you want to unassign element ${element_title}`,
                                            onConfirm: () =>
                                              this.deleteContentElement(
                                                element_id
                                              ),
                                          })
                                        }
                                      >
                                        Delete
                                      </Button>
                                    </ButtonGroup>
                                  </div>
                                  {!(
                                    editElement &&
                                    element_id === editElement.element_id
                                  ) && (
                                    <div
                                      className={"content-element"}
                                      dangerouslySetInnerHTML={{
                                        __html: html,
                                      }}
                                    />
                                  )}
                                  {editElement &&
                                    element_id === editElement.element_id && (
                                      <ClickAwayListener
                                        onClickAway={() =>
                                          this.setState({
                                            confirmExistEditDialog: true,
                                          })
                                        }
                                      >
                                        <div>
                                          <Editor
                                            apiKey={tinymceKey}
                                            content={html}
                                            onSave={this.updateContentElement}
                                            onCancel={() => {
                                              this.setState({
                                                editElement: null,
                                              });
                                            }}
                                          />
                                          <ConfirmDialog
                                            open={confirmExistEditDialog}
                                            header="Exit Edit Mode"
                                            prompt={`Are you sure you want to exit edit mode without saving`}
                                            onConfirm={() =>
                                              this.setState({
                                                editElement: null,
                                                confirmExistEditDialog: false,
                                              })
                                            }
                                            onClose={() => {
                                              this.setState(
                                                {
                                                  confirmExistEditDialog: false,
                                                },
                                                () =>
                                                  console.log(
                                                    "test confirm onClose",
                                                    this.state
                                                  )
                                              );
                                            }}
                                          />
                                        </div>
                                      </ClickAwayListener>
                                    )}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </TabPanel>
                    {
                      // -----------------------------------CONFIGURE---------------------------------------
                    }
                    <TabPanel id={`configure`} value={cat_tab}>
                      <Configuration
                        course_type={course.course_type}
                        source={currentCategory}
                        onSave={this.saveConfig}
                      />
                    </TabPanel>
                  </div>
                )}
              </div>
            </div>
          </TabPanel>
        </div>

        {/* <ModalContent
          onModalOpen={contentModel}
          onModalClose={() => this.setState({ contentModel: false })}
          {...{ id, category_id, page_id }}
          onUpdate={async () => await this.fetchData()}
        />       
        <ModalWindow
          open={configureModel}
          onClose={() => this.setState({ configureModel: false })}
          header={"Content page configuration"}
        >
          <Configuration
            course_type={course.course_type}
            source={currentPage}
            onSave={this.saveConfig}
          />
          <ButtonGroup>
            <Button variant="contained" onClick={this.saveConfig}>
              Save
            </Button>
            <Button
              variant="contained"
              onClick={() => this.setState({ configureModel: false })}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </ModalWindow> */}
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onShowMessage: (message, type) => {
    // console.debug("onShowMessage is called (error)");
    dispatch(showMessage(message, type));
  },
});

export default connect(null, mapDispatchToProps)(AT5000Course);

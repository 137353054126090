import { Button, MenuItem, Select, Switch, TextField } from "@material-ui/core";
import React from "react";
import AsyncButton from "../../template/AsyncButton";
import "./organization-form.scss";

const fields = [
  "organization_title",
  "organization_logo_images",
  "active",
  "url_slug",
];

const types = [
  'online',
  'standalone'
];
class OrganizationForm extends React.Component {
  state = {
    organization_title: "",
    organization_logo_images: {},
    active: 1,
    url_slug: "",
    config:{type:"online"},    
  };

  componentDidMount() {
    const {
      organization_id,
      organization_title = "",
      organization_logo_images = {},
      active = 1,
      url_slug = "",
    } = this.props.data || {};
    let {config} = this.props.data || {};
    if (!config){
      config = {type:'online'}
    }
    this.setState({     
      organization_id, 
      organization_title,
      organization_logo_images,
      active,
      url_slug,
      config,
    });
  }

  updateValue = ({ target }) => {
    let { name, value, checked } = target;
    if (!fields.includes) {
      console.error("Wrong field name", name);
      return;
    }
    console.debug(name, value)

    switch(name){
      case "active":
        // convert from true/false to 1/0
        value = checked === true ? 1 : 0;
        break;
      case "config":
        value = {type:value};
        break;
    }

    this.setState({ [name]: value });
  };

  render() {
    const { onClose, onSave, data = {} } = this.props;

    const {
      organization_id,
      organization_title,
      organization_logo_images,
      active,
      url_slug,
      config,
    } = this.state;
    const {type} = config || {};
    const disableSave = !type || type.length === 0 || !organization_title || !url_slug || organization_title.length === 0 || url_slug.length === 0;
    const AdditionalFields = ({active}) => <div className="additional-fields">
        <Switch name="active" checked={active  === 1 ? true : false } onChange={this.updateValue} />
    </div>

    console.debug("state", this.state)
    return (
      <form className="organization-form">
        <div className="fields">
          <TextField
            name="organization_title"
            placeholder="title"
            label="Organization title"
            onChange={this.updateValue}
            value={organization_title}
          />
          <TextField
            name="url_slug"
            placeholder="URL slug"
            label="URL slug"
            onChange={this.updateValue}
            value={url_slug}
          />
        </div>
        { organization_id && <AdditionalFields {...this.state} /> }
        {type && (
          <Select
            name="config"
            onChange={this.updateValue}
            value={type}
          >
            {types.map(type =>
              <MenuItem value={type}>{type}</MenuItem>
            )}
          </Select>
        )}
        <div className="buttons m-top-10">
          <AsyncButton variant="contained" disabled={disableSave} color="primary" onClick={() => onSave(this.state) }>Save</AsyncButton>
          <Button variant="contained" color="secondary" onClick={onClose}>Cancel</Button>
        </div>
      </form>
    );
  }
}

export default OrganizationForm;

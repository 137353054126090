import React from 'react'
// import Editor from '../../../template/common/tinymce-editor'
import {TextField, Button, Container, CircularProgress} from '@material-ui/core'
import Service from '../../../../../services/experiments'
import { connect } from 'react-redux';
import { showMessage } from '@ses-education/courses-components';

const emptyExperiment = {
    course_experiment_id: null,
    title: null,
    image: null,
    description: null,
    html: null,
    active: true
}

class ExperimentEditor extends React.Component{


    state = {
        isChanged : false,
        experiment: null,
        original: null,
        changes: {}
    }

    componentDidMount(){
        const {
            course_experiment_id,
            title,
            image,
            description,
            html,
            active,
        } = this.props

        const experiment = {
            course_experiment_id,
            title,
            image,
            description,
            html,
            active,
        }

        this.setState( { experiment, original: {...experiment}, changes: {} } )
    }
    
    updateValue = ev => {
        // console.log( ev, val)
        const {currentTarget} = ev
        const {name, value} = currentTarget
        console.log(name, value )
        this.setFieldValue(name, value)
        // const {experiment, original, changes} = this.state
        
        // // update changes
        // if( value !== original[name] ){
        //     changes[name] = value
        // } else {
        //     if( original.hasOwnProperty(name))
        //         delete changes[name]
        // }

        // // update experiment
        // experiment[name] = value

        // this.setState( {experiment, changes } )
    }

    setFieldValue = (name, value) => {
        const {experiment, original, changes} = this.state
        
        // update changes
        if( value !== original[name] ){
            changes[name] = value
        } else {
            if( original.hasOwnProperty(name))
                delete changes[name]
        }

        // update experiment
        experiment[name] = value

        this.setState( {experiment, changes } )
    }

    save = async ev => {
        ev.preventDefault()
        let {experiment : oldExperiment, changes} = this.state
        const {course_id} = this.props
        if( Object.keys(changes).length > 0 ){
            
            const {course_experiment_id} = oldExperiment
            let experiment 

            // if ID was aleady set, update it after mixing in the experiment id
            if( course_experiment_id ){                                
                experiment = await Service.updateExperiment (course_id, 
                    // mix in experiment id
                    {...changes, course_experiment_id} 
                    )
            } else {
                // else add new
                experiment = await Service.addExperiment( course_id, changes )
            }

            if( experiment ){
                // let {experiment} = this.state
                let original = {...experiment}
                this.setState({experiment, original, changes: {} })
                if( this.props.onUpdate )
                    this.props.onUpdate( experiment )
            } else {
                this.props.onShowMessage('Error: ' + Service.error, 'error');
            }
        } else {
            console.log("No changes were made")
        }

    }

    render(){
        const {changes, experiment } = this.state        
        const isChanged = Object.keys(changes).length > 0

        if( !experiment ) return <Container><CircularProgress/></Container>
        
        
        const {course_experiment_id,
            title = "",
            image,
            description = "" ,
            html = "" ,
            active} = experiment
            
        return(
            <Container>                
            <form onSubmit={this.save}>
            
            <TextField 
            required 
            label="Title" 
            placeholder="type experiment title here" 
            value={title}
            fullWidth
            name="title"
            onChange={this.updateValue}

            />
            <TextField
             multiline
             label="Description"
             placeholder="type short experiment description here"
             rows={5}
             value={description}
             fullWidth
             name="description"
             onChange={this.updateValue}
              />
            <Button type="submit" disabled={!isChanged} >Save</Button>
        </form>
        </Container>
        )
    }
}

const mapDispatchToProps = (dispatch) =>{
    return {
        onShowMessage: (message, type) => dispatch(showMessage(message, type))
    }
}

export default connect(null, mapDispatchToProps) (ExperimentEditor);
import React from "react";
import {
  Grid,
  Button,
  Checkbox,
  Typography,
  // Modal,
  TextField,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogActions,
  Select,
  ListItem,
  ListItemText,
  DialogContent,
  ButtonGroup,
} from "@material-ui/core";
import UserService from "../../../services/users";
import { connect } from "react-redux";
import { showMessage } from "@ses-education/courses-components";
import {ModalWindow as Modal, AvatarSelector, AdminTable, Spinner} from "@ses-education/courses-components";
import "./modal-user-form.scss";
import { List, MenuItem } from "@material-ui/core";

import { AddBox, AssignmentInd, Delete } from "@material-ui/icons";
import OrganizationService from "../../../services/organizations";

const credentialConversion = {
  student: "Student",
  admin: "Admin",
  tester: "Tester",
  manager: "Manager",
  organization: "Organization",
};

const TabPanel = (props) => {
  const { children, value, index, tabname, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${tabname}-panel-${index}`}
      aria-labelledby={`${tabname}-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

class ModalUserForm extends React.Component {
  state = {
    name: "",
    last_name: "",
    email: "",
    password: "",
    passwordAgain: "",
    citizen_id: "",
    credentials: [],
    users: [],
    programs: null,
    allPrograms: null,
    error: null,
    emailError: null,
    passwordError: null,
    activityStatus: "created",
    allCredentials: null,
    image: null,
    selectedTab: 0,
    statusList: [],
    assignProgramDialog: false,
    selectedProgram: null,
    programToUnassign: null,
    unassignProgramDialog: false,
  };

  async componentDidMount() {
    await this.fetchUsers();
    await this.fetchStatusList();
    await this.fetchAvailableCredentials();
    // edit mode
    if (this.props.user) this.populateState();
    if (this.props.org_id) await this.fetchPrograms();
    console.log("ModalUserForm - componentDidMount ", this.state, this.props);
  }
  componentDidUpdate = async (prevProps, prevState) => {
    if (
      (!prevProps.user && this.props.user) ||
      prevProps.user !== this.props.user
    ) {
      this.populateState();
    }
    if (
      !(prevState.programs && prevProps.org_id) &&
      this.props.org_id &&
      this.state.programs
    ) {
      await this.fetchPrograms();
    }
  };
  shouldComponentUpdate(prevProps, prevState) {
    const {
      name,
      last_name,
      email,
      password,
      passwordAgain,
      passwordError,
      emailError,
      credentials,
      activityStatus,
      selectedTab,
    } = this.state;
    if (
      prevProps.onModalOpen !== this.props.onModalOpen ||
      prevProps.user !== this.props.user ||
      prevProps.org_id !== this.props.org_id ||
      prevProps.availableCredentials !== this.props.availableCredentials
    ) {
      return true;
    }

    if (
      prevState.name !== name ||
      prevState.last_name !== last_name ||
      prevState.email !== email ||
      prevState.password !== password ||
      prevState.passwordAgain !== passwordAgain ||
      prevState.passwordError !== passwordError ||
      prevState.emailError !== emailError ||
      prevState.credentials !== credentials ||
      prevState.activityStatus !== activityStatus ||
      prevState.selectedTab !== selectedTab ||
      prevState.citizen_id !== this.state.citizen_id ||
      prevState.programs !== this.state.programs ||
      prevState.allPrograms !== this.state.allPrograms ||
      prevState.statusList !== this.state.statusList ||
      prevState.assignProgramDialog !== this.state.assignProgramDialog ||
      prevState.unassignProgramDialog !== this.state.unassignProgramDialog
    ) {
      return true;
    }
    return false;
  }

  async fetchUsers() {
    try {
      const users = await UserService.getUsers();
      if (!users) {
        this.setState({ error: "Failed loading users" });
        return;
      }
      this.setState({ users });
    } catch (err) {
      this.props.onShowMessage(`Fetching users from server failed.`, "error");
    }
  }

  async fetchAvailableCredentials() {
    let allCredentials = await UserService.getCredentialsList();
    if (!allCredentials) {
      this.setState({ allCredentials, error: UserService.error });
      return;
    }

    allCredentials = allCredentials.filter((c) => c.credential_id);
    this.setState({ allCredentials });
  }

  fetchPrograms = async () => {
    const { org_id } = this.props;
    const { programs } = this.state;
    let allPrograms = await OrganizationService.getOrganizationPrograms(org_id);
    if (!allPrograms) {
      this.props.onShowMessage(
        OrganizationService.error || `Fetching programs from server failed.`,
        "error"
      );
      return;
    }
    //console.log('allPrograms',allPrograms, programs);
    if (Array.isArray(allPrograms) && Array.isArray(programs)) {
      allPrograms = allPrograms.filter(
        (program) => !programs.find((p) => p.program_id === program.program_id)
      );
    }
    //console.log('allPrograms',allPrograms);
    allPrograms = allPrograms.map((program) => {
      return {
        ...program,
        id: program.program_id,
      };
    });
    this.setState({ allPrograms });
  };
  fetchStatusList = async () => {
    const statusList = await UserService.getStatusList();
    if (!statusList) {
      this.props.onShowMessage(
        UserService.error || `Fetching status list from server failed.`,
        "error"
      );
      return;
    }
    console.log("fetch status", statusList);
    this.setState({ statusList });
  };
  populateState() {
    if (this.props.user) {
      const { availableCredentials, user } = this.props;
      // let credentials = [];
      let credentials = Array.isArray(user.credentials)
        ? user.credentials.filter((c) => availableCredentials?.includes(c))
        : [];
      // filter only the relevant credentials
      // user.credentials.forEach((c) =>
      //   availableCredentials.includes(c) ? credentials.push(c) : null
      // );

      this.setState({
        name: user.name,
        email: user.email,
        citizen_id: user.citizen_id,
        last_name: user.last_name,
        credentials,
        image: user.image,
        activityStatus: user.status.status_id,
        programs: Array.isArray(user.programs)
          ? user.programs.map((p) => {
              return {
                ...p,
                id: p.program_id,
              };
            })
          : [],
      });
    }
  }

  // turns on/off credentials in state when box is checked/unchekced
  manageCredentialsState(credential) {
    const { credentials } = this.state;

    // if credential turned on pop it from credentials in state
    if (credentials?.includes(credential)) {
      this.setState((prevState) => ({
        credentials: [...prevState.credentials].filter((c) => c !== credential),
      }));
    } else {
      // if credential turned off
      this.setState((prevState) => ({
        credentials: [...prevState.credentials, credential],
      }));
    }
  }

  closeModal = () => {
    this.setState({
      name: "",
      last_name: "",
      email: "",
      password: "",
      citizen_id: "",
      passwordAgain: "",
      credentials: [],
      programs: null,
      allPrograms: null,
      statusList: [],
      assignProgramDialog: false,
      unassignProgramDialog: false,
      selectedProgram: null,
      programToUnassign: null,
    });

    this.props.onModalClose();
  };

  async submitHandler(ev) {
    if (ev.preventDefault) ev.preventDefault();
    else ev.returnValue = false;

    const {
      name,
      last_name,
      email,
      citizen_id,
      credentials,
      password,
      passwordAgain,
      activityStatus,
    } = this.state;
    const { user } = this.props;

    //send details update to server
    let dataRes;
    // if coming from organization
    if (this.props.org_id) {
      dataRes = await OrganizationService.updateOrganizationUser(
        {
          name,
          last_name,
          email,
          citizen_id,
          status: activityStatus,
        },
        user && user.id,
        this.props.org_id
      );
    }
    // if coming from user
    if (!this.props.org_id) {
      dataRes = await UserService.updateUser(
        {
          name,
          last_name,
          email,
          citizen_id,
          status: activityStatus,
        },
        user && user.id
      );
    }

    // if error happened - show message and exit
    if (!dataRes) {
      this.props.onShowMessage(
        this.props.org_id ? OrganizationService.error : UserService.error,
        "error"
      );
      return;
    }

    // when creating new user dataRes returns as an object {id: some-int}
    // when editing existing user dataRes returns as integer
    if (typeof dataRes === "object" && dataRes.id) dataRes = dataRes.id;

    // // send credentials update to server
    if (credentials) await this.credentialsHandler(credentials, dataRes);

    // check if passwords match, just in case there was an error in UI
    if (password !== "" && password !== passwordAgain) {
      this.props.onShowMessage("Passwords do not match!");
    }

    // send password update to server if new password was given and it coincedes with the second one
    if (
      password !== "" &&
      password === passwordAgain &&
      !(await UserService.updatePassword({ password }, dataRes))
    ) {
      this.props.onShowMessage(
        "Error updating password:" + UserService.error || "unknown error",
        "error"
      );
    }

    // send update back to users page
    await this.props.onChanges();

    this.closeModal();
    this.props.onShowMessage(
      user
        ? `User with id ${this.props.user.id} was updated.`
        : `User with id ${dataRes} was created`,
      "info"
    );
  }

  async credentialsHandler(newCredentials, id) {
    const { user, onShowMessage } = this.props;
    const oldCredentials = user ? user.credentials : [];
    console.log("credentialsHandler", newCredentials, oldCredentials);
    // convert object to array
    /* let newCredentialsArr = [];
    for (const [key, value] of Object.entries(newCredentialsObj)) {
      if (value) newCredentialsArr.push(key);
    } */

    if( !Array.isArray(newCredentials) ){
      onShowMessage("New Credentials passed in wrong format", "error");
      console.debug("credentialsHandler: newCredentials wrong format. Expected array, got:", newCredentials);
      return false;
    }

    // up -> credentials that must be added
    const up = newCredentials.filter(
      (c) =>
        !Array.isArray(oldCredentials) ||
        !oldCredentials.includes(c) ||
        // when adding new user in organization the api function add for the user credential of student
        // that means if we try adding the student credential her the api throw exception of duplicate key and dont finish the oparation
        (this.props.org_id && !user && !c === "student")
    );

    up.forEach((el) => {
      UserService.addCredentials(id, el);
    });

    // down -> credentials that must be deleted
    // fall back to empty array if old credentials in not an array
    const down = oldCredentials?.filter((c) => !newCredentials.includes(c)) || [];
    down.forEach((el) => {
      UserService.deleteCredentials(id, el);
    });
  }

  passwordHandler() {
    this.setState({ passwordError: null });
    const { password, passwordAgain } = this.state;
    if (password !== passwordAgain) {
      this.setState({ passwordError: "Passwords must match" });
    }
    if (password.length < 6)
      this.setState({
        passwordError: "Password must be at least 6 charachters long",
      });
  }

  emailHandler() {
    this.setState({ emailError: false });
    const emails = this.state.users.map((u) => u.email);
    const { email } = this.state;

    if (!this.props.user?.id && emails?.includes(email)) {
      this.setState({ emailError: true });
    }
  }

  handleDeleteImage = async () => {
    const { onShowMessage, user } = this.props;
    const { id } = user;
    // check that id is passed
    if (!id) {
      onShowMessage("Could not update image: user is not saved", "error");
      return false;
    }
    if (!(await UserService.deleteUserImage(id))) {
      onShowMessage(UserService.error || "Unknown error", "error");
    } else {
      onShowMessage("Image successfully deleted", "info");
    }
    // reload user data on image update
    await this.props.onChanges();
  };

  handleFiles = async ({ target }) => {
    const { onShowMessage, user } = this.props;
    const { id } = user;

    // check that id is passed
    if (!id) {
      onShowMessage("Could not update image: user is not saved", "error");
      return false;
    }

    const image = target.files && target.files[0] ? target.files[0] : null;
    // this.setState({ image }, () => console.log("image", this.state));
    if (!image) {
      onShowMessage("No image selected", "error");
      return false;
    }

    // build form data with file
    const data = new FormData();
    data.append("image", image);

    if (!(await UserService.updateUserImage(id, data))) {
      onShowMessage(UserService.error || "Unknown error", "error");
      return;
    }
    onShowMessage("Image successfully updated", "info");
    // reload user data on image update
    this.setState({ image: null }, async () => await this.props.onChanges());
  };

  assignProgramToUserHandler = async () => {
    const { selectedProgram, programs, allPrograms } = this.state;
    const { user, org_id, onShowMessage } = this.props;
    const result = await OrganizationService.assignProgramToUser(
      org_id,
      user.id,
      selectedProgram.program_id
    );
    if (!result) {
      onShowMessage(
        UserService.error || "cant assign program for user",
        "error"
      );
      return;
    }
    onShowMessage(
      `Program with id ${selectedProgram.program_id} was added to user with id ${user.id}.`,
      "info"
    );
    this.setState({
      programs: [...programs, selectedProgram],
      allPrograms: [...allPrograms].filter(
        (p) => p.program_id !== selectedProgram.program_id
      ),
      selectedProgram: null,
    });
  };

  unassignProgramFromUserHandler = async (program_id) => {
    const { user, org_id, onShowMessage } = this.props;
    const { programs, allPrograms, programToUnassign } = this.state;

    const result = await OrganizationService.unassignProgramToUser(
      org_id,
      user.id,
      program_id
    );
    if (!result) {
      onShowMessage(
        UserService.error || "cant assign program for user",
        "error"
      );
      return;
    }
    onShowMessage(
      `Program with id ${program_id} was removed from user with id ${user.id}.`,
      "info"
    );
    this.setState({
      programs: [...programs].filter((p) => p.program_id !== program_id),
      allPrograms: [...allPrograms, programToUnassign],
      programToUnassign: null,
      unassignProgramDialog: false,
    });
  };
  render() {
    const { onModalOpen, user, availableCredentials, org_id } = this.props;

    const {
      emailError,
      passwordError,
      credentials,
      name,
      last_name,
      email,
      password,
      image,
      passwordAgain,
      activityStatus,
      allCredentials,
      selectedTab,
      citizen_id,
      statusList,
      programs,
      allPrograms,
      assignProgramDialog,
      unassignProgramDialog,
      selectedProgram,
      programToUnassign,
    } = this.state;
    // console.log("selectedTab",selectedTab);
    const programs_columns = [
      { field: "id", hide: true },
      { field: "title", flex: 1, headerName: "name" },
      {
        field: "actions",
        headerName: "Actions",
        flex: 1,
        renderCell: ({ row }) => {
          return (
            <ButtonGroup>
              <Button
                onClick={
                  () =>
                    this.setState({
                      programToUnassign: row,
                      unassignProgramDialog: true,
                    })
                  // this.unassignProgramFromUserHandler(
                  //   row.program_id
                  // )
                }
                style={{ cursor: "pointer", fill: "#db0047" }}
              >
                <Delete />
              </Button>
            </ButtonGroup>
          );
        },
      },
    ];
    return (
      <Modal
        open={onModalOpen}
        onClose={this.closeModal}
        onSubmit={this.submitHandler.bind(this)}
        formClassName="modal-user-form"
        header={
          user
            ? "Edit user"
            : org_id
            ? "Add user to organization"
            : "Create new user"
        }
        buttons={
          (selectedTab === 0 || selectedTab === 1) && (
            <>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => this.closeModal()}
              >
                Cancel
              </Button>
              <Button
                // onClick={() => this.submitHandler()}
                type="submit"
                disabled={emailError || passwordError ? true : false}
                variant="contained"
                color="primary"
                style={{
                  marginLeft: "10px",
                }}
              >
                {user ? "Submit changes" : "Create user"}
              </Button>
            </>
          )
        }
      >
        {/* <form
          className="modal-window modal-user-form"
          onSubmit={this.submitHandler.bind(this)}
        > */}
        {/* <div className="modal-header header-container" xs={12} item>
          {id
            ? "Edit user"
            : org_id
            ? "Add user to organization"
            : "Create new user"}
        </div> */}
        <Tabs
          value={selectedTab}
          aria-label="user tabs"
          onChange={(event, tab) => this.setState({ selectedTab: tab })}
        >
          <Tab label="Properties" index={0} />
          <Tab label="Credentials" index={1} />
          {org_id && user && <Tab label="Programs" index={2} />}
        </Tabs>
        <TabPanel tabname={"Properties"} value={selectedTab} index={0}>
          <div className="users-root-container">
            {/* <div className="modal-content"> */}
            <Grid container className="image-fields-container">
              <Grid item xs={12} md={8} className="text-fields">
                {user ? (
                  <TextField
                    id="create-user-id"
                    label="User ID"
                    variant="outlined"
                    style={{ marginBottom: "6px" }}
                    disabled={true}
                    value={user.id}
                  />
                ) : null}
                <TextField
                  id="create-user-name"
                  label="Citizen Id"
                  variant="outlined"
                  style={{ marginBottom: "6px" }}
                  onChange={(event) =>
                    this.setState({ citizen_id: event.target.value })
                  }
                  value={citizen_id}
                />
                <TextField
                  id="create-user-name"
                  label="First Name"
                  variant="outlined"
                  style={{ marginBottom: "6px" }}
                  onChange={(event) =>
                    this.setState({ name: event.target.value })
                  }
                  value={name}
                />
                <TextField
                  id="create-user-lastname"
                  label="Last Name"
                  variant="outlined"
                  style={{ marginBottom: "6px" }}
                  onChange={(event) =>
                    this.setState({ last_name: event.target.value })
                  }
                  value={last_name}
                />
                <TextField
                  id="create-user-email"
                  label="User Email"
                  variant="outlined"
                  inputProps={{
                    type: "email",
                  }}
                  style={{ marginBottom: "6px" }}
                  error={emailError ? true : false}
                  helperText={emailError ? "Email already exist" : false}
                  onChange={(event) =>
                    this.setState({ email: event.target.value })
                  }
                  onBlur={() => this.emailHandler()}
                  value={email}
                />
                {user && (
                  <>
                    <TextField
                      id="create-user-password1"
                      type="password"
                      disabled={!user}
                      label="Password"
                      variant="outlined"
                      style={{ marginBottom: "6px" }}
                      onChange={(event) =>
                        this.setState({ password: event.target.value })
                      }
                      value={password}
                    />
                    <TextField
                      id="create-user-password2"
                      type="password"
                      disabled={!user}
                      label="Repeat Password"
                      variant="outlined"
                      onChange={(event) =>
                        this.setState({ passwordAgain: event.target.value })
                      }
                      onBlur={() => this.passwordHandler()}
                      error={passwordError ? true : false}
                      helperText={passwordError ? passwordError : false}
                      value={passwordAgain}
                    />
                  </>
                )}
              </Grid>
              <Grid className="image-container" item xs={12} md={4}>
                <AvatarSelector
                  image={user && user.image}
                  onChange={this.handleFiles}
                  onDelete={this.handleDeleteImage}
                />
                {/* <img
                  src={`${process.env.PUBLIC_URL}/static/images/userPlaceholder.jpg`}
                  alt="user-placeholder"
                  // style={{
                  //   width: "250px",
                  //   height: "auto",
                  //   border: "solid 5px rgb(63, 81, 181)",
                  //   cursor: "pointer",
                  // }}
                ></img> */}
              </Grid>
              {user && (
                <Grid container className="modal-switch">
                  <Grid item style={{ display: "flex" }}>
                    <AssignmentInd />
                    <Typography>
                      {activityStatus ? "Active" : "Suspended"}
                    </Typography>
                    <Select
                      className="status-select"
                      // native
                      value={activityStatus}
                      onChange={(event) =>
                        this.setState({ activityStatus: event.target.value })
                      }
                    >
                      {statusList.map((status) => (
                        <MenuItem value={status.status_id}>
                          {status.status_title}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </div>
        </TabPanel>
        <TabPanel tabname={"Credentials"} value={selectedTab} index={1}>
          <div className="users-root-container">
            <div item className="credentials-container">
              {!allCredentials && <Spinner />}
              {Array.isArray(allCredentials) && (
                <List>
                  {allCredentials.map((credential) => (
                    <MenuItem for={`credential-${credential.credential_id}`}>
                      <Checkbox
                        id={`credential-${credential.credential_id}`}
                        onChange={() =>
                          this.manageCredentialsState(credential.credential_id)
                        }
                        checked={
                          credentials.find(
                            (el) => el === credential.credential_id
                          ) !== undefined
                            ? true
                            : false
                        }
                        //id={`${credential.credential_id}-checkbox`}
                      />
                      {credential.title}
                    </MenuItem>
                  ))}
                </List>
              )}
              {/* <Table>
                <TableHead>
                  <TableRow>
                    {["", ...availableCredentials].map((c) => (
                      <TableCell>{credentialConversion[c]}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{"Select user credentials"}</TableCell>
                    {availableCredentials.map((c) => (
                      <TableCell>
                        <Checkbox
                          onChange={() => this.manageCredentialsState(c)}
                          checked={
                            credentials.find((el) => el === c) !== undefined
                              ? true
                              : false
                          }
                          id={`${c}-checkbox`}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table> */}
            </div>
          </div>
        </TabPanel>
        {org_id && user && (
          <TabPanel tabname={"Programs"} value={selectedTab} index={2}>
            {org_id && !allPrograms && <Spinner />}
            {org_id && (
              <div className="assigned-programs">
                <div className="buttons-container">
                  {/* <Typography className="title">Assigned programs</Typography> */}
                  {Array.isArray(allPrograms) && allPrograms.length > 0 && (
                    <Button
                      variant="contained"
                      className="bg-green color-white"
                      onClick={() =>
                        this.setState({ assignProgramDialog: true })
                      }
                    >
                      <AddBox />
                      Assign program
                    </Button>
                  )}
                  {programs && programs.length == 0 && (
                    <Typography style={{ textAlign: "center" }}>
                      No programs assigned
                    </Typography>
                  )}
                  {Array.isArray(programs) && programs.length > 0 && (
                    <AdminTable
                      autoHeight
                      columns={programs_columns}
                      rows={programs}
                    />
                  )}
                </div>
                {/* Assign dialog */}
                <Dialog
                  open={assignProgramDialog}
                  onClose={() =>
                    this.setState({
                      assignProgramDialog: false,
                      selectedProgram: null,
                    })
                  }
                >
                  <DialogTitle>
                    {`Choose program to assign to user ${name} ${last_name}`}
                  </DialogTitle>
                  <DialogContent>
                    <List>
                      {Array.isArray(allPrograms) &&
                        allPrograms.map((program) => {
                          const labelId = `checkbox-list-label-${program.program_id}`;
                          return (
                            <ListItem
                              key={program.program_id}
                              dense
                              button
                              onClick={() =>
                                this.setState({
                                  selectedProgram: program,
                                })
                              }
                              selected={
                                selectedProgram &&
                                program.program_id ===
                                  selectedProgram.program_id
                              }
                            >
                              <ListItemText
                                id={labelId}
                                primary={`${program.program_id} - Program ${program.title}`}
                              />
                            </ListItem>
                          );
                        })}
                    </List>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        this.setState({
                          assignProgramDialog: false,
                          selectedProgram: null,
                        })
                      }
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={this.assignProgramToUserHandler}
                    >
                      Assign
                    </Button>
                  </DialogActions>
                </Dialog>
                {/* Unassign Confirm Dialog */}
                <Dialog
                  open={unassignProgramDialog}
                  onClose={() =>
                    this.setState({
                      unassignProgramDialog: false,
                      programToUnassign: null,
                    })
                  }
                >
                  <DialogTitle>
                    {programToUnassign &&
                      `Are you sure you want to unassign ${programToUnassign.title} from this student?`}
                  </DialogTitle>
                  <DialogActions>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        this.setState({
                          unassignProgramDialog: false,
                          programToUnassign: null,
                        })
                      }
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        this.unassignProgramFromUserHandler(
                          programToUnassign.program_id
                        )
                      }
                    >
                      Confirm
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            )}
          </TabPanel>
        )}
        {/* <div item className="modal-buttons-container buttons-container">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => this.closeModal()}
            >
              Cancel
            </Button>
            <Button
              // onClick={() => this.submitHandler()}
              type="submit"
              disabled={emailError || passwordError ? true : false}
              variant="contained"
              color="primary"
              style={{
                marginLeft: "10px",
              }}
            >
              {id ? "Submit changes" : "Create user"}
            </Button>
          </div> */}
        {/* </form> */}
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onShowMessage: (message, type) => dispatch(showMessage(message, type)),
  };
};
export default connect(null, mapDispatchToProps)(ModalUserForm);

import React from "react";
import { connect } from "react-redux";
import {
  showMessage,
  clearAllMessages,
} from "@ses-education/courses-components";
import { Typography, Button } from "@material-ui/core";
import {AutoBreadcrumbs} from "@ses-education/courses-components";
import { DataGrid } from "@material-ui/data-grid";

class NotificationsSettigns extends React.Component {
  render() {
    const { messages = [], breadcrumbs = [] } = this.props;
    return (
      <div>
        <AutoBreadcrumbs items={breadcrumbs} />
        <Typography variant="h2">Notifications</Typography>
        <DataGrid
          rows={messages}
          autoHeight
          columns={[
            { field: "id", hide: true },
            {
              field: "message",
              headerName: "Message",
              type: "text",
              align: "left",
              flex: 1,
            },
            { field: "type", headerName: "Type", type: "text", flex: 1 },
            {
              field: "hidden",
              headerName: "Closed",
              valueGetter: (params) => (params.row.hidden ? "Yes" : "No"),
              flex: 0.5,
            },
            { field: "timestamp", type: "text", align: "left", flex: 1 },
          ]}
        />

        <Button
          variant="contained"
          color="secondary"
          style={{ width: "300px", margin: "10px" }}
          onClick={() => this.props.onClearmessages()}
        >
          Clear all Notifications
        </Button>
      </div>
    );
  }
}

const stateToProps = (state) => ({
  messages: state.messages,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onShowMessage: (message, type) => dispatch(showMessage(message, type)),
    onClearmessages: () => dispatch(clearAllMessages()),
  };
};

export default connect(stateToProps, mapDispatchToProps)(NotificationsSettigns);

import { List, ListItem } from '@material-ui/core'
import {Link, Redirect} from 'react-router-dom'
import React from 'react'
import ProgramService from '../../../services/programs';
import ModalProgramForm from '../../template/modalProgramForm';
import Error from '../../template/Error';
import { Home, Translate } from '@material-ui/icons';
import {AutoBreadcrumbs, AdminTable} from "@ses-education/courses-components";
class TranslationsPrograms extends React.Component{

    state = {
        programs: null,
        redirect: null
    }

    componentDidMount(){
        this.fetchPrograms();
    }

    componentDidUpdate(prevProps){
        // reset redirect if used
        if( prevProps.program_id !== this.props.program_id){
            this.setState({ redirect: null })
        }
    }

    async fetchPrograms(){
        const programs = await ProgramService.getPrograms();
        const error = programs ? null : ProgramService.error || "Unknown programs error";
        this.setState({programs, error});
    }

    render(){
        let breadcrumbs = [
            { icon: <Home/>, to: "/"},
            { icon: <Translate/>, to: "/translations",  text: "Translations"}, 
            { to: "/translations/programs",  text: "programs"}           
        ]
        const columns = [
            { field: "id", flex: 0, headerName: "ID" },
            {
              field: "title",
              flex: 1,
              headerName: "Title",
              renderCell: (params) => (
                  <Link to={`/translations/programs/${params.row.program_id}`}>
                  {params.row.title}
                </Link>
              ),
            },
            {
              field: "translations",
              flex: 1,
              headerName: "Translations",
              renderCell: (row) => <>{row.value.map((p)=>p.language_id).join(", ")}</>,
            },
          ];
      
        const {error, programs, redirect } = this.state;
        const {program_id} = this.props;
        if( redirect ) return <Redirect push to={redirect} />
        
        if( error) return <Error error={error} />
        return(
            <>
                <AutoBreadcrumbs 
                items={breadcrumbs}
                />
                <AdminTable
                autoHeight
                pageSize={10}
                rows={
                    Array.isArray(programs) ? 
                    programs.map((p)=>({...p,id:p.program_id})):
                    []
                }
                searchFields={
                    ["title"]
                }
                {...{
                    columns,
                }}
                />
                <div className="programs">
                    {program_id && <ModalProgramForm
                        onModalOpen={Boolean(program_id)}
                        id={program_id}
                        mode={"translation"}
                        // org_id={org_id}
                        // courses={courses} 
                        onModalClose={() => this.setState({redirect: "/translations/programs"}) }
                        onChanges={this.fetchPrograms.bind(this)}
                    />}
                </div>
            </>
            )
    }
}

export default TranslationsPrograms
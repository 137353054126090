import { Button, ButtonGroup, Grid, TextField } from '@material-ui/core'
import React from 'react'
import {debounce} from '../../../../utils/functions';
import { defaultCoursesConfig } from '../../../../data/tinymce-configs';
import TinyEditor from '../../../template/common/tinymce-editor';
import "./translation-desk.scss"

const tinymceKey = process.env.REACT_APP_TINYMCE;


const Input = (props) => <TextField {...props} />

  

const Edit = ({value, onSave}) =>{
return <TinyEditor
  // before={<div className='editor-before' data-ln1={translationLanguage}></div>}
  apiKey={tinymceKey}
  className="editor"
  content={value}
  // disabled={savingHtml}
  onSave={onSave}
  // onCancel={cancel}
  init={defaultCoursesConfig}
/>
} 

const InputDisplay = ({value, originalLanguage}) => <div data-ln1={originalLanguage} className='input-display'>{value}</div>
const EditDisplay = ({value, originalLanguage}) => <div data-ln1={originalLanguage} className='edit-display' dangerouslySetInnerHTML={{__html: value}} ></div>
const ParserDisplay = ({value}) => <div className='parser-display'></div>



class TranslationDesk extends React.Component{

    state  = {
        currentValue: null,
        isChanged: false,
        updating: false,
        title: null,
        description: null
    }



    componentDidMount(){
      const {value : currentValue} = this.props;

      this.setState({currentValue})

      this.debouncedUpdate = debounce( (v) => { 
        // set 'updating' to false when sending data to server. 
        // If response comes back and 'updating' is still false - the componentDidUpdate will update state
        // If the value had been changed and 'updating' is true, state won't be updated.
        this.setState({updating: false})
        this.props.onUpdate(v);  
      }  , 1000 );
    }

    componentDidUpdate(prevProps){
      const {value : currentValue} = this.props;
      // if not updating value at the moment - can update the state.
      if( ! this.state.updating &&  JSON.stringify(currentValue) !== JSON.stringify(prevProps.value) )
        this.setState({currentValue, updating: false})
    }

    handleInput = ( {target}) => {
      
      const {value} = target;
      // update state
      this.setState({currentValue: value, updating: true })
      
      // debounced-update on server
      this.debouncedUpdate(value) 

    }

    render(){
        const { originalValue, value, onUpdate, type, title, originalLanguage, translationLanguage, ...other} = this.props;

        const {currentValue, isChanged } = this.state;

        return( <div className="translation-desk">
            <div className="label">{title}
            {/* <ButtonGroup className='buttons'>
                <Button size="small" disabled={!isChanged}>Save</Button>
            </ButtonGroup> */}
            </div>
            <Grid container spacing={4}>                
                <Grid item xs={12} sm={6} className="english" data-ln1={originalLanguage}>
                    { type === "input" && <InputDisplay {...{value: originalValue, ...other}} /> }
                    { type === "html" && <EditDisplay {...{value: originalValue, ...other}} /> }
                    { type === "parser" && <EditDisplay {...{value: originalValue, ...other}} /> }
                </Grid>
                <Grid item xs={12} sm={6} className="translation"  data-ln1={translationLanguage}>
                    { type === "input" && <Input {...{ ...other, value: currentValue, onChange : this.handleInput } }/> }                                        
                    { type === "html" && <Edit {...{ ...other, value: currentValue, onSave : onUpdate } }/> }
                    { type === "parser" && <Edit {...{ ...other, value: currentValue, onSave : onUpdate} }/> }
                </Grid>
            </Grid>
        </div>)
    }
}

export default TranslationDesk
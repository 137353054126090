import { Button, Modal, Tab, Tabs } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";
import { Link } from "react-router-dom";
import CourseService from "../../../../services/course";
import ExperimentService from "../../../../services/experiments";
// import AdminTable from "@ses-education/courses-components/admin-table";
import Error from "../../../template/Error";
// import Spinner from "../../../template/spinner";
// import experiments from "../../courses/experiments";
import experiment_pages from "../../../../data/experiment_pages.json";
import TranslationSelect from "../../../template/translation-select";
import TranslationDesk from "../translation-desk";
import store from "../../../../redux/store";
import { showMessage } from "@ses-education/courses-components";
import QuestionsEditor from "./questions-editor";

class TranslationsCoursesItemExperiment extends React.Component {
  state = {
    experiment: null,
    error: null,
    language_id: null,
    curentTranslation: null,
    original: null,
    pageQuestions: null
  };

  componentDidMount() {
    this.fetchExperiment();
    this.fetchPageQuestions();    
  }

  componentDidUpdate(prevProps) {
    if (prevProps.course_id !== this.props.course_id) this.fetchExperiment();
    if( prevProps.page !== this.props.page ) this.fetchPageQuestions();
    if( prevProps.language_id !== this.props.language_id) this.selectTranslation();
  }

  fetchExperiment = async () => {
    const { experiment_id } = this.props;
    if (!experiment_id) {
      this.setState({ experiment: null });
      return;
    }
    
    const experiment = await ExperimentService.getExperiment(experiment_id);
    const error = experiment
      ? null
      : CourseService.error || "Unknown experiment data error";

    this.setState({ experiment, error }, this.selectTranslation);
  };

  fetchPageQuestions = async() => {
    const {page, experiment_id} = this.props;
    if( !page || !experiment_pages[page] || !experiment_pages[page].has_questions ) {
      this.setState({pageQuestions: null})
      return;
    }

    try{      
      const pageQuestions = await ExperimentService.getPageQuestions( experiment_id, page );
      this.setState({pageQuestions})
    }catch(e){
      store.dispatch( showMessage(e.message, "error"))
      this.setState({pageQuestions: null})
    }
  }

  selectTranslation = () => {
    const { experiment, language_id } = this.state;
    let currentTranslation = null,
      original = null;

    if (!experiment) return;
    if (!Array.isArray(experiment.translations) || !language_id)
      this.setState({ currentTranslation, original });

    currentTranslation = experiment.translations.find(
      (t) => t.language_id === language_id
    );
    original = experiment.translations.find((t) => t.language_id === "en");
    this.setState({ currentTranslation, original });
  };

  shouldIClose = () => {
    // TODO: check editing state and confirm closing/saving
    this.props.onClose();
  };

  selectLanguage = (language_id) =>
    this.setState({ language_id }, this.selectTranslation);

  addTranslation = async (language_id) => {
    const {experiment_id} = this.props;
    console.debug("add translation", language_id, "to experiment", experiment_id);

    try {
      await ExperimentService.addTranslation(experiment_id, language_id)
      store.dispatch( showMessage("Translation created" ))
      this.fetchExperiment();
    } catch(e){
      store.dispatch( showMessage(e.message, "error" ))
    }

  };

  onUpdateField = async (field, value)  => {
    console.debug("updating", field)

    const {course_id} = this.props;
    const {currentTranslation } = this.state;
    if( !course_id ) {
      store.dispatch( showMessage("Course not selected! (wow...)", "error"))
      return false;
    }
    // can't get translation id (course_experiment_id of the actual record in database) - report it and quit
    if( !currentTranslation || !currentTranslation.translation_id) {
      store.dispatch( showMessage("Translation not selected or its data is damaged", "error"))
      return false;
    }

    const {translation_id: course_experiment_id } = currentTranslation;

    try{
      // console.debug("updating", course_experiment_id, "field", field, "to value:\n", value)
      await ExperimentService.updateExperimentDirect( { course_experiment_id, [field]: value } )
      store.dispatch( showMessage("Experiment updated") )
      this.fetchExperiment()
    } catch( e ){
      store.dispatch( showMessage(e.message, "error") )
    }


  }

  onQuestionsChange = () => {
    this.fetchPageQuestions();
  }

  render() {
    const {
      course_id,
      experiment_id,
      currentExperiment,
      error,
      page,
      question_id,
    } = this.props;
    const { experiment, language_id, currentTranslation, original, pageQuestions } =
      this.state;
    console.debug("experiment: ", experiment, "current translation:\n", currentTranslation);
    return (
      <div className="item-course-experiment">
        <TranslationSelect
          translations={experiment ? experiment.translations || [] : []}
          translation={currentTranslation ? currentTranslation.language_id : language_id  }
          onSelect={this.selectLanguage}
          onAdd={this.addTranslation}
        />
        <h2>
          {currentExperiment.title}
          {language_id && (
            <Tabs value={page || "title" } className="experiment-tabs" variant="scrollable">
              <Tab
                  id={`etab-title`}
                  value={"title"}
                  component={Link}
                  to={`/translations/courses/${course_id}/experiments/${experiment_id}`}
                  label="Experiment title"
                />
              {Object.values(experiment_pages).map((p) => (
                <Tab
                  id={`etab-${p.id}`}
                  value={p.id}
                  component={Link}
                  to={`/translations/courses/${course_id}/experiments/${experiment_id}/${p.id}`}
                  label={p.title}
                />
              ))}
            </Tabs>
          )}
        </h2>
        {!language_id && (
          <div className="text">
            Please, select language to edit translation
          </div>
        )}
        {language_id && !page && currentTranslation &&  (
          /* title edit when no page selected (title tab) */
          <div className="tab-content" id={`etab-title`} key={`tab-title`}>
            <TranslationDesk
              title="Experiment title"
              type="input"
              originalValue={original.title}
              value={currentTranslation.title}
              onUpdate={(value) => this.onUpdateField( "title" , value)}
              originalLanguage="en"
              translationLanguage={language_id}
              fullWidth
            />
          </div>
        )}
        {currentTranslation && (
          <>            
            {Object.values(experiment_pages).map((p) => {
              const {index} = p;
              const active = p.id === page ;
              return index.map( (field, ind) => (
                (
                  /* title tab */
                  <div className="tab-content" id={`etab-${p.id}`} key={`tab-${p.id}-${ind}`} style={{display: active ? "inherit" : "none"}}>
                    {p.has_questions && (
                      <>                      
                      <QuestionsEditor {...{pageQuestions, language_id}} onQuestionsChange={this.onQuestionsChange} showMessage={ (message, level) => store.dispatch( showMessage( message, level)) } />                     
                      { 
                      // only show content editor if it's the Experiment chapter, otherwise content consists only of single Quiz question and there's no need to translation anything ther
                      page === "experiment" && <TranslationDesk
                          title={p.title}
                          type="parser"
                          originalValue={original[field]}
                          value={currentTranslation[field]}
                          onUpdate={(value) => this.onUpdateField( field , value)}
                          originalLanguage="en"
                translationLanguage={language_id}
                        />
                      }
                      </>
                    )}
                    {!p.has_questions && (
                      <TranslationDesk
                        title={p.title}
                        type="html"
                        originalValue={original[field]}
                        value={currentTranslation[field]}
                        onUpdate={(value) => this.onUpdateField( field , value)}
                        originalLanguage="en"
                        translationLanguage={language_id}
                      />
                    )}
                  </div>
                )
              ))              
            })}
          </>
        )}

        <Button
          className="close-button"
          component={Link}
          to={`/translations/courses/${course_id}/experiments`}
        >
          <Close color="secondary" />
        </Button>
        {error && <Error error={error} />}
      </div>
    );
  }
}

export default TranslationsCoursesItemExperiment;

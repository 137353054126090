import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Grid,
  Typography,
  Container,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogActions,
  Paper,
} from '@material-ui/core';
import {
  EditRounded as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import courseService from '../../../../../services/course';
import experimentService from '../../../../../services/experiments';
import Loading from '../../../../template/common/loader';
import Experiment from './item';
// import Modal from '../../../../template/common/modal';
// import { showMessage } from '../@ses-education/courses-components/notifications/notificationActions';
import { showMessage } from '@ses-education/courses-components';
import { Link, Redirect } from 'react-router-dom';
import './experiments.scss';

const newExperiment = {
  course_experiment_id: null,
  title: '',
};

class ExperimentsPage extends React.Component {
  state = {
    experiments: null,
    experiment: null,

    experimentIsChanged: false,
    savingExperiment: false,
    experimentToDeleteID: false,
    createdNew: null,
  };

  componentDidMount() {
    this.fetchExperiments().then(() => this.selectExperiment());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.exp_id !== this.props.exp_id) {
      if(this.state.experiments){
        this.selectExperiment();
      } else {
        this.fetchExperiments().then(() => this.selectExperiment());
      }
    }
  }

  selectExperiment() {
    let { exp_id } = this.props;

    // have to cast it to integer so that the find works
    if (exp_id && exp_id !== 'new') exp_id = parseInt(exp_id);

    console.debug('Selecting experiment', exp_id);
    if (exp_id === 'new') {
      // new experiment
      console.debug('Selecting experiment: new');
      this.setState({ experiment: { ...newExperiment } });
    } else {
      const { experiments } = this.state;
      console.debug('Selecting experiment - experiments:', experiments);

      const experiment = experiments.find(
        (e) => e.course_experiment_id === exp_id
      );
      console.debug('Selecting experiment: ', experiment);
      if (experiment) {
        this.setState({ experiment });
      } else {
        this.setState({ experiment: false });
      }
    }
  }

  fetchExperiments = async () => {
    const { id } = this.props;
    const experiments = await courseService.getCourseExperiments(id);
    this.setState({ experiments });
  };

  onUpdate = (experiment) => {
    console.debug('updating experiments in course');

    this.fetchExperiments().then(() =>
      // this.setState({experiment})

      // update experiment in state
      {
        // if it's a new experiment, set its id to state so that the render redirects to the newly created experiment
        if (this.props.exp_id === 'new')
          this.setState({
            createdNew: experiment.course_experiment_id,
            experiment,
          });
        else this.setState({ experiment });
      }
    );
  };

  closeEditor = () => {
    this.setState({
      experiment: null,

      experimentIsChanged: false,
    });
  };

  closeDeleteDialog = () => {
    this.setState({ experimentToDeleteID: null });
  };

  deleteExpHandler = async () => {
    const { experimentToDeleteID } = this.state; // experiment.course_experiment_id;
    const result = await experimentService.deleteExperiment(
      experimentToDeleteID
    );
    if (result) {
      this.fetchExperiments();
    } else {
      // this is the way to show the error that was set by Service.Error()
      this.props.onShowMessage(experimentService.error, 'error');
    }
    // close the dialog when done
    this.closeDeleteDialog();
  };

  render() {
    const {
      experiments,
      experiment,
      savingExperiment,
      experimentToDeleteID,
      createdNew,
    } = this.state;

    const { id: course_id, exp_tab } = this.props;

    if (createdNew) {
      // if have just created a new experiment, redirect to its page
      this.setState({ createdNew: null }); // reset flag
      return (
        <Redirect to={`/courses/${course_id}/experiments/${createdNew}`} />
      );
    }

    return (
      <Container id='experiments'>
        {/* ---------------------------experiment is selected-------------------------------- */}
        {experiment ? (
          <Paper elevation={1} className='experiment-edit'>
            <Experiment
              onUpdate={this.onUpdate}
              course_id={course_id}
              exp_tab={exp_tab}
              {...experiment}
            />
          </Paper>
        ) : (
          <>
            {/* ---------------------------before experiment is selected-------------------------------- */}
            <Grid container>
              <Grid item xs={12}>
                {/* --------------------------------header------------------------------------- */}
                <Typography variant='h4'>Course experiments</Typography>
                {/* ---------------------------buttons panel-------------------------------- */}
                <div className='button-panel'>
                  <Button
                    variant='outlined'
                    color='primary'
                    component={Link}
                    to={`/courses/${course_id}/experiments/new`}
                  >
                    Add experiment
                  </Button>
                </div>
                <Loading data={experiments}>
                  {(props) => (
                    <Grid container spacing={4} className='experiment-list'>
                      {/* ---------------------------list of all experiments-------------------------------- */}
                      {props.length > 0 ? (
                        props.map((e, ind) => (
                          <Grid item xs={12} key={ind} className='list-item'>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={4}>
                                <Typography
                                  variant='h5'
                                  color='primary'
                                  align='left'
                                >
                                  Experiment {ind + 1}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <Typography
                                  variant='h5'
                                  color='primary'
                                  align='left'
                                >
                                  {e.title}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <ButtonGroup
                                  variant='text'
                                  color='secondary'
                                  aria-label='contained secondary button group'
                                >
                                  <Button
                                    variant='outlined'
                                    color='secondary'
                                    component={Link}
                                    to={`/courses/${course_id}/experiments/${e.course_experiment_id}`}
                                  >
                                    <EditIcon fontSize='small' />
                                    edit
                                  </Button>
                                  <Button
                                    variant='outlined'
                                    color='secondary'
                                    onClick={() =>
                                      this.setState({
                                        experimentToDeleteID:
                                          e.course_experiment_id,
                                      })
                                    }
                                  >
                                    <DeleteIcon fontSize='small' />
                                    delete
                                  </Button>
                                </ButtonGroup>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))
                      ) : (
                        <Typography>No experiments added so far</Typography>
                      )}
                    </Grid>
                  )}
                </Loading>
              </Grid>
            </Grid>
            <Dialog
              open={experimentToDeleteID}
              onClose={this.closeDeleteDialog}
            >
              <DialogTitle>
                <Typography style={{ fontWeight: 'bold', fontSize: 22 }}>
                  Do you really want to delete this experiment?
                </Typography>
              </DialogTitle>
              <DialogActions>
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={this.deleteExpHandler}
                >
                  Delete
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={this.closeDeleteDialog}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onShowMessage: (message, type) => dispatch(showMessage(message, type)),
  };
};

export default connect(null, mapDispatchToProps)(ExperimentsPage);

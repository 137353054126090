import Base from './baseClientService';

class ProgressService extends Base {

  static async checkAnswer( answerObject ){
    const question_id = Object.keys(answerObject)[0]
    const value = answerObject[question_id];

    try {
      return await this.POST(`/admin/progress/check-answer/${question_id}`, {value});      
    } catch (e) {
      console.error('Error fetching programs: ', e.message);
      this.Error(e.message);
      return false;
    }
  }

  static async getPrograms() {
    try {
      const programs = await this.GET(`/admin/programs`);
      return programs;
    } catch (e) {
      console.error('Error fetching programs: ', e.message);
      this.Error(e.message);
      return false;
    }
  }

}
Base.extend(ProgressService);

export default ProgressService;

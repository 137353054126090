import React from 'react';
import {ModalWindow, AsyncButton, ConfirmDialog} from '@ses-education/courses-components';
import {debounce} from '@ses-education/content-parser';
import {Button, FormControl, FormControlLabel, InputLabel, Menu, MenuItem, Select, Switch, TextField, Typography} from '@material-ui/core';
import {FormatTextdirectionLToR, FormatTextdirectionRToL, Save, MoreVert, Delete} from '@material-ui/icons';
import Actions from '../../../../redux/actions';
import SearchableDropdown from '../../../template/searchable-dropdown';


class LanguageForm extends React.Component {

    state = {
        language: null,
        original: null,
        isChanged: false,
        optionsMenuAnchor: null,
        deleteConfirmId: null
    }

    updateFromProps(){
        const {language} = this.props;

        console.debug("updating from props:", this.props)
        this.setState({
            language,
            original: {...language},
            isChanged: false
        })
    }

    componentDidMount(){
        this.updateFromProps();
    }

    componentDidUpdate(prevProps){
        // compare by stringifying... not really elegant
        if( JSON.stringify(prevProps) !== JSON.stringify(this.props)){
            this.updateFromProps();
        } else {
            console.debug("no change", prevProps, this.props)
        }
    }

    debouncedChange = (target) => debounce( () => {
        const {name, value} = target;
        const {language} = this.state;
        language = {...language, [name]: value }
        this.setState({language})
    }, 500)

    // changeValue = ({target}) => 
    changeValue = ({target}) => {
        const {name, value} = target;
        let {language, original} = this.state;
        language = {...language, [name]: value }
        this.setState({
            language, 
            // compare updated to original by stringifying both (not very elegant, but works...)
            isChanged: JSON.stringify(language) !== JSON.stringify(original)
        })
    }

    setId = ({iso639_1, english_name}) => {
        let {language, original} = this.state;
        language = {...language, "iso639-1": iso639_1, english_name }
        this.setState({
            language, 
            // compare updated to original by stringifying both (not very elegant, but works...)
            isChanged: JSON.stringify(language) !== JSON.stringify(original)
        })
    }

    onSave = async () => {
        let {language} = this.state;
        // separate id
        const {id, ...other} = language;

        // get callback
        const {onSave = console.debug} = this.props;

        if( typeof onSave === "function" ){
            try {
                await onSave(other);
                return true;
            } catch(e){
                Actions.showMessage(e?.message || "Unknown error", "error");
            }
        }
    }

    deleteLanguage = async () => {
        const {onDelete, language, onClose } = this.props;

        if( typeof onDelete !== "function" ){
            Actions.showMessage("Error: delete function not found", "error");
            return false;
        }

        if( !language || !language["iso639-1"]) {
            Actions.showMessage("No ISO code found, the language probably isn't saved yet", "error");
            return false;
        }
        // close confirm dialog
        this.setState({deleteConfirm: false})

        return onDelete(language["iso639-1"]);
    }

    toggleActive = () => {
        let {language, original} = this.state;
        language = {...language, active: language.active === 0 ? 1 : 0 }
        this.setState({
            language, 
            // compare updated to original by stringifying both (not very elegant, but works...)
            isChanged: JSON.stringify(language) !== JSON.stringify(original)
        })
    }

    render(){
        const {language : original, iso_codes, onSave, onClose, ...other} = this.props;
        const {language, isChanged, optionsMenuAnchor, deleteConfirm} = this.state;

        console.debug("state:", this.state);
        return (
            <ModalWindow
                header={original && original["iso639-1"] ? "Editing language" : "Adding new language"}
                {...other}
                onClose={onClose}
            >   
                <div className="flex align-self-stretch row justify-content-space-between p-bottom-12">

                    <div>
                        {language && language["iso639-1"] && <Typography><span className='p-right-6'>ISO639-1 code:</span> {language["iso639-1"]}</Typography>}
                        {language && language["iso639-1"] && <Typography><span className='p-right-6'>English title:</span> {language["english_name"]}</Typography>}
                    </div>

                    { 
                    original && !original["iso639-1"] && language && 
                                Array.isArray(iso_codes) && (
                        <div>

                            <InputLabel id="select-iso" >Select language</InputLabel>
                                <SearchableDropdown
                                    items={iso_codes}
                                    onSelect={this.setId}
                                    searchFields={["iso639_1","english_name", "english_short", "direction"]}
                                    textFieldProps={{
                                        placeholder: "Start typing language name...",
                                    }}
                                    
                                    getOptionLabel={(o) => o["iso639_1"] + " : " + o.english_name}
                                    
                                />
                               
                                {/* <Select fullWidth name="iso639-1" value={language["iso639-1"] || ""}>
                                    {Array.isArray(iso_codes) && 
                                    iso_codes.map(ic => 
                                        <MenuItem value={ic["iso639_1"]}
                                        onClick={() => this.setId(ic)}
                                        >
                                            {ic["iso639_1"]}: {ic["english_name"]}
                                        </MenuItem>
                                    )}
                                </Select>  */}
                        </div>
                        )
                        }
                    
                    
                    {
                        original && original["iso639-1"] && <div>
                            <Button onClick={(ev) => this.setState({optionsMenuAnchor: ev.target})}><MoreVert /></Button>
                            <Menu 
                                anchorEl={optionsMenuAnchor} 
                                onClose={()=> this.setState({optionsMenuAnchor: null})}
                                open={Boolean(optionsMenuAnchor)}
                            >
                                <MenuItem onClick={() => this.setState({deleteConfirm: true, optionsMenuAnchor: null})}><Delete htmlColor='red' /> Delete</MenuItem>
                            </Menu>
                        </div>
                    }
                </div>
                
                {
                // if iso639-1 is selected, we can edit the editable part.
                language && language["iso639-1"] && (
                    <>
                        <TextField className="m-bottom-10" fullWidth label="English abbreviation (3 letters)" max={3} name="english_short" value={language?.english_short} onChange={this.changeValue}/>
                        <TextField className="m-bottom-10" fullWidth label="Local abbreviation (3 letters)" name="local_short" value={language?.local_short} onChange={this.changeValue}/>
                        <TextField className="m-bottom-10" fullWidth label="Title in local language" name="local_name" value={language?.local_name} onChange={this.changeValue}/>

                        <div className="flex align-self-stretch justify-content-space-between">

                            <FormControl className=''>
                                <InputLabel id="direction-select-label">Text direction</InputLabel>
                                <Select labelId="direction-select-label" name="direction" value={language?.direction} onChange={this.changeValue}>
                                    <MenuItem value="ltr"><FormatTextdirectionLToR/> Left to Right</MenuItem>
                                    <MenuItem value="rtl">Right to Left <FormatTextdirectionRToL/></MenuItem>
                                </Select>
                            </FormControl>

                            <FormControlLabel
                            value="top"
                            control={<Switch labelId="active-lang" checked={language.active} onChange={this.toggleActive}/>}
                            label="Language is Active"
                            labelPlacement="top"
                            />
                            
                        </div>
                    </>
                )}
                <div className="flex align-self-stretch justify-content-space-between p-top-6 m-top-6 b-top-1">
                    <AsyncButton color="primary" variant="contained" icon={<Save/>} disabled={!isChanged} onClick={this.onSave}>Save</AsyncButton>
                    <Button onClick={onClose} color="secondary" variant="contained" >Close</Button>
                </div>
                
                {
                    language && language["iso639-1"] && (
                        <ConfirmDialog
                            open={Boolean(deleteConfirm)}
                            onClose={() => this.setState({deleteConfirm: null})}
                            onConfirm={() => this.deleteLanguage(language["iso639-1"])}
                            header="Warning: You are trying to DELETE the language"
                            prompt="Are you sure you want to delete this language? The operation won't succeed if there are translations created for this language."
                        />
                    )

                }
            </ModalWindow>
        )
    }
}

export default LanguageForm;
// import tinymce from '../../tiny';
import tinymce from '../../tiny';
import Service from '../../../../../../services/experiments';

tinymce.PluginManager.add('color', function (editor) {
  let contextMenuQuestionId = null,
    contextMenuQuestionType = null;

  const addClickListener = (element) => {
    element.addEventListener('click', function(event) {
        // Prevent default click behavior
        event.preventDefault();
        event.stopPropagation();

        const questionId = this.getAttribute('data-question-id');
        
        // Implement your logic to handle the click event
        console.debug('Edit color question:', questionId);
        editQuestion(questionId);
    });
  }
/**
* Parse the content for elements of colorDrop type and add click listener to them that opens editor
*/
editor.on('init', function() {
  console.debug("Initializing colorDrop plugin");
  // Access the editor's content body
  var contentBody = editor.getBody();

  // Query for elements with 'data-question-id'
  var questionElements = contentBody.querySelectorAll('[data-question-id][data-question-type="color"]');
  console.debug("...found", questionElements.length, "color questions on page");
  // Add click listener to each element
  questionElements.forEach( addClickListener );
});

const editQuestion = async function(questionId){
  const { course_id, course_experiment_id } = editor.settings;
  const serverRes = await Service.getQuestion(
    course_id,
    course_experiment_id,
    questionId
  );

  if (serverRes) {
    const { question_data, question_id } = serverRes;
    openDialog({
      // have to mix in the question ID.
      // Also had to cast it to string for it was giving problems with input type for some reason.
      ...question_data,
      question_id: questionId.toString(),
    });
  } else {
    // TODO: show notification or tinymce alert
    console.error('Error fetching question data:', questionId);
  }
}


  const openDialog = function (config) {
    return editor.windowManager.open({
      title: 'Color dropdown question',
      body: {
        type: 'panel',
        items: [
          {
            type: 'input',
            label: 'Question ID (empty if creating a new question)',
            disabled: true,
            inputMode: 'numeric',
            name: 'question_id',
          },
          {
            type: 'listbox',
            name: 'colorsListBox',
            label: 'Select correct answer:',
            items: [
              { text: 'Red', value: 'red' },
              { text: 'Green', value: 'green' },
              { text: 'Orange', value: 'orange' },
              { text: 'Gold', value: 'gold' },
              { text: 'Brown', value: 'brown' },
              { text: 'Yellow', value: 'yellow' },
              { text: 'Black', value: 'black' },
              { text: 'White', value: 'white' },
              { text: 'Silver', value: 'silver' },
            ],
          },
        ],
      },
      buttons: [
        {
          type: 'cancel',
          text: 'Close',
        },
        {
          type: 'submit',
          text: config ? 'Update' : 'Save',
          primary: true,
        },
      ],
      initialData: {
        question_id: config ? config['question_id'] : '',
        colorsListBox: config ? config['color'] : '',
      },
      //when "save" button is pressed
      onSubmit: async function (api) {
        const data = api.getData();
        const { colorsListBox, question_id } = data;
        let questionData = { color: colorsListBox, question_type: 'color' };
        const { course_id, course_experiment_id } = editor.settings;

        //if question already has ID, add it to API call to triger update
        if (parseInt(question_id) > 0) {
          questionData = {
            ...questionData,
            question_id,
          };
        }

        // make API call to receive color-question ID
        const colorDropId = await Service.updateQuestion(
          course_id,
          course_experiment_id,
          questionData
        );

        // if error happened - show it and return
        if( !colorDropId ){
          editor.notificationManager.open({
            text: Service.error || "Unknown error",
            type: 'error',
            timeout: 2000
          });

          return false;
        }

        //insert the content
        const result = `<span data-question-id="${colorDropId}" data-question-type="color" data-chosen-color="${colorsListBox}" class="question color-drop" data-updated="${Date.now()}">${'-----'}</span>`;

        if (config && config.question_id) {
          // when editing, delete the old question

          // find the old question
          const elm = editor.dom.select(
            `[data-question-id="${config.question_id}"]`
          )[0];

          // this removes only the found element
          if (elm) editor.dom.remove(elm);
        }

        editor.insertContent(result);

        // find the newly inserted element
        const elm2 = editor.dom.select(
          `[data-question-id="${config.question_id}"]`
        )[0];


        // add listener
        addClickListener(elm2);

        api.close();
      },
    });
  };

  //add button to the tools menu
  editor.ui.registry.addButton('color', {
    text: 'Color dropdown',
    onAction: function () {
      openDialog();
    },
  });

  //adds the ability to click the "Change color" option in the context menu
  editor.ui.registry.addContextMenu('color', {
    //function fires when context menu is opened (right clicking an element)
    update: function (element) {
      console.debug('color context menu');
      // parse the ID & TYPE from the element, save in global variables
      contextMenuQuestionId = element.dataset['questionId'];
      contextMenuQuestionType = element.dataset['questionType'];

      const result =
        Boolean(contextMenuQuestionId) && contextMenuQuestionType === 'color'
          ? 'color'
          : '';
      console.debug("colorDrop menu check:", result);
      return result;
    },
  });

  // Adds the "Change color" option when right clicked
  editor.ui.registry.addMenuItem('color', {
    text: 'Change color',

    //function fires when question context menu selected
    onAction: () => {
      console.debug("Opening question with id", contextMenuQuestionId);
      editQuestion(contextMenuQuestionId);
    }

    // onAction: async function () {
    //   const { course_id, course_experiment_id } = editor.settings;
    //   const serverRes = await Service.getQuestion(
    //     course_id,
    //     course_experiment_id,
    //     contextMenuQuestionId
    //   );

    //   if (serverRes) {
    //     const { question_data, question_id } = serverRes;
    //     openDialog({
    //       // have to mix in the question ID.
    //       // Also had to cast it to string for it was giving problems with input type for some reason.
    //       ...question_data,
    //       question_id: question_id.toString(),
    //     });
    //   } else {
    //     // TODO: show notification or tinymce alert
    //     console.error('Error fetching question data');
    //   }
    // },
  });

  return {
    getMetadata: function () {
      return {
        name: 'color',
      };
    },
  };
});

import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import mergeReducers from "./mergeReducers";
import root from "./reducers";
import { notificationsReducers} from "@ses-education/courses-components";
console.debug("notificationsReducers:", notificationsReducers);
// const store  = createStore(root, compose(applyMiddleware(thunk)))
const store = createStore(
  mergeReducers([root,notificationsReducers], "sesAdminAppConfig"),
  compose(applyMiddleware(thunk))
);
export default store; 

import { List, ListItem, Typography } from "@material-ui/core";
import { ListAlt, School } from "@material-ui/icons";
import { Link } from "react-router-dom";
import React from "react";
import { Home, Translate, Language } from "@material-ui/icons";
import {AutoBreadcrumbs} from "@ses-education/courses-components";
import { connect } from "react-redux";
import { arraysIntersect } from "../../../utils/functions";

// items to display in list
const items = [
  {
    to: "/programs",
    title: "Programs",
    icon: <ListAlt />,
    credentials: ["content-translator"],
  },
  {
    to: "/courses",
    title: "Courses",
    icon: <School />,
    credentials: ["content-translator"],
  },
  {
    to: "/interface",
    title: "Interface translation",
    icon: <Translate />,
    credentials: ["interface-translator"],
  },
  {
    to: "/languages",
    title: "Available languages",
    icon: <Language />,
    credentials: ["admin"],
  },
];
class TranslationsIndex extends React.Component {
  render() {
    const { user } = this.props;
    // checks whether user has given credentials
    const hasCredentials = (cred) =>
      user &&
      Array.isArray(user.credentials) &&
      // user is admin
      (user.credentials.includes("admin") ||
        // or they have at least one of the credentials
        arraysIntersect(user.credentials, cred));

    let breadcrumbs = [
      { icon: <Home />, to: "/" },
      { icon: <Translate />, to: "/translations", text: "Translations" },
    ];
    return (
      <>
        <AutoBreadcrumbs items={breadcrumbs} />
        <Typography variant="h1">Translations</Typography>
        <List className="index">
          {items.map(
            (i) =>
              hasCredentials(i.credentials) && (
                <ListItem component={Link} to={`/translations${i.to}`}>
                  {i.icon && i.icon}
                  {i.title}
                </ListItem>
              )
          )}
        </List>
      </>
    );
  }
}

const stateToProps = (state) => ({
  user: state.user,
});

export default connect(stateToProps)(TranslationsIndex);

/**
 * cudos to https://medium.com/@mhagemann/the-ultimate-way-to-slugify-a-url-string-in-javascript-b8e4a0d849e1
 * @param {*} string
 */
export function slugify(string) {
  const a =
    "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
  const b =
    "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
  const p = new RegExp(a.split("").join("|"), "g");

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[^\w\-]+/g, "") // Remove all non-word characters
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
}

/**
 * From https://stackoverflow.com/a/175787/3770223
 * @param {*} str
 */
export function isNumeric(str) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

/**
 * Replace words wrapped with %..% with corresponding items from items{} object
 * E.g.
 * items: { %name%: "Me", %age%: 12 }
 * str: "I am %name" and I'm %age% years old. That's %name%."
 * strFormat(str, items) => "I am Me and I'm 12 years old. that's Me."
 * @param {*} str
 * @param {*} items
 */
export function strFormat(str, items) {
  return str.replace(/%\w+%/g, (key) => {
    return items[key] || key;
  });
}

/**
 * Kudos to https://stackoverflow.com/questions/19269545/how-to-get-n-no-elements-randomly-from-an-array/38571132#comment74054264_38571132
 * @param {*} array
 * @param {*} num
 */
export const randomFromArray = (array, num) =>
  array.sort(() => 0.5 - Math.random()).slice(0, num);

/**
 * Loads an external script asynchronously and optionally executes a callback once it's done.
 * Kudos to https://stackoverflow.com/a/53396827/3770223
 * @param {*} url
 * @param {*} callback
 */
export function loadScript(url, callback = null) {
  const script = document.createElement("script");
  script.src = url;
  script.async = true;
  if (callback && typeof callback === "function")
    script.onload = () => callback();

  document.body.appendChild(script);
}

export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

/**
 * Replace anything within %..% with correponding eement from passed array
 * Kudos to https://stackoverflow.com/questions/7975005/format-a-javascript-string-using-placeholders-and-an-object-of-substitutions#comment100792501_35187109
 * @param {string} message
 * @param {string[]} values
 */
export function textFormat(message, values) {
  let i = 0;
  if (message)
    return message.replace(/%\w+%/g, (match, idx) => {
      return values[i++];
    });
  else {
    console.warn("textFormat: passed value is not a string, null returned.");
    return null;
  }
}

/**
 * Scrolls to given element with given offset in pixels
 */
export function scrollToElement(
  el,
  yOffset = 0,
  options = { behavior: "smooth" }
) {
  const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
  window.scrollTo({ ...options, top: y });
}

export function removePrefix(str, prefix) {
  return str.indexOf(prefix) === 0 ? str.substr(prefix.length) : str;
}

/**
 * Populates target object's fields recursively from corresponding fields in source object.
 * If field is not present in source, it is not changed in target object.
 * If target field type is object, the function is called recursively to populate the field.
 * If source object is null, target is returned unchanged.
 * If target object is null, null is returned.
 * @param {object|null} target
 * @param {object|null} source
 * @returns {object|null}
 */
export function populateObjectRecursively(target, source) {
  if (source === null || target === null) return target;

  if (typeof target === "object" && typeof source === "object") {
    Object.keys(target).map((key) => {
      if (source.hasOwnProperty(key))
        switch (typeof target[key]) {
          case "object":
            if (typeof source[key] === "object")
              // recursion
              target[key] = populateObjectRecursively(target[key], source[key]);
            else target[key] = source[key];
            break;
          default:
            target[key] = source[key];
            break;
        }
    });
  }
  return target;
}
/**
 * Returns true if arr1 contains at least one of elements of arr2
 * @param {*} arr1
 * @param {*} arr2
 * @returns
 */
export const arraysIntersect = (arr1, arr2) =>
  Array.isArray(arr1) &&
  Array.isArray(arr2) &&
  arr1.some((el) => arr2.includes(el));

/**
 * Returns an object that contains all properties of obj2 that differ from obj1.
 * "Differ" means JSON.stringify(obj1.prop) !== JSON.stringify(obj2.prop)
 * @param {object} original
 * @param {object} changed
 * @returns {object}
 * @throws {Error} if either of the arguments is not an object
 */
export const getObjectDifference = (original, changed) =>
  Object.keys(changed).reduce((res, prop) => {
    return JSON.stringify(original[prop]) === JSON.stringify(changed[prop])
      ? res // if they equal, do not add prop to result
      : // if they differ, add prop to result
        {
          ...res,
          [prop]: changed[prop],
        };
  }, {});

import React from "react";
import { TextField } from "@material-ui/core";
import {pipeline} from "@ses-education/content-parser";

const elementMap = {
  quiz: {
    fieldFields: [ 
      { field: "title", label: "Title"}, 
      { field: "desc", label: "Description"}
    ],
    itemsField: "questions",
    itemIdField: "id",
    itemType: "question",
    idField: "id" // must be passed from outside
  },
  question: {
    fieldFields: [{ field: "title", label: "Title"}, ],
    itemsField: "answers",
    itemIdField: "id",
    itemType: "answer",
    idField: "id"
  },
  
  answer: {
    fieldFields: [{ field: "text", label: "Answer text"}, ]   ,
    idField: "id"     
  },

  multiple: {
    fieldFields: [ 
      { field: "title", label: "Title"}, 
      { field: "desc", label: "Description"}
    ],
    itemsField: "options",
    itemIdField: "option",
    itemType: "option",
    idField: "id" // must be passed from outside
  },

  option: {
    fieldFields: [{ field: "text", label: "Answer text"}, ]   ,
    idField: "option"     
  },

  'text-field': {
    fieldFields: [{ field: "title", label: "Title"}, { field: "desc", label: "Description"}]   ,
    idField: "id"  // must be passed from outside
  }
}

class ElementEditor extends React.Component{

  state = {
    // rendering fields
    fields: null, 
    items: null,
    element: null,
    error: null,

    // template fields 
    fieldFields: null,
    itemsField: null,
    itemType: null,
    idField: null
  }

  componentDidMount(){
    this.propsToState();
  }

  propsToState(){
    const { element_type, type, index = 0, onChange, ...element } = this.props;

    const elementTemplate = elementMap[element_type]
    
    if( !elementTemplate ) {
      this.setState({error: "Unknown element type:", element_type})
      return;
    }

    // if element map contains fieldFields, populate 'fields' with it and add values from 'element'
    const fields = Array.isArray(elementTemplate.fieldFields) ? elementTemplate.fieldFields.map( f => ({...f, value: element[f.field] }) ) : null;

    // if element map contains itemsFields and there is such field within element, use it as 'items'
    const items = elementTemplate.itemsField && Array.isArray( element[elementTemplate.itemsField] ) ? [...element[elementTemplate.itemsField] ]  : null;

    const id = elementTemplate.idField && element[elementTemplate.idField] ? element[elementTemplate.idField] : null;

    const {itemType} = elementTemplate;

    console.debug("propsToState\n element:\n", { ...elementTemplate, fields, items, id, itemType, element} )

    this.setState({ ...elementTemplate, fields, items, id, itemType, element})
  }

  onFieldChange = (field, value) => {
    const {onChange = console.debug, showMessage = console.debug  } = this.props;

    let {element, fields, id } = this.state;

    const fieldIndex = Array.isArray(fields) ? fields.findIndex( f => f.field === field ) : null;
 
    if( fieldIndex < 0 ) {
      showMessage("question fields ["+ field + "] not found!", "error")
      return;
    }

    // update fields
    fields[fieldIndex].value = value

    element = {...element, [field]: value }
    
    this.setState({element, fields}, () => {
      // propagate update
      onChange( id, element );
    })
  }
  
  onItemChange = (itemId, value) => {
    const {onChange = console.debug , showMessage = console.debug } = this.props;

    let {element, items, itemIdField, id, itemsField } = this.state;    

    const itemIndex = items.findIndex( i => i[itemIdField] === itemId )

    if( itemIndex < 0 ) {
      console.debug( "onItemChange failed to find item with ", itemIdField, "=", itemId, "within:\n", items);
      showMessage("question item with id ["+ itemId + "] not found!", "error")
      return;
    }

    // update item in items
    items[itemIndex] = value;

    // update itemsField in element with updated items
    element = {...element, [itemsField]: items  }
    this.setState({element, items}, () => {
      // propagate update
      onChange( id, element  );
    })
    

  }

  render(){
    const { fields = [], items = [], id, itemType, error } = this.state;
    const {element_type, type, index = 0, onChange } = this.props;
    const isDisplay = type === "show"

    const elementKey = `${element_type}-${id}-${index}`
    
    if( error ) return <div className="error">{error}</div>

    // return <ElementRenderer {...props} onElementUpdate={this.onElementUpdate} />
    return <div className={`question-element ${element_type}`} key={elementKey} data-index={index + 1} >
      <div className="fields">
        {Array.isArray(fields) && fields.map( (f, fieldIndex) => <div className="question-element-field">
          <TextField
          key={`${elementKey}-field${fieldIndex}`}
            fullWidth
            multiple
            label={f.label}
            value={f.value}
            disabled={isDisplay}
            onChange={
              isDisplay
                ? console.debug
                : ({ target }) => this.onFieldChange( f.field, target.value)
            }
          />
          <div
              className="field-text-rendered"
              dangerouslySetInnerHTML={{ __html: pipeline( typeof f.value === "string" ? f.value : "" ) }}
            ></div>
        </div>  )}
      </div>
      { Array.isArray(items) && (
          <div className="items">
            { items.map( (i,itemIndex) => <ElementEditor {...{...i, index: itemIndex, element_type: itemType, type }} onChange={this.onItemChange } /> )}
          </div>
        )
      }
    </div>
  }
}


export default ElementEditor;
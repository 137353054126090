import { Grid, Input, InputAdornment, List, ListItem, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import React from 'react';





class ProgramFormCourses extends React.Component{

    state = {
        course_ids: [],
        not_selected: [],
        all_courses: {},
        all_courses_search: "",
        courses_search: ""
    }

    componentDidMount(){
        this.populateForm();
    }

    componentDidUpdate( prevProps ){
        // only repopulate if program id has changed
        if( prevProps?.program.program_id !== this.props?.program.program_id ){
            this.populateForm();
        }
    }

    changeValue = ({target}) => {
        const {name, value} = target;
        console.debug(name, value);
    }

    populateForm() {
        let { program_id, course_ids = []} = this.props?.program || {};
        const {allCourses} = this.props;
        console.debug('props', this.props);
        
        // hash courses by id and store in state
        const all_courses = allCourses.reduce( (res, c) => ({...res, [c.course_id]: c }), {});

        // fetch all ids not included in current program
        const not_selected = allCourses
            .filter( c => c &&  !course_ids.includes(c.course_id) )
            .map( c => c.course_id)
            
        course_ids = course_ids.filter( c => c) // remove empty records if any

        this.setState({ program_id, course_ids, all_courses, not_selected});
    }

    /**
     * 
     * @param {int} course_id 
     * @param {["course_ids", "not_selected"]} from_name string, field name in state, indicates where to move the course from
     * @param {["course_ids", "not_selected"]} to_name string, field name in state, indicates where to move the course to
     */
    moveTo = (course_id, from_name, to_name) => {
        const {all_courses} = this.state; 
        const {onChange} = this.props;

        this.setState({
            // remove from the 'from' list and sort by source code
            [from_name]: this.state[from_name]
                .filter( cid => cid && cid !== course_id )
                .sort( (a,b) => all_courses[a]?.course_code?.localeCompare(all_courses[b]?.course_code)),

            // add to 'to' list and sort by course code
            [to_name]: [...this.state[to_name], course_id]
            .sort( (a,b) => all_courses[a]?.course_code?.localeCompare(all_courses[b]?.course_code))
        }, () => {

            if( typeof onChange === "function"){
                onChange( {course_ids: this.state.course_ids});
            }
        })


        


    }

    updateSearch = ({target}) => {
        const {name, value} = target;
        this.setState({
            ...this.state,
            [name]: value
        })
    }

    render(){
        let { course_ids, not_selected, all_courses, all_courses_search, courses_search} = this.state;
        
        // bring search terms to lower case
        all_courses_search = all_courses_search.toLowerCase();
        courses_search = courses_search.toLowerCase();

        return (

            <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                    <h3 className='align-center m-top-0'>Unused courses</h3>
                    <Input 
                    startAdornment={
                        <InputAdornment position='start'>
                            <Search/>
                        </InputAdornment>

                    }
                    fullWidth className='search-box m-bottom-10' name="all_courses_search" 
                    value={all_courses_search} onChange={this.updateSearch}/>
                    <List 
                    className='course-select'>
                        { not_selected
                            .filter( id => !id 
                                || all_courses[id].course_code.toLowerCase().includes(all_courses_search) 
                                || all_courses[id].title.toLowerCase().includes(all_courses_search))
                            .map( 
                            cid => <ListItem
                            onClick={()=> this.moveTo(
                                cid, "not_selected", "course_ids"
                            )}
                            >{all_courses[cid].course_code}: {all_courses[cid].title}</ListItem>
                            )}
                    </List>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <h3 className='align-center m-top-0'>Courses in program ({course_ids.length})</h3>
                    <Input 
                    startAdornment={
                        <InputAdornment position='start'>
                            <Search/>
                        </InputAdornment>

                    }
                    fullWidth className='search-box m-bottom-10' name="courses_search" 
                    value={courses_search} onChange={this.updateSearch}/>
                    <List 
                    className='course-select'>
                        { course_ids
                            .filter( id => !id 
                                || all_courses[id].course_code.toLowerCase().includes(courses_search) 
                                || all_courses[id].title.toLowerCase().includes(courses_search))
                            .map( 
                            cid => <ListItem 
                            onClick={()=> this.moveTo(
                                cid, "course_ids", "not_selected"
                            )}
                            >{all_courses[cid]?.course_code}: {all_courses[cid]?.title}</ListItem>
                        )}
                    </List>
                </Grid>
            </Grid>
        )
    }
}

export default ProgramFormCourses;
import React from "react";
import "./App.scss";
import Main from "./components/main";

import Notification from "./components/template/Notification";
import "@ses-education/courses-components/dist/main.css";
import "@ses-education/content-editor/dist/main.css";

function App() {
  return (
    <>
      <Main />
      <div className="messaging">
        <Notification />
      </div>
    </>
  );
}

export default App;

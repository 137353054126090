import baseClientService from "./baseClientService";



class TranslationsService extends baseClientService{


  static async getLanguages(){
    try{
      // console.debug("getLanguages called")
      return await this.getCachedOr( "languageList" , async ( index ) => {  
        const languages = await this.GET("/admin/translations/languages")
        // console.debug("getLanguages received value:", languages)
        this.cache( index , languages )
        return languages;
      })
    } 
    catch(e){
      return this.Error(e.message);
    }
  }
  static async getInterfaceTranslations(language_id){
    try{
        const languages = await this.GET(`/admin/translations/interface/${language_id}`)
        return languages;
    } 
    catch (e) {
      console.error('Error fetching Interface Translations: ', e.message);
      this.Error(e.message);
      return false;
    }
  }
  static async updateInterfaceTranslation(translation_id, language_id, content){
    try{
        const languages = await this.POST(`/admin/translations/interface/${language_id}`,{translation_id,content})
        return languages;
    } 
    catch (e) {
      console.error('Error fetching Interface Translations: ', e.message);
      this.Error(e.message);
      return false;
    }
  }
  static async changeType(type, ids){
    try{
      const result = await this.POST(`/admin/translations/change-type/${type}`,{ids})
      return result;
  } 
  catch (e) {
    console.error('Error changing translation type: ', e.message);
    this.Error(e.message);
    return false;
  }
  }
  static async verifyInterfaceTranslation( ids, language_id, verified){
    try{
        const languages = await this.POST(`/admin/translations/interface/${language_id}/verify`,{ids,verified})
        return languages;
    } 
    catch (e) {
      console.error('Error fetching Interface Translations: ', e.message);
      this.Error(e.message);
      return false;
    }
  }
  static async deleteQuestionTranslation(){
    console.log("deleteQuestionTranslation called");
    return true;
  }

  static async deleteTranslation( translation_id, language_id){
    await this.POST(`/admin/translations/interface/${language_id}/delete`,{translation_id})
    return true;
  }
}

baseClientService.extend(TranslationsService)

export default TranslationsService;
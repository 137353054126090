import Base from "./baseClientService";

const cacheIndexUserList = "users_list";

class UserService extends Base {
  static async getStatusList() {
    try {
      const statuses = await this.GET(`/admin/users/statuses`);

      return statuses;
    } catch (e) {
      console.error("Error fetching users statuses: ", e.message);
      this.Error(e.message);
      return false;
    }
  }

  static async getUsers() {
    try {
      const users = await this.GET(`/admin/users`);

      return users;
    } catch (e) {
      console.error("Error fetching users: ", e.message);
      this.Error(e.message);
      return false;
    }
  }

  static async getCredentialsList() {
    try {
      return await this.GET(`/admin/users/available-credentials`);
    } catch (err) {
      console.error("Error fetching credentials list: ", err.message);
      return this.Error(err.message);
    }
    // try {
    //   //const users = await this.GET(`/admin/organizations/${id}/users`);
    //   const tempArr = [
    //     {
    //       credential_id: 'admin',
    //       title: 'Admin',
    //       description: 'All privileges',
    //     },
    //     {
    //       credential_id: 'organization',
    //       title: 'Organization',
    //       description:
    //         'See all courses, buy license for a course for a fixed number of students',
    //     },
    //     {
    //       credential_id: 'student',
    //       title: 'Student',
    //       description:
    //         'See all published courses, see own courses, see own progress, participate in courses, buy courses',
    //     },
    //     {
    //       credential_id: 'tester',
    //       title: 'Test operator',
    //       description: 'Can test existing courses using special features',
    //     },
    //   ];
    //   return tempArr;
    // } catch (e) {
    //   console.error('Error fetching credentials of users: ', e.message);
    //   this.Error(e.message);
    //   return false;
    // }
  }

  static async updateUser(data, id) {
    // when editing existing user
    if (id) {
      data = { ...data, id };
    }

    try {
      const result = await this.POST(`/admin/users/update`, data);
      return result;
    } catch (err) {
      console.error("Error updating user: ", err.message);
      return this.Error(err.message);
    }
  }

  static async deleteUser(id) {
    try {
      const result = await this.POST(`/admin/users/${id}/delete`);
      if (result) {
        return true;
      }
    } catch (err) {
      console.error("Error deleting user: ", err.message);
      this.Error(err.message);
      return false;
    }
  }

  static async addCredentials(user_id, credential) {
    try {
      const result = await this.POST(
        `/admin/users/${user_id}/add-credentials/${credential}`
      );
      if (result) {
        return true;
      }
    } catch (err) {
      console.error("Error adding credential: ", err.message);
      this.Error(err.message);
      return false;
    }
  }

  static async updateUserImage(id, formData) {
    try {
      await this.POST(`/admin/users/${parseInt(id)}/image`, formData);
      return true;
    } catch (e) {
      console.error("Error updating user image:", e.message);
      return this.Error(e.message);
    }
  }

  static async deleteUserImage(id) {
    try {
      await this.POST(`/admin/users/${parseInt(id)}/image/delete`);
      return true;
    } catch (e) {
      console.error("Error deleting user image:", e.message);
      return this.Error(e.message);
    }
  }

  static async deleteCredentials(user_id, credential) {
    try {
      const result = await this.POST(
        `/admin/users/${user_id}/del-credentials/${credential}`
      );
      if (result) {
        return true;
      }
    } catch (err) {
      console.error("Error deleting credential: ", err.message);
      this.Error(err.message);
      return false;
    }
  }

  static async updatePassword(data, id) {
    try {
      await this.POST(`/admin/users/${id}/password`, data);
      return true;
    } catch (err) {
      console.error("Error updating password: ", err.message);
      return this.Error(err.message);
    }
  }
}
Base.extend(UserService);

export default UserService;

import { Container } from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";
import React from "react";
import Error from "../../../template/Error";
import TranslationsCoursesItem from "./translations-courses-item";
import CourseService from "../../../../services/course";
import TranslationsCoursesItemExperiments from "./translations-courses-item-experiments";
// import TranslationsCoursesItemExperiment from "./translations-courses-item-experiment";
import { Home, Translate } from '@material-ui/icons';
import {AutoBreadcrumbs, AdminTable, ModalWindow} from "@ses-education/courses-components";
import Spinner from "../../../template/spinner";

class TranslationsCourses extends React.Component {
  state = {
    courses: null,
    redirect: null,
    currentCourse: null,
    language_id: null
  };

  componentDidMount() {
    this.fetchCourses();
  }

  setLanguage = (language_id) => this.setState({language_id});

  componentDidUpdate(prevProps) {
    const { course_id, section } = this.props;

    console.debug(
      "Courses course_id",
      this.props.course_id,
      "prev course_id:",
      prevProps.course_id
    );
    // reset redirect if used
    if (prevProps.course_id !== course_id || prevProps.section !== section) {
      console.debug("Courses resetting redirect");
      this.setState({ redirect: null }, () =>
        console.debug("Courses redirect is reset")
      );
      if(!this.state.currentCourse) {
        this.fetchCourses();
      }
    }

    // if( !Array.isArray(this.state.courses) ) this.fetchCourses();
  }

  async fetchCourses() {

    const courses = await CourseService.getCourseListPage(1, 1000);
    const error = Array.isArray(courses)
      ? null
      : CourseService.error || "Unknown programs error";

    const currentCourse = error ? null : courses.find (c => c.course_id === parseInt(this.props.course_id) )

    this.setState({ courses, error, currentCourse });
  }

  render() {
    const { error, courses, currentCourse, redirect, language_id } = this.state;
    const { course_id, section, experiment_id, page, question_id } = this.props;
    let breadcrumbs = [
      { icon: <Home/>, to: "/"},
      { icon: <Translate/>, to: "/translations",  text: "Translations"}, 
      { to: "/translations/courses",  text: "Courses"}           
  ]
    const columns = [
      { field: "id", flex: 0, headerName: "ID" },
      { field: "course_code", flex: 0, headerName: "Code" },
      {
        field: "title",
        flex: 1,
        headerName: "Title",
        renderCell: (params) => (
          <Link to={`/translations/courses/${params.row.course_id}`}>
            {params.row.title}
          </Link>
        ),
      },
      {
        field: "translations",
        flex: 1,
        headerName: "Translations",
        renderCell: (row) => <>{row.value.join(", ")}</>,
      },
    ];

    console.debug("Courses render: id", course_id, "section", section, "page", page );
    if (redirect) {
      console.debug("Courses redirecting to", redirect);
      return <Redirect push to={redirect} />;
    }

    if (error) return <Error text={error} />;
    console.debug("Courses rendering courses");
    // console.debug("Courses current course\n", currentCourse);

    return (
      <>
      <AutoBreadcrumbs 
      items={breadcrumbs}
      />
      <Container disableGutters className="courses">
        <h1>Courses</h1>
        { Array.isArray(courses)  ? (
            <AdminTable
              autoHeight
              pageSize={10}
              rows={
                // Array.isArray(courses)
                  // ? 
                  courses.map((c) => ({ ...c, id: c.course_id }))
                  // : []
              }
              searchFields={
                ["title","course_code"]
              }
              {...{
                columns,
              }}
            />
          ) : <Spinner />
        }
        { course_id && <ModalWindow 
          open={true}
          formClassName="full-screen"
          >
            {course_id && !section &&  (
              <TranslationsCoursesItem
                {...{ course_id, language_id }}
                onClose={() => this.setState({ redirect: "/translations/courses" })}
                onLanguageSelect={this.setLanguage}
              />
            )}
            {currentCourse && section === "experiments" &&  (
              <TranslationsCoursesItemExperiments
                {...{ course_id, currentCourse, experiment_id, page, language_id }}
                onClose={() => this.setState({ redirect: `/translations/courses/${course_id}` })}
                onLanguageSelect={this.setLanguage}
              />
            )}
          </ModalWindow>
        }
      </Container>
      </>
    );
  }
}

export default TranslationsCourses;

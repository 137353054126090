import { Button, Grid, TextField, Typography } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { showMessage } from "@ses-education/courses-components";
import CourseService from "../../../../services/course";
import Service from "../../../../services/course";
import {ImageSelector} from "@ses-education/courses-components";
import "./form.scss";

const defaultState = {
  course_id: null,
  original: {
    course_code: null,
    title: null,
    image: null,
    description: null,
    active: true,
  },
  course: {
    course_code: null,
    title: null,
    image: null,
    description: null,
    active: true,
  },
  changes: {},
};

const defaultImage = `${process.env.PUBLIC_URL}/static/images/course-no-image.png`;

class CourseForm extends React.Component {
  state = { ...defaultState };

  componentDidMount() {
    this.populateFromProps();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
      console.debug("course data updated");
      this.populateFromProps();
    } else {
      console.debug("course data NOT updated");
    }
  }

  populateFromProps() {
    const {
      course_id,
      title,
      description,
      image_url: image,
      active,
      course_code,
    } = this.props;

    this.setState({
      course_id,
      original: {
        course_code,
        title,
        image,
        description,
        active,
      },
      course: {
        course_code,
        title,
        image,
        description,
        active,
      },
      changes: {},
    });
  }

  save = async (ev) => {
    ev.preventDefault();
    let { course_id, changes } = this.state;
    if (Object.keys(changes).length > 0) {
      changes = { ...changes, course_id };
      const result = await Service.updateCourse(changes);
      if (result) {
        // let { course, original } = this.state;
        // original = { ...course };
        // this.setState({ course, original, changes: {} });
        this.props.onUpdate();
      } else {
        this.props.onShowMessage("Error: " + Service.error, "error");
      }
    } else {
      console.log("No changes were made");
    }
  };

  /**
   * removes latest changes
   */
  cancel = () => {
    let { course, original } = this.state;
    course = { ...original };
    this.setState({ course, original, changes: {} });
  };

  updateValue = (ev, val) => {
    // console.log( ev, val)
    const { currentTarget } = ev;
    const { name, value } = currentTarget;
    const { course, original, changes } = this.state;

    // update changes
    if (value !== original[name]) {
      changes[name] = value;
    } else {
      if (original.hasOwnProperty(name)) delete changes[name];
    }

    // update course
    course[name] = value;

    this.setState({ course, changes });
  };

  onSelectImage = async ({ target }) => {
    const { onShowMessage, course_id } = this.props;

    if (!course_id) {
      onShowMessage("Course not saved", "error");
      return false;
    }

    const image = target.files && target.files[0] ? target.files[0] : null;

    if (!image) {
      onShowMessage("No file selected", "error");
      return false;
    }

    // build form data with file
    const data = new FormData();
    data.append("image", image);

    // console.debug("uploading data", data);
    if (!(await CourseService.uploadImage(course_id, data))) {
      onShowMessage(CourseService.error || "Unknown error", "error");
      return false;
    }

    onShowMessage("Image successfully updated");
    this.props.onUpdate();
    return true;
  };

  onDeleteImage = async () => {
    const { onShowMessage, course_id } = this.props;

    if (!course_id) {
      onShowMessage("Course not saved", "error");
      return false;
    }
    // console.debug("uploading data", data);
    if (!(await CourseService.deleteImage(course_id))) {
      onShowMessage(CourseService.error || "Unknown error", "error");
      return false;
    }
    onShowMessage("Image successfully deleted");
    this.props.onUpdate();
    return true;
  };

  render() {
    const { changes, course_id } = this.state;
    let { title, description, image, active, course_code } = this.state.course;

    // console.debug("Form image url:", image);
    title = title || "";
    description = description || "";
    course_code = course_code || "";

    const isChanged = Object.keys(changes).length > 0;
    return (
      <Grid container spacing={4} className="course-form">
        <Grid item xs={12} md={4} className="image">          
          {course_id ? (
            <ImageSelector
              {...{ image, defaultImage }}
              onSelect={this.onSelectImage}
              onDelete={this.onDeleteImage}
              confirmationText="Delete course image?"
            />
          ) : (
            <Typography>Save the course to select image</Typography>
          )}
          <Typography align="center">Course image (click to change)</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <form onSubmit={this.save}>
            <TextField
              required
              label="Course code"
              placeholder="type course code here"
              value={course_code}
              fullWidth
              name="course_code"
              onChange={this.updateValue}
            />
            <TextField
              required
              label="Title"
              placeholder="type course title here"
              value={title}
              fullWidth
              name="title"
              onChange={this.updateValue}
            />
            <TextField
              multiline
              label="Description"
              placeholder="Description is shown in list of courses to briefly describe each course"
              rows={5}
              value={description}
              fullWidth
              name="description"
              onChange={this.updateValue}
            />
            <Button type="submit" disabled={!isChanged}>
              Save
            </Button>
            <Button type="reset" disabled={!isChanged} onClick={this.cancel}>
              Cancel
            </Button>
          </form>
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onShowMessage: (message, type) => dispatch(showMessage(message, type)),
  };
};

export default connect(null, mapDispatchToProps)(CourseForm);

import { MenuItem, Select, Typography } from "@material-ui/core";
import React from "react";
import {AdminTable} from "@ses-education/courses-components";

class Configuration extends React.Component {
  state = {
    template: null,
    templates: [],
    stateConf: [],
    nestKey: [],
  };
  componentDidMount() {
    // feaching templates from props
    // const templates = this.props.course_type?.categories?.templates || [];
    const { currentCategory } = this.props;
    // const templates = this.props.course_type?.categories?.templates || [];
    const { templates } = currentCategory?.course_type || { templates: [] };
    this.setState({ templates, template: templates[0] || null });
  }
  render() {
    const { templates, template, stateConf, nestKey } = this.state;
    const state_columns = [
      { field: "title", headerName: "Title" },
      { field: "conditions", headerName: "Conditions" },
    ];
    const keys_columns = [
      { field: "key", headerName: "Key" },
      { field: "title", headerName: "Title" },
    ];
    return (
      <div className="modal-data">
        <Select
          label="Template"
          onChange={(e) => this.setState({ template: e.target.value })}
          value={template}
        >
          {Array.isArray(templates) &&
            templates.map((template) => {
              return <MenuItem value={template}>{template}</MenuItem>;
            })}
        </Select>
        <Typography>State configuration</Typography>
        <AdminTable
          autoHeight
          rows={stateConf}
          columns={state_columns}
          pageSize={10}
        />
        <Typography>Nested keys</Typography>
        <AdminTable
          autoHeight
          rows={nestKey}
          columns={keys_columns}
          pageSize={10}
        />
      </div>
    );
  }
}

export default Configuration;

import {
  MenuItem,
  Select,
  Typography,
  ButtonGroup,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Tooltip,
} from "@material-ui/core";
import React from "react";

import TranslationsService from "../../../services/translations-service";
import { Cancel, Check, Edit, Home, Remove, Translate } from "@material-ui/icons";
import TranslationSelect from "../../template/translation-select";
import {AdminTable, ConfirmDialog, Spinner, AutoBreadcrumbs} from "@ses-education/courses-components";

import { Redirect } from "react-router-dom";
import TranslationsInterfaceEditHtml from "./translations-interface-edit-html";
import { connect } from "react-redux";
import HelpText from "../../template/HelpText";

const filterFunctions = {
  verified: (value) => (row) => {
    return row.verified === value;
  },
  type: (value) => (row) => {
    return row.type === value;
  },
};

class TranslationsInterface extends React.Component {
  state = {
    translations: null,
    interface_translations: null,
    filtered_interface_translations: null,
    filters: {
      verified: 0,
      selectedOnly: {
        items: [],
      },
      type: -1,
    },
    showConfirmDialog: false,
    showChangeDialog: false,
    ConfirmText: "",
    onConfirmDialog: null,
    redirect: null,
    translation: null,
    type:"phrase",
  };
  componentDidMount() {
    this.fetchTranslations();
    if (this.props.language_id) {
      this.fetchInterfaceTranslations();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      (prevProps.language_id !== this.props.language_id ||
      prevState.redirect !== this.state.redirect) && this.props.language_id
    ) {
      this.fetchInterfaceTranslations();
      this.setState(
        {
          filters: {
            verified: prevState.filters.verified,
            selectedOnly: {
              items: [],
            },
            type: prevState.filters.type,
          },
          redirect: null,
        },
      );
    }
    if (!this.state.translations) {
      this.fetchTranslations();
    }
  }
  fetchTranslations = async () => {
    const { onShowMessage } = this.props;
    let translations = await TranslationsService.getLanguages();

    if (!translations) {
      onShowMessage(
        TranslationsService.error || "Unknown translation error",
        "error"
      );
      return;
    }
    translations = translations.map((t) => {
      return {
        ...t,
        language_id: t["iso639-1"],
      };
    });
    this.setState({ translations });
  };
  fetchInterfaceTranslations = async () => {
    const { onShowMessage } = this.props;
    const { language_id, translation_id } = this.props;
    if (!language_id) {
      onShowMessage("Language not selected", "error");
      return;
    }
    let interface_translations =
      await TranslationsService.getInterfaceTranslations(language_id);
    if (!interface_translations) {
      onShowMessage(
        TranslationsService.error || "Unknown translation error",
        "error"
      );
      return;
    }
    interface_translations = interface_translations.map((t) => {
      return {
        ...t,
        id: t.translation_id,
      };
    });
    const translation = interface_translations.find(it => it.translation_id === translation_id);
    this.setState(
      {
        translation,
        interface_translations,
        filtered_interface_translations: interface_translations,
      },
      this.filterEverything
    );
  };
  selectLanguage = (language) => {
    console.log("select language: ", language);
    this.setState({ redirect: `/translations/interface/${language}` });
  };
  selectTranslation = (translation_id) => {
    const { language_id } = this.props;
    console.log("select translation: ", translation_id);
    this.setState({ redirect: `/translations/interface/${language_id}/${translation_id}` });
  }
  deleteTranslation = async (translation_id) => {
    const {language_id, onShowMessage} = this.props;
    console.debug("delete translation", translation_id, language_id);
    try{
      await TranslationsService.deleteTranslation(translation_id, language_id);
      // reset confirm dialog
      this.setState({
        confirmHeader: null,
        ConfirmText: null,
        showConfirmDialog: null,
        onConfirmDialog: null
      })
      this.fetchInterfaceTranslations();
      onShowMessage("Translation deleted", "success");

    }catch(e){
      console.debug("Error while deleting", translation_id, "/", language_id, e);
      onShowMessage( e.message || "Unknown error", "error");
    }
  }
  onSelect = (model) => {
    let filters = { ...this.state.filters };
    filters.selectedOnly.items = model;
    //console.log("onSelect", model, filters);
    this.setState({ filters });
  };
  onSelect2 = (event, type) => {
    let { filters } = this.state;
    filters = { ...filters, [type]: event.target.value };
    this.setState({ filters }, this.filterEverything);
  };
  filterEverything = () => {
    console.log("filterEverything", this.state);
    const { filters } = this.state;
    const { interface_translations } = this.state;
    let filtered_interface_translations = [...(interface_translations || [])];
    Object.entries(filters).map((entry) => {
      const [key, value] = entry;
      console.log("filterEverything", filterFunctions[key]);
      if (filterFunctions[key] && value !== -1) {
        filtered_interface_translations =
          filtered_interface_translations.filter(filterFunctions[key](value));
      }
    });
    this.setState({ filtered_interface_translations });
  };
  EditContent = async (row) => {
    console.log("EditContent", row);
    const { onShowMessage } = this.props;
    const { language_id } = this.props;
    if (!language_id) {
      onShowMessage("Language not selected", "error");
      return;
    }
    const result = await TranslationsService.updateInterfaceTranslation(
      row.id,
      language_id,
      row.value
    );
    if (!result) {
      onShowMessage(
        TranslationsService.error || "Unknown translation error",
        "error"
      );
      return;
    }
    onShowMessage("succes in update value", "info");
  };
  verify = async (verification) => {
    //console.log("EditContent", row);
    const { onShowMessage } = this.props;
    const { language_id } = this.props;
    let { filters } = this.state;
    if (!language_id) {
      onShowMessage("Language not selected", "error");
      return;
    }
    const result = await TranslationsService.verifyInterfaceTranslation(
      filters.selectedOnly.items,
      language_id,
      verification
    );
    if (!result) {
      onShowMessage(
        TranslationsService.error || "Unknown translation error",
        "error"
      );
      return;
    }
    onShowMessage(
      `succes in ${
        verification === 1 ? "Verified" : "Unverified"
      } all selected translations`,
      "info"
    );
    filters = {
      ...filters,
      selectedOnly: {
        ...filters.selectedOnly,
        items: [],
      },
    };
    this.setState({ filters });
    this.closeConfirmDialog();
    this.selectLanguage(language_id);
  };
  changeType = async (type) => {
    const { onShowMessage } = this.props;
    const { language_id } = this.props;
    let { filters } = this.state;
    if (!language_id) {
      onShowMessage("Language not selected", "error");
      return;
    }
    const result = await TranslationsService.changeType(
      type,
      filters.selectedOnly.items,
    );
    if (!result) {
      onShowMessage(
        TranslationsService.error || "Unknown translation error",
        "error"
      );
      return;
    }
    onShowMessage(
      `succes in changing all translation types to ${type}`,
      "info"
    );
    filters = {
      ...filters,
      selectedOnly: {
        ...filters.selectedOnly,
        items: [],
      },
    };
    this.setState({ filters, showChangeDialog: false, type:"phrase" });
    this.selectLanguage(language_id);
  }
  closeConfirmDialog = () => {
    this.setState({
      showConfirmDialog: false,
      ConfirmText: "",
      onConfirmDialog: null,
    });
  };
  render() {
    let breadcrumbs = [
      { icon: <Home />, to: "/" },
      { icon: <Translate />, to: "/translations", text: "Translations" },
      { text: "Interface", to: "/translations/interface" },
    ];
    const { language_id, translation_id, user } = this.props;
    const {
      translations,
      filtered_interface_translations,
      filters,
      showConfirmDialog,
      showChangeDialog,
      ConfirmText,
      confirmHeader = "Confirm verification",
      onConfirmDialog,
      translation,
      type,
    } = this.state;
    const { redirect } = this.state;
    if(language_id){
      breadcrumbs = [...breadcrumbs,{text: language_id, to: `/translations/interface/${language_id}` }];
      if(translation_id){
        breadcrumbs = [...breadcrumbs,{text: `Edit ${translation && translation.type}`, to: `/translations/interface/${language_id}/${translation_id}` }];
      }
    }
    if (redirect) {
      return <Redirect to={redirect} />;
    }
    //console.log("translation interface: ",translations, language_id,filtered_interface_translations);
    const columns = [
      { field: "translation_id", headerName: "translation ID", flex: 1 },
      { field: "type", headerName: "Type", flex: 1 },
      { 
        field: "original", 
        headerName: "Original", 
        flex: 1,
        renderCell: (params) => {
          return (
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                interactive
                arrow
                //classes={{ popper: 'help-tip' }}
                title={
                  <HelpText html={params.value}/>
                }
              >
                <span>{(params.value || "").split(/[\s>]/).slice(0, 5).join(" ")}</span>
              </Tooltip>
          )
        } 
      },
      {
        field: "content",
        headerName: "Translation",
        flex: 1,
        editable: true,
        renderCell: (params) => {
          if(params.row.type === "document" || params.row.type === "html"){
            return (
              <ButtonGroup>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<Edit />}
                  onClick= {()=>this.selectTranslation(params.row.translation_id)}
                >
                  Edit
                </Button>
              </ButtonGroup>
            );
          }
          if (params.row.type === "phrase") {
            return (
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                interactive
                arrow
                //classes={{ popper: 'help-tip' }}
                title={
                  <HelpText html={params.value}/>
                }
              >
                <span>{(params.value || "").split(/[\s>]/).slice(0, 5).join(" ")}</span>
              </Tooltip>
            )
          }
        },
      },
      {
        field: "verified",
        headerName: "verified",
        flex: 1,
        renderCell: (param) => {
          switch (param.value) {
            case 0:
              return <><Remove style={{ color: "red" }} />
              {param.row.content !== null && 
                <Button onClick={
                    ()=>{
                      this.setState({
                        confirmHeader: "Deleting translation",
                        ConfirmText: "Are you sure you want to delete the translation for '" + param.row.id + "'?",
                        onConfirmDialog: () => this.deleteTranslation(param.row.id),
                        showConfirmDialog: true
                      })
                    }
                  }
                  >
                    <Cancel htmlColor="red"/>
                  </Button>
              }</>;
            case 1:
              return <Check style={{ color: "green" }} />;
            default:
              return "???";
          }
        },
        // valueFormatter: (params) =>{
        //     switch(params.value){
        //         case 0:return 'Unverified';
        //         case 1:return 'Verified';
        //         default: return 'Unknown';
        //     }
        // },
      },
    ];
    const buttons = [
      <ButtonGroup>
        {user && Array.isArray(user.credentials) && user.credentials.includes("admin") && (
          <Button
            className="bg-green color-white"
            onClick={() =>
              this.setState({
                showChangeDialog: true,
              })
            }
            disabled={!filters.selectedOnly.items.length}
          >
            Change type
          </Button>
        )}
        <Button
          className="bg-green color-white"
          onClick={() =>
            this.setState({
              showConfirmDialog: true,
              ConfirmText:
              "Are you sure you want to verify all selected translations?",
              onConfirmDialog: () => this.verify(1),
            })
          }
          disabled={!filters.selectedOnly.items.length}
        >
          Verify
        </Button>
        <Button
          className="bg-red color-white"
          onClick={() =>
            this.setState({
              showConfirmDialog: true,
              ConfirmText:
                "Are you sure you want to reset verification to all selected translations?",
              onConfirmDialog: () => this.verify(0),
            })
          }
          disabled={!filters.selectedOnly.items.length}
        >
          Reset verification
        </Button>
      </ButtonGroup>,
      <Select
        className="verified-select"
        // native
        value={filters["verified"]}
        onChange={(event) => this.onSelect2(event, "verified")}
      >
        <MenuItem value={-1}>All</MenuItem>
        <MenuItem value={0}>Unverified</MenuItem>
        <MenuItem value={1}>Verified</MenuItem>
      </Select>,
      <Select
        className="verified-select"
        // native
        value={filters["type"]}
        onChange={(event) => this.onSelect2(event, "type")}
      >
        <MenuItem value={-1}>All</MenuItem>
        <MenuItem value={"phrase"}>phrase</MenuItem>
        <MenuItem value={"html"}>html</MenuItem>
        <MenuItem value={"document"}>document</MenuItem>
      </Select>
    ];
    console.debug(
      "filtered_interface_translations",
      filtered_interface_translations
    );
    return (
      <>
        <AutoBreadcrumbs items={breadcrumbs} />
        {!language_id && translations && (
          <div className="centered">
            <Typography variant="h1" className="Translation-header">
              Please, select translation language
            </Typography>
            <TranslationSelect
              translations={translations}
              onSelect={this.selectLanguage}
              hideIcon
            />
          </div>
        )}
        {language_id && translations && !translation_id && (
          <>
            <div className="right-corner">
              <TranslationSelect
                translations={translations}
                translation={language_id}
                onSelect={this.selectLanguage}
              />
            </div>
            {filtered_interface_translations && (
              <>
                <AdminTable
                  autoHeight
                  checkboxSelection
                  disableSelectionOnClick
                  buttons={buttons}
                  rows={filtered_interface_translations}
                  columns={columns}
                  searchFields={["translation_id", "original", "content"]}
                  pageSize={10}
                  isCellEditable={(params) => params.row.type === "phrase"}
                  onSelectionModelChange={(model) => {
                    return this.onSelect(model);
                  }}
                  onCellEditCommit={(params) => {
                    return this.EditContent(params);
                  }}
                />
                <ConfirmDialog
                  open={showConfirmDialog}
                  header={confirmHeader}
                  prompt={ConfirmText}
                  onConfirm={onConfirmDialog}
                  onClose={this.closeConfirmDialog}
                />
                <Dialog open={showChangeDialog}>
                  <DialogContent>
                    <Select
                      value={type}
                      onChange={(event) => this.setState({ type: event.target.value })}
                    >
                      {["phrase","html","document"].map(u=>(
                        <MenuItem value={u}>{u}</MenuItem>
                      ))}
                    </Select>
                  </DialogContent>
                  <DialogActions className="flex row justify-content-space-between">
                    <Button variant="contained" color="primary" onClick={() => this.changeType(type)}> Change type</Button>
                    <Button variant="contained" color="secondary" onClick={()=> this.setState({showChangeDialog: false, type:"phrase"})}>Cancel</Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
            {!filtered_interface_translations && <Spinner />}
          </>
        )}
        {!translations && <Spinner />}
        {language_id && translation_id && (
            <TranslationsInterfaceEditHtml 
              {...{translation}}
              updateInteraceTranslation={this.EditContent} 
            />
        )}
      </>
    );
  }
}
const stateToProps = (state) => ({
  user: state.user,
});

export default connect(stateToProps)(TranslationsInterface);

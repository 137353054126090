import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  IconButton,
  List,
  ListItem,
  // ListItemText,
  Menu,
  MenuItem,
} from "@material-ui/core";
import React from "react";
import { NavLink } from "react-router-dom";
import Spinner from "../../../template/spinner";
import { defaultCoursesConfig } from "../../../../data/tinymce-configs";
import TinyMceEditor from "@ses-education/content-editor";
import { Alert } from "@material-ui/lab";
import ModalContent from "./modalContent";
import { Add, Delete, Edit, MoreVert } from "@material-ui/icons";

const tinymceKey = process.env.REACT_APP_TINYMCE;

const defaultContentElement = {
  element_id: null,
  element_title: "",
  html: "",
};

const Editor = ({ init = {}, ...props }) => {
  return (
    <TinyMceEditor init={{ ...defaultCoursesConfig, ...init }} {...props} />
  );
};

class ContentCategoriesPages extends React.Component {
  state = {
    pages: null,
    currentPage: null,
    currentPageIndex: null,
    editElement: null, // this is the element to edit with tinymce
    contentElementInForm: null, // this is the element data to edit within content element modal form
    elementMenuAnchor: null, // menu anchor for content element flyout menu
    elementMenuData: null, // content element data for the flyout menu. Will be used to edit/delete the element

    listenToClickAwayOnEditor: true, // if false, the listener is stopped. We need it to turn the listener off while the confirm dialog is displayed.
  };

  componentDidMount() {
    this.selectPage();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.page_id !== this.props.page_id ||
      prevProps.currentCategory !== this.props.currentCategory
    )
      this.selectPage();
  }

  /**
   * Finds currently selected page and stores it as currentPage in state, stores currentPageIndex in state as well
   * @returns
   */
  selectPage = () => {
    // get page_id from url and currentCategory from state
    const { page_id, currentCategory } = this.props;
    let currentPage = null;

    if (!currentCategory || !Array.isArray(currentCategory.pages)) {
      return this.setState({
        currentPage,
        pages: null,
        currentPageIndex: null,
      });
    }

    // extract pages array
    let { pages } = currentCategory;

    console.debug("selectPage pages from category: \n", pages);

    // sort the array by ordering
    pages.sort((a, b) => a.ordering - b.ordering);
    console.debug("selectPage pages after sort: \n", pages);

    // find the current page index
    const currentPageIndex = pages.findIndex(
      (p) => p.page_id === parseInt(page_id)
    );

    // if no page_id, set page to null
    if (!page_id) {
      console.debug(
        "selectPage no page_id, category or pages is not an array:",
        page_id,
        currentCategory,
        currentCategory.pages
      );
      return this.setState({
        currentPage,
        pages,
        currentPageIndex: null,
      });
    }

    // get the current page and clone it
    currentPage =
      currentPageIndex >= 0 ? { ...pages[currentPageIndex] } : false;

    // put current page to state
    this.setState({ pages, currentPage, currentPageIndex });
  };

  render() {
    // close menu
    const closeElementMenu = () => {
      this.setState({
        elementMenuAnchor: null,
        elementMenuData: null,
      });
    };

    const {
      baseUrl,
      currentCategory,
      page_id,
      showConfirmDialog = console.debug,
      deleteContentElement = console.debug,
      updateContentElement = console.debug,
      addPage = console.debug,
      deletePage = console.debug,
      onUpdate = () => console.debug("Pages.onUpdate is called"),
    } = this.props;

    const {
      pages,
      currentPage,
      currentPageIndex,
      editElement,
      contentElementInForm,
      elementMenuAnchor,
      elementMenuData,
      listenToClickAwayOnEditor,
    } = this.state;

    console.debug("pages:", pages);
    return (
      <div className="flex grid-container column">
        <div className="grid-item" key={`left-panel`}>
          {/* pages side bar */}
          <List className="page-list" variant="persistent" anchor="left">
            <h3>Pages</h3>
            {Array.isArray(pages) &&
              pages.map((item, pageNumber) => {
                const { page_id } = item;

                return (
                  <ListItem
                    className="page-icon"
                    button
                    key={page_id}
                    component={NavLink}
                    to={`${baseUrl}/pages/${page_id}`}
                  >
                    <span className="page-number">{pageNumber + 1}</span>
                    <span className="page-id">id:{page_id}</span>
                  </ListItem>
                );
              })}
            <ListItem
              className="page-icon add-button flex row"
              button
              key={"pages-add"}
              onClick={() => {
                // this.setState({
                //   confirmHeader: "Add Page",
                //   confirmPrompt: "Are you sure you want to add a new page?",
                //   onConfirm: this.addPage,
                // });
                showConfirmDialog(
                  "Add Page",
                  "Are you sure you want to add a new page?",
                  () => {
                    addPage();
                    // close dialog
                    showConfirmDialog(null, null, null);
                  }
                );
              }}
            >
              <Add /> Add page
            </ListItem>
          </List>
        </div>
        <div className="grid-item" key={`right-panel`}>
          {(!Array.isArray(pages) ||
            (Array.isArray(pages) && pages.length === 0)) && (
            <Alert severity="info">
              There are no pages in category use "Add" button to add a page
            </Alert>
          )}
          {Array.isArray(pages) && pages.length !== 0 && !page_id && (
            <Alert severity="info">Please select a page to display</Alert>
          )}
          {Array.isArray(pages) &&
            pages.length !== 0 &&
            page_id &&
            !currentPage && <Spinner />}
          {currentPage && (
            <div className="page-header">
              <div className="title">
                {/* {currentCategory.title} */}
                <span className="page-index">Page {currentPageIndex + 1}</span>
                {/* <span className="page-id">
                                  id: {currentPage.page_id}
                                </span> */}
              </div>
              <ButtonGroup>
                <Button
                  variant="contained"
                  onClick={() =>
                    this.setState({
                      contentElementInForm: { ...defaultContentElement },
                    })
                  }
                >
                  Add content element
                </Button>
                <Button
                  variant="contained"
                  onClick={() => this.setState({ configureModel: true })}
                >
                  configure
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    showConfirmDialog(
                      "Deleting category page",
                      `Are you sure you want to delete page ${
                        currentPageIndex + 1
                      } from this category?`,
                      () => {
                        // delete...
                        deletePage(currentPage.page_id);
                        // ...and close dialog
                        showConfirmDialog(null, null, null);
                      }
                    );
                  }}
                >
                  delete
                </Button>
              </ButtonGroup>
            </div>
          )}
          {currentPage &&
            (!Array.isArray(currentPage.content) ||
              (Array.isArray(currentPage.content) &&
                currentPage.content.length === 0)) && (
              <Alert severity="info">
                There are no content elements on this page. Use "Add content
                element" button to add one.
              </Alert>
            )}
          {currentPage &&
            Array.isArray(currentPage.content) &&
            currentPage.content.map((content) => {
              const { html, element_title, element_id } = content;
              return (
                <div className={"page-content"}>
                  <div className="page-content-header flex row justify-content-flex-end">
                    <span>{element_title}</span>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={() =>
                        this.setState({
                          editElement: { ...content },
                        })
                      }
                    >
                      Edit
                    </Button>
                    <IconButton
                      size="small"
                      // set anchor for the flyout menu
                      onClick={({ currentTarget }) =>
                        this.setState(
                          {
                            elementMenuAnchor: currentTarget,
                            elementMenuData: { ...content },
                          },
                          () => console.debug(this.state)
                        )
                      }
                    >
                      <MoreVert />
                    </IconButton>
                  </div>
                  {!(editElement && element_id === editElement.element_id) && (
                    <div
                      className={"content-element"}
                      dangerouslySetInnerHTML={{
                        __html: html,
                      }}
                    />
                  )}
                  {editElement && element_id === editElement.element_id && (
                    <ClickAwayListener
                      // this is a way to disable the listener:
                      mouseEvent={listenToClickAwayOnEditor ? "onClick" : false}
                      touchEvent={listenToClickAwayOnEditor ? "onClick" : false}
                      // this onClickAway doesn't work with links, unfortunately
                      // TODO: find out how to deal with link clicks
                      onClickAway={(ev) => {
                        this.setState(
                          {
                            // turn the listener off
                            listenToClickAwayOnEditor: false,
                          },
                          () => {
                            console.debug("Clicked away!");
                            // do not propagate the click
                            ev.stopPropagation(); // doesn't work (

                            // confirm exit
                            showConfirmDialog(
                              "Exit Edit Mode",
                              `Are you sure you want to exit edit mode without saving`,
                              () => {
                                this.setState({
                                  editElement: null,
                                  listenToClickAwayOnEditor: true,
                                });
                                // close the dialog
                                // showConfirmDialog(null, null, null);
                              },
                              // beforeClose
                              () => {
                                // wait half a sec then reinstate clickaway listener.
                                // We need the waiting time so that the confirmation dialog is closed
                                // and the clickAway event isn't fired immediately after clicking on Cancel button
                                setTimeout(
                                  () =>
                                    this.setState({
                                      listenToClickAwayOnEditor: true,
                                    }),
                                  500
                                );
                              }
                            );
                          }
                        );
                      }}
                    >
                      <div>
                        <Editor
                          apiKey={tinymceKey}
                          content={html}
                          onSave={(updatedHtml) =>
                            updateContentElement(editElement.element_id, {
                              html: updatedHtml,
                            })
                          }
                          onCancel={() => {
                            this.setState({
                              editElement: null,
                            });
                          }}
                        />
                      </div>
                    </ClickAwayListener>
                  )}
                </div>
              );
            })}
        </div>

        {
          // Flyout menu for content element.
          // Contains Edit and Delete buttons
        }
        <Menu
          anchorEl={elementMenuAnchor}
          open={Boolean(elementMenuAnchor)}
          onClose={() =>
            this.setState({
              elementMenuAnchor: null,
              elementMenuData: null,
            })
          }
        >
          <MenuItem
            onClick={() => {
              this.setState({
                contentElementInForm: elementMenuData,
              });
              closeElementMenu();
            }}
          >
            <Edit /> Edit or replace element
          </MenuItem>
          {/* <ButtonGroup> */}
          <MenuItem
            // variant="contained"
            // size="small"
            // color="secondary"
            onClick={() =>
              showConfirmDialog(
                "Unassign Element",
                `Are you sure you want to unassign element ${elementMenuData?.element_title}?`,
                async () => {
                  await deleteContentElement(elementMenuData?.element_id);
                  // close the dialog
                  showConfirmDialog(null, null, null);
                  closeElementMenu();
                }
              )
            }
          >
            <Delete /> Remove element
          </MenuItem>
          {/* </ButtonGroup> */}
        </Menu>

        {
          // Form to edit content element data (except for HTML)
        }
        <ModalContent
          // {...{ id, category_id, page_id }}
          id={currentCategory?.course_id} // course id - category may or may not have course id
          category_id={currentCategory?.content_cat_id} // category id
          currentCategory={currentCategory} // category object
          page_id={page_id} // page id (comes through props)
          onModalOpen={Boolean(contentElementInForm)}
          currentElement={contentElementInForm}
          onModalClose={() => this.setState({ contentElementInForm: null })}
          onUpdate={onUpdate}
        />
      </div>
    );
  }
}

export default ContentCategoriesPages;

import React from "react";
import { Grid, Button } from "@material-ui/core";
// import QuizEditor from "./quiz-editor";
import "./questions-editor.scss";
import ExperimentService from "../../../../../services/experiments";
import store from "../../../../../redux/store";
import { showMessage } from "@ses-education/courses-components";
import ElementEditor from "./element-editor";
import {debounce} from '@ses-education/content-parser';
import ConfirmDialog from "../../../../template/ConfirmDialog";
import TranslationsService from "../../../../../services/translations-service";




const msg = (m,l) => store.dispatch( showMessage(m,l) )

const Question = ({
  question_id,
  question_type,
  translations,
  language_id,
  onAddTranslation,
  onTranslationUpdate,
}) => {
  // flag for delete question confirm daialog
  const [onconfirmDialog, setConfirmDialog] = React.useState(false);
  // delete qustion on confirm call this function
  const deleteQuestion = async() => {
    const result = await TranslationsService.deleteQuestionTranslation();
    // if there was an error
    if(!result){
      //show error message and close confirm dialog
      msg(TranslationsService.error || "Error deleting question translation", "error");
      setConfirmDialog(false);
      return;
    }
    // if there was no error reload page and close confirm dialog
    onTranslationUpdate();
    setConfirmDialog(false);
  }
  const translation = Array.isArray(translations)
    ? translations.find((t) => t.language_id === language_id)
    : null;
  const original = Array.isArray(translations)
    ? translations.find((t) => t.language_id === "en")
    : null;
  const AddButton = (
      <Button
        variant="contained"
        onClick={() => onAddTranslation(question_id, language_id)}
      >
        Create translation
      </Button>
  );
  // delete button for deleting qustion translation
  const DeleteButton= (
    <Button
      variant="contained"
      onClick={() => setConfirmDialog(true)}
    >
      Delete
    </Button>
  )
  if (!original)
    return (
      <div className="translation-desk">
        <div className="label">
          {question_id}: {question_type}
        </div>
        Something's wrong with this question!
      </div>
    );
  // if( !translation ) return <div className='translation-desk'><div className="label">{question_id}: {question_type}</div><Button variant="contained" onClick={() => onAddTranslation(question_id, language_id) }>Create translation</Button> </div>

  const questionProps = {
    question_id,
    question_type,
    language_id,
    onTranslationUpdate,
    AddButton,
    original,
    translation,
    DeleteButton,
  };

  return <>
    <GeneralQuestion key={`question-${question_id}-${language_id}`} {...questionProps}/>
    <ConfirmDialog
      open={onconfirmDialog}
      header="Confirm question deletion" 
      prompt={ `Are you sure you want to delete the question`}
      onConfirm={deleteQuestion}
      onClose={()=> setConfirmDialog(false)} 
    />
  </>
      {/* {question_type === "text-field" && <TextQuestion {...questionProps} />}
      {question_type === "multiple" && <Multiple {...questionProps} />}
      {question_type === "binary" && <Binary {...questionProps} />}
      {question_type === "quiz" && <Quiz {...questionProps} />} */}
    

};

// const TextQuestion = ({
//   question_id,
//   question_type,
//   language_id,
//   AddButton,
//   original,
//   onTranslationUpdate,
//   translation,
// }) => (
//   <>
//     <div className="translation-desk question">
//       <div className="label">
//         {question_id}: {question_type}
//       </div>
//       <Grid container spacing={4}>
//         <Grid item xs={12} sm={6} className="english" data-ln1={"en"}>
//           <ElementEditor
//             element_type="text-field"
//             type="show"
//             id={question_id}
//             {...original.question_data}
//           />
//         </Grid>
//         <Grid
//           item
//           xs={12}
//           sm={6}
//           className="translation"
//           data-ln1={language_id}
//         >
//           {translation && (
//             <ElementEditor
//               element_type="text-field"
//               type="edit"
//               id={question_id}
//               {...translation.question_data}
//               onChange={onTranslationUpdate}
//             />
//           )}
//           {!translation && AddButton}

//           {/* <div className="text-field">
//                         <TextField label="Title" value={title}  />
//                         </div>  */}
//         </Grid>
//       </Grid>
//     </div>
//   </>
// );

// const Binary = ({
//   question_id,
//   question_type,
//   title,
//   description,
//   language_id,
// }) => {
//   return (
//     <div className="translation-desk question">
//       <div className="label">
//         {question_id}: {question_type}
//       </div>
//       <Grid container spacing={4}>
//         <Grid item xs={12} sm={6} className="english" data-ln1={"en"}></Grid>
//         <Grid
//           item
//           xs={12}
//           sm={6}
//           className="translation"
//           data-ln1={language_id}
//         ></Grid>
//       </Grid>
//     </div>
//   );
// };
// const Multiple = ({
//   question_id,
//   question_type,
//   language_id,
//   onTranslationUpdate,
//   AddButton,
//   original,
//   translation,
// }) => {
//   return (
//     <div className="translation-desk question">
//       <div className="label">
//         {question_id}: {question_type}
//       </div>
//       <Grid container spacing={4}>
//         <Grid item xs={12} sm={6} className="english" data-ln1={"en"}>
//           <ElementEditor
//               element_type={question_type}
//               type="show"
//               id={question_id}
//               {...original.question_data}
//             />
//         </Grid>
//         <Grid
//           item
//           xs={12}
//           sm={6}
//           className="translation"
//           data-ln1={language_id}
//         >
//           {!translation && AddButton}
//           {/* { translation && <QuizEditor {...translation} type="edit" /> } */}
//           {translation && (
//             <ElementEditor
//               element_type={question_type}
//               type="edit"
//               id={question_id}
//               {...translation.question_data}
//               onChange={onTranslationUpdate}
//             />
//           )}
//         </Grid>
//       </Grid>
//     </div>
//   );
// };
// const Quiz = ({
//   question_id,
//   question_type,
//   language_id,
//   original,
//   AddButton,
//   onTranslationUpdate,
//   translation,
// }) => {
//   return (
//     <div className="translation-desk question">
//       <div className="label">
//         {question_id}: {question_type}
//       </div>
//       <Grid container spacing={4}>
//         <Grid item xs={12} sm={6} className="english" data-ln1={"en"}>
//           <ElementEditor
//             element_type="quiz"
//             type="show"
//             id={question_id}
//             {...original.question_data}
//           />
//           {/* <QuizEditor {...original} type="show" /> */}
//           {/* <QuizComponent 
//                             data={{question_id,question_type,  title, description, language_id, ...other}}
//                             randomize={false}
//                         /> */}
//         </Grid>
//         <Grid
//           item
//           xs={12}
//           sm={6}
//           className="translation"
//           data-ln1={language_id}
//         >
//           {!translation && AddButton}
//           {/* { translation && <QuizEditor {...translation} type="edit" /> } */}
//           {translation && (
//             <ElementEditor
//               element_type="quiz"
//               type="edit"
//               id={question_id}
//               {...translation.question_data}
//               onChange={onTranslationUpdate}
//             />
//           )}
//         </Grid>
//       </Grid>
//     </div>
//   );
// };

const GeneralQuestion = ({
  question_id,
  question_type,
  language_id,
  onTranslationUpdate,
  AddButton,
  original,
  translation,
  DeleteButton,
}) => {
  return (
    <div className={`translation-desk question ${question_type}`}>
      <div className="label">
        {question_id}: {question_type}
      </div>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} className="english" data-ln1={"en"}>
          <ElementEditor
              element_type={question_type}
              type="show"
              id={question_id}
              {...original.question_data}
            />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className="translation"
          data-ln1={language_id}
        >
          {!translation && AddButton}
          {translation && (
            <>
              {DeleteButton}
              <ElementEditor
                element_type={question_type}
                type="edit"
                id={question_id}
                {...translation.question_data}
                onChange={onTranslationUpdate}
              />
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
};



class QuestionsEditor extends React.Component {
  state = {
    questions: null,
  };

  /**
   * debounced update function
   */
  debouncedUpdate = null;

  componentDidMount() {

    this.debouncedUpdate = debounce( async (v) => {


      // remove id, it's irrelevant
      const {id, ...other} = v;
      const {language_id} = this.props;
      
      
      try{
        await ExperimentService.updateQuestionTranslation( id, language_id, other )
        if( typeof this.props.onQuestionsChange !== "function") {
          msg("Question updated but onQuestionsChange function is not passed, try reloading the page manually")
          return;
        }

        this.props.onQuestionsChange();
      }catch(e){
        msg(e.message, "error")
      }

    }, 500);

    this.populateState();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.pageQuestions) !==
        JSON.stringify(this.props.pageQuestions) ||
      prevProps.language_id !== this.props.language_id
    )
      this.populateState();
  }

  populateState() {
    const { pageQuestions } = this.props;
    if (!Array.isArray(pageQuestions)) {
      this.setState({ questions: null });
      return;
    }

    const questions = pageQuestions.filter(
      (q) =>
        // either the type of queastion is one of these...
        ["multiple", "binary", "quiz"].includes(q.question_type) ||
        // or it's text_field
        (q.question_type === "text-field" &&
          Array.isArray(q.translations) &&
          // ...and it has english translation
          q.translations.find((t) => t.language_id === "en") &&
          // and english translation has non-empty title or description
          q.translations.find((t) => t.language_id === "en").question_data
            .title) ||
        q.translations.find((t) => t.language_id === "en").question_data
          .description
    );

    this.setState({ questions });
  }

  onAddTranslation = async (question_id, language_id) => {
    console.debug(
      "Adding translation to language",
      language_id,
      "for question #",
      question_id
    );

    try {
      await ExperimentService.addQuestionTranslation(question_id, language_id);
      this.props.onQuestionsChange && this.props.onQuestionsChange();
    } catch (e) {
      store.dispatch(showMessage(e.message, "error"));
    }
  };

  onTranslationUpdate = (id, value) => {
    console.debug("questionsEditor.onTranslationUpdate called with\n", value);
    this.debouncedUpdate(value)
  };

  render() {
    const { language_id } = this.props;
    // console.debug("props", this.props)
    const { questions } = this.state;
    return (
      <div className="questions-editor">
        {!Array.isArray(questions) && "No questions"}
        {Array.isArray(questions) &&
          questions.map((q) => (
            <Question
              {...{
                ...q,
                language_id,
                onAddTranslation: this.onAddTranslation,
                onTranslationUpdate: this.onTranslationUpdate,
              }}
            />
          ))}
      </div>
    );
  }
}

export default QuestionsEditor;
export { GeneralQuestion };
import {Spinner} from "@ses-education/courses-components";
import { Button } from "@material-ui/core";
import React from "react";
import CourseService from "../../../../services/course";
import Error from "../../../template/Error";
// import spinner from "../../../template/spinner";
import TransferList from "../../../template/transferList/TransferList";
import "./requirements.scss";

class Requirements extends React.Component {
  state = {
    selected: [],
    requirements: null,
    courses: null,
    changed: false
  };

  componentDidMount() {
    this.fetchRequirements();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.course_id !== this.props.course_id) this.fetchRequirements();
  }

  /**
   * Checks whether 'selected' array is different from 'requirements' that came from server   
   * 
   * @param {*} selected 
   * @returns 
   */
  changed = ( selected ) => {
    const { requirements } = this.state;
    if (!Array.isArray(requirements) || !Array.isArray(selected)) return false;
    const requirementIds = requirements.map((r) => r.id);  
    console.debug( "req ids", requirementIds, "selected ids", selected)  
    // as there should not be duplicate entries in each arrays, we can check just their sizes and one side inlcusion
    return (
      requirementIds.length !== selected.length ||
      !requirementIds.every((id) => selected.includes(id))
    );
  };

  async fetchRequirements() {
    const { course_id } = this.props;
    if (!course_id) {
      this.setState({ requirements: [] });
      return;
    }

    const requirements = await CourseService.getCourseRequirements(course_id);
    const courses = await CourseService.getCourseListPage(1, 1000);

    this.setState({
      requirements,
      error: requirements && courses ? null : CourseService.error,
      selected: Array.isArray(requirements) ? requirements.map(r=>r.id) : [] ,
      courses: Array.isArray(courses)
        ? courses.map((c) => ({
            id: c.course_id,
            title: `${c.course_code}: ${c.title}`,
          }))
        : courses,
    });
  }

  onChange = (selected) => {
    console.debug("Selected:", selected);
    selected = Array.isArray(selected) ? selected.map((i) => i.id) : [];
    this.setState({
      selected,
      changed: this.changed( selected )
    });
  };

  save = async () => {
    const {course_id, message} = this.props;

    try{
      await CourseService.updateCourseRequirements(  course_id, this.state.selected);
      message("Course requirements successfully updated");
      this.setState({
        changed: false
      })
    } catch( e ){
      message( e.message, "error" )
    }

  }

  reset = () => {
    // this also doesn't work ((
    this.fetchRequirements();

    // const {requirements} = this.state;
    // if( !Array.isArray(requirements) )  return;
    
    // this.setState({
    //   selected: requirements.map( r => r.id ),
    //   // update requirements in state to re-render component
    //   // IT DOESNT WORK AT ALL. F*CK FUNCTIONAL COMPONENTS
    //   requirements: [],
    //   changed: false
    // }, () => this.setState({
    //   requirements
    // }))
  }

  render() {
    const { requirements, courses, error, changed } = this.state;

    const { course_id } = this.props;

    if (requirements === null || courses === null) return <Spinner />;
    if (requirements === false || courses === false)
      return <Error error={error} />;

    // if( courses === null ) return <spinner />

    return (
      <div className="course-requirements">
        <div className="buttons">
          <Button variant="contained" color="primary" disabled={!changed} onClick={this.save}>Save</Button>
          {/* <Button variant="contained" color="secondary" disabled={!changed} onClick={this.reset}>Reset</Button> */}
        </div>
        <TransferList
          titleLeft="Select from the complete list of courses"
          titleRight="Required courses"
          course_id={course_id}
          onChange={this.onChange}
          left={courses.filter(
            (c) => !requirements.find((cc) => cc.id === c.id)
          )}
          right={requirements}
        />
      </div>
    );
  }
}

export default Requirements;

import React from "react";
import {
  LinearProgress,
  Grid,
  ButtonGroup,
  Button,
  Typography,
  // Modal,
  Checkbox,
} from "@material-ui/core";
import OrganizationService from "../../../services/organizations";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { 
  // DataGrid, 
  GridOverlay, GridToolbarExport } from "@material-ui/data-grid";
import { connect } from "react-redux";
import { showMessage, AdminTable, ModalWindow, ConfirmDialog } from "@ses-education/courses-components";
// import ModalForm from "../../template/modalForm";
import Error from '../../template/Error';
// import AdminTable from "@ses-education/courses-components/admin-table";
// import ModalWindow from "@ses-education/courses-components/modal-window";
import { Check } from "@material-ui/icons";
import OrganizationForm from "./organization-form";
// import ConfirmDialog from "../../template/ConfirmDialog";

class Organizations extends React.Component {
  state = {
    error: null,
    loading: false,
    organizations: null,
    rows: [],
    modalOpen: false,
    confirmDeletion: false
  };

  async componentDidMount() {
    this.setState({ loading: true });
    const organizationsLoaded = await this.fetchOrganizations();

    if (organizationsLoaded) {
      this.setState({ error: null, loading: false });
    }
  }

  async fetchOrganizations() {
    try {
      const organizations = await OrganizationService.getOrganizations();
      if (organizations) {
        this.setState({ organizations });
        this.populateRows(organizations);
        return true;
      } else {
        this.setState({ error: "Failed loading organizations" });
      }
    } catch (err) {
      this.props.onShowMessage(
        `Fetching organizations from server failed.`,
        "error"
      );
      return false;
    }
  }

  populateRows(organizations) {
    let rows = organizations.map( o => ({
      id: o.organization_id,
      ...o
    }) )
    // let rows = [];
    // organizations.forEach((o) => {
    //   const item = {
    //     id: o.organization_id,        
    //     // is_active: [
    //     //   <Checkbox
    //     //     key={o.organization_id}
    //     //     checked={o.active === 1 ? true : false}
    //     //     disabled
    //     //   />,
    //     // ],
    //     // organization_name: o.organization_title,
    //   };
    //   rows.push(item);
    // });
    this.setState({ rows });
  }

  addOrganizationHandler() {
    this.setState({ modalOpen: true });
  }

  confirmOrganizationDelete = ( confirmDeletion ) => {
    this.setState({confirmDeletion})
  }

  async deleteOrganizationHandler(id) {
    // close confirmation dialog
    this.setState({confirmDeletion:false})

    const result = await OrganizationService.deleteOrganization(id);
    if (result) {
      if (result.message) {
        this.props.onShowMessage(result.message, "error");
      } else {
        await this.fetchOrganizations();
        this.props.onShowMessage(
          `Organization with ID #${id} was deleted successfuly`,
          "info"
        );
      }
    }
  }

  closeModalHandler() {
    this.setState({ modalOpen: false });
  }

  loadingOverlay() {
    return (
      <GridOverlay>
        <div style={{ position: "absolute", top: 0, width: "100%" }}>
          <LinearProgress />
        </div>
      </GridOverlay>
    );
  }

  onSaveOrganization = async ( data ) => {
    const {onShowMessage} = this.props;
    console.debug(data);

    if( ! await OrganizationService.updateOrganization(data) ){
      onShowMessage( OrganizationService.error, "error")
      return false;
    }
    onShowMessage( "Organization successfully created" )
    // reload list
    this.fetchOrganizations();
    this.closeModalHandler();
  }

  render() {
    const { loading, rows, error, modalOpen, confirmDeletion } = this.state;

    const columns = [
      { field: "id", headerName: "ID", flex: 0.8 },
      {
        // field: "is_active",
        field: "active",
        headerName: "Active",
        flex: 1,
        valueFormatter: (params) => params.value === 1 ?  "yes" : "no",
        renderCell: (params) => <div>{params.value === 1 ? <Check className="icon-green" /> : "" }</div>,
      },
      { field: "organization_title", headerName: "Organization Name", flex: 3 },
      { field: "url_slug", headerName: "URL slug", flex: 1, valueFormatter: (params) => `/${params.value}`, },
      {
        field: "buttons",
        headerName: "Actions",
        flex: 2,
        renderCell: (params) => (
          <div>
            <ButtonGroup>
              <Button
                onClick={() =>
                  this.props.history.push(`/organizations/${params.id}`, {
                    id: params.id,
                  })
                }
                variant="contained"
                color="primary"
                size="small"
                startIcon={<EditIcon />}
              >
                Edit
              </Button>
              <Button
                // onClick={() => this.deleteOrganizationHandler(params.id)}
                onClick={() => this.confirmOrganizationDelete(params.id)}
                variant="contained"
                color="secondary"
                size="small"
                startIcon={<DeleteIcon />}
              >
                Delete
              </Button>
            </ButtonGroup>
          </div>
        ),
      },
    ];

    if (error) return <Error {...{ error }} />;

    const AddButton = <Button
              onClick={this.addOrganizationHandler.bind(this)}
              className="green-btn"
              variant="contained"
              startIcon={<AddBoxIcon />}
            >Add organization</Button>

    return (
      <div className="page organizations">
        <Typography variant="h1" color="primary">Organizations</Typography>
        {/* <Grid container> */}
          {/* <Grid
            item
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Button
              onClick={this.addOrganizationHandler.bind(this)}
              style={{
                backgroundColor: "green",
                color: "white",
                marginBottom: "18px",
                marginLeft: "20px",
              }}
              variant="contained"
              startIcon={<AddBoxIcon />}
            >
              Add organization
            </Button>
          </Grid> */}
          {/* <Grid
            item
            style={{
              height: "70vh",
              width: "100%",
            }}
          > */}
            {/* <DataGrid
              components={{
                LoadingOverlay: this.loadingOverlay,
              }}
              rows={rows}
              columns={columns}
              pageSize={10}
              rowHeight={80}
              disableSelectionOnClick
              loading={loading}
            /> */}
            <AdminTable
            buttons={[
              AddButton, <GridToolbarExport />
            ]
            }
              rows={rows}
              columns={columns}
              searchFields={["organization_title", "url_slug"]}
              pageSize={10}    
              autoHeight          
              disableSelectionOnClick
              loading={loading}
            />
          {/* </Grid>
        </Grid> */}
        <ModalWindow open={modalOpen} onClose={this.closeModalHandler.bind(this)} header="Add organization" formClassName="stretch">
          <OrganizationForm
            onClose={this.closeModalHandler.bind(this)}
            onSave={ this.onSaveOrganization }
          />
          {/* <ModalForm
            type={"creation"}
            onClose={this.closeModalHandler.bind(this)}
            onOrganizationCreation={this.fetchOrganizations.bind(this)}
          /> */}
        </ModalWindow>
        <ConfirmDialog 
        open={confirmDeletion} 
        onClose={ () => this.setState({confirmDeletion: false})} 
        onConfirm={() => this.deleteOrganizationHandler(confirmDeletion)}
        header="Confirm organization deletion"
        prompt={`Are you sure you want to delete organization with ID=${confirmDeletion}?`}
        />        
       
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onShowMessage: (message, type) => dispatch(showMessage(message, type)),
  };
};

export default connect(null, mapDispatchToProps)(Organizations);

import React from "react";
import CourseTypesList from "./course-types-list";
import Page404 from "../../../template/404";
import CourseTypesType from "./course-types-type";
import CourseService from "../../../../services/course";
// import { Home, SettingsApplications } from "@material-ui/icons";
import {AutoBreadcrumbs} from "@ses-education/courses-components";
import { connect } from "react-redux";
import {showMessage} from "@ses-education/courses-components";

class CourseTypes extends React.Component {
  state = {
    types: null,
    currentType: null,
  };
  componentDidMount = async () => {
    await this.fetchData();
  };
  componentDidUpdate = async (prevProps) => {
    if (this.props.match.params.type_id !== prevProps.match.params.type_id) {
      await this.fetchData();
    }
  };
  fetchData = async () => {
    const { type_id } = this.props.match.params;
    const types = await CourseService.getCourseTypes();
    console.debug("fetched types:", types);
    if (!Array.isArray(types)) {
      this.props.onShowMessage( CourseService.error || "Error loading course types");
      return;
    }
    const currentType =
      type_id &&
      types.find((type) => type.course_type_id === parseInt(type_id));
    this.setState({ types, currentType });
  };
  render() {
    const { types, currentType } = this.state;
    const { type_id } = this.props.match.params;
    let { breadcrumbs } = this.props;
    // let breadcrumbs = [
    //   { icon: <Home/> , to: "/"},
    //   { to: "/settings", icon: <SettingsApplications/>,  text: "Settings"},
    //   { to: "/settings/course-types", text: "Course Types"},
    // ]
    if (type_id && currentType) {
      breadcrumbs = [...breadcrumbs, { text: currentType.title }];
    }
    return (
      <>
        {!(type_id && types && !currentType) && (
          <AutoBreadcrumbs items={breadcrumbs} />
        )}
        {!type_id && (
          <CourseTypesList {...{ types }} onUpdate={this.fetchData} />
        )}
        {type_id && types && !currentType && <Page404 />}
        {type_id && currentType && (
          <CourseTypesType {...{ currentType }} onUpdate={this.fetchData} />
        )}
      </>
    );
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    onShowMessage: (message, type = "error") => dispatch(showMessage(message, type)),
  };
};

export default connect(null, mapDispatchToProps)(CourseTypes);
// export default CourseTypes;

import React from "react";
import { Box, List, ListItem } from '@material-ui/core'
import { Link } from "react-router-dom";
class CourseTypesList extends React.Component {
  render() {
    const {types} = this.props;
    return (
      <>
        <List component={Box} sx={{ bgcolor: "background.paper" }}>
          {Array.isArray(types) && types.map(type => {
            return <ListItem button component={Link} to={`/settings/course-types/${type.course_type_id}`}>
              {type.title}
          </ListItem>
          })}
        </List>
      </>
    );
  }
}

export default CourseTypesList;

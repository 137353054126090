import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { InputAdornment, TextField, Typography } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import "./transfer-list.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
  },
  paper: {
    width: "auto",
    height: "auto",
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

export default function TransferList(props) {
  let {
    left = [
      { id: 1, title: "item 1" },
      { id: 2, title: "item 2" },
      { id: 3, title: "item 3" },
    ],
    right = [
      { id: 4, title: "item 4" },
      { id: 5, title: "item 5" },
      { id: 6, title: "item 6" },
    ],
    onChange = (ev) => console.debug("current selected:", right),
    idField = "id",
    titleField = "title",
    titleLeft, titleRight,
    className
  } = props;

  const not = (a, b) => {
    // return a.filter((value) => b.indexOf(value) === -1);
    return a.filter((value) => !b.find((i) => i[idField] === value[idField]));
  };

  const intersection = (a, b) => {
    // return a.filter((value) => b.indexOf(value) !== -1);
    return a.filter((value) => b.find((i) => i[idField] === value[idField]));
  };

  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  let setLeft, setRight;
  [left, setLeft] = React.useState(left);
  [right, setRight] = React.useState(right);

  const leftChecked = () => intersection(checked, left);
  const rightChecked = () => intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    const result = right.concat(left);
    setRight(result);
    setLeft([]);

    onChange(result);
  };

  const handleCheckedRight = () => {
    const result = right.concat(leftChecked());
    setRight(result);
    setLeft(not(left, leftChecked()));
    setChecked(not(checked, leftChecked()));
    onChange(result);
  };

  const handleCheckedLeft = () => {
    const result = not(right, rightChecked());

    setLeft(left.concat(rightChecked()));
    setRight(result);
    setChecked(not(checked, rightChecked()));

    onChange(result);
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
    onChange([]);
  };

  // console.debug( left )
  console.debug(right);
  // console.debug( checked )
  // console.debug( "left checked :" , leftChecked() )
  // console.debug( "right checked :" , rightChecked() )

  const CustomList = (props) => {
    const { items, idField = "id", titleField = "title", title } = props;

    let [search, setSearch] = useState("");

    return (
      <Paper className={classes.paper}>
        <Typography color="primary" className="transfer-list-header">{title}</Typography>
        <TextField
          placeholder="search..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          fullWidth
          value={search}
          onChange={({ target }) => setSearch(target.value.toLowerCase())}
        />
        <List dense component="div" role="list">
          {items
            // if search not empty, return only items that contain search substring
            // otherwise return all ( filter(i=>true) )
            .filter((i) =>
              search !== "" ? i[titleField].toLowerCase().includes(search) : true
            )
            .map((item) => {
              // destruct using id field and title field
              const { [idField]: value, [titleField]: title } = item;
              const labelId = `transfer-list-item-${value}-label`;

              return (
                <ListItem
                  key={value}
                  role="listitem"
                  button
                  onClick={handleToggle(item)}                  
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.map((i) => i[idField]).includes(value)}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={title} />
                </ListItem>
              );
            })}
          <ListItem />
        </List>
      </Paper>
    );
  };

  return (
    <Grid
      container
      spacing={2}
      // justifyContent="center"
      alignItems="center"
      className={classes.root}
      className={`transfer-list${className ? ` ${className}` : ""}`}
    >
      <Grid className="tl-left" item>{CustomList({ title: titleLeft, items: left, idField, titleField })}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center" className="tl-buttons">
          
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked().length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked().length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <span className="horizontal-separator"></span>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid className="tl-right" item>{CustomList({ title: titleRight, items: right, idField, titleField })}</Grid>
    </Grid>
  );
}

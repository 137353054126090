import React from 'react';
import {
  Container,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import { Email, Lock, AccountCircle as UserIcon } from '@material-ui/icons';
import store from '../../redux/store';
import A from '../../redux/actionTypes';
import Service from '../../services/auth';
// import Notification from '../template/Notification';

import './login.scss';

class Login extends React.Component {
  state = {
    email: '',
    password: '',
    connecting: false,
  };

  validateForm = (ev) => {
    return ev.currentTarget.reportValidity();
  };

  login = async (ev) => {
    // catch submit
    ev.preventDefault();

    if (this.validateForm(ev)) {
      const { email, password } = this.state;

      if (email && password) {
        this.setState({ connecting: true }, () => {
          this.doLogin(email, password);
        });
      }
    }
  };

  doLogin = async (email, password) => {
    const result = await Service.login(email, password);

    this.setState({ connecting: false });
    if (result) {
      if (result === 'wrong credentials') {
        store.dispatch({
          type: A.SHOW_MESSAGE,
          payload: { message: 'Login failed', type: 'error' },
        });
        return;
      }
    } else {
      store.dispatch({
        type: A.SHOW_MESSAGE,
        payload: { message: Service.error, type: 'error' },
      });
    }
  };

  updateValue = ({ target }) => {
    const { name, value } = target;
    // console.log( name, value)
    this.setState({ [name]: value });
  };

  render() {
    const { email, password, connecting } = this.state;

    return (
      <>
        <Container id='login'>
          <Typography variant='h1' color='primary'>
            SES courses admin
          </Typography>
          {connecting ? (
            <div className='login-form'>
              <UserIcon fontSize='large' color='primary' />
              <CircularProgress className='spin-over' />
              <Typography className='bold' color='primary'>
                Logging in...
              </Typography>
            </div>
          ) : (
            <form noValidate className='login-form' onSubmit={this.login}>
              <Grid container spacing={4}>
                <Grid item xs={12} className='flex column align-items-center'>
                  <UserIcon fontSize='large' color='primary' />
                  <Typography className='bold' color='primary'>
                    Login
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl>
                    <InputLabel htmlFor='input-with-icon-adornment'>
                      Email
                    </InputLabel>
                    <Input
                      id='input-with-icon-adornment'
                      autoComplete='username'
                      type='email'
                      name='email'
                      onChange={this.updateValue}
                      value={email}
                      startAdornment={
                        <InputAdornment position='start'>
                          <Email color='primary' />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl>
                    <InputLabel htmlFor='input-with-icon-adornment2'>
                      Password
                    </InputLabel>
                    <Input
                      id='input-with-icon-adornment2'
                      type='password'
                      name='password'
                      autoComplete='current-password'
                      onChange={this.updateValue}
                      value={password}
                      startAdornment={
                        <InputAdornment position='start'>
                          <Lock color='primary' />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button variant='contained' color='primary' type='submit'>
                    Log in
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Container>
        {/* <Notification /> */}
      </>
    );
  }
}

export default Login;

import React from "react";
import { Switch } from "react-router-dom";
import store from "../../redux/store";
import { arraysIntersect } from "../../utils/functions";
import Route from "../template/scrollToTop"; // route with auto-scroll to top on navigation

const RoutesComponent = (props) => {
  let { routes = [] } = props;

  const hasCredentials = (routeCredentials) => {
    const { user } = store.getState();

    if (!user) return false;
    if (!Array.isArray(user.credentials)) return false;

    const userCredentials = user.credentials;
    console.debug("userCredentials", userCredentials);

    // if user is admin, they can access all routes
    if (userCredentials.includes("admin")) return true;

    // if no credentials are set and user is not admin, deny access
    if (!Array.isArray(routeCredentials)) return false;

    return (
      // ...if route credentials include "*", any user has access to it
      routeCredentials.includes("*") ||
      // check if user has at least one of route credentials
      arraysIntersect(routeCredentials, userCredentials)
    );
  };

  routes = routes.filter((route) => hasCredentials(route.credentials));

  console.debug("routes", routes);

  return (
    <Switch>
      {routes.map((route, ind) => (
        <Route {...{ ...route, key: `route-${ind}` }} />
      ))}
    </Switch>
  );
};

export default RoutesComponent;

import React from 'react'

class HelpText extends React.Component{

    render(){
        const { html } = this.props;
        return <div dangerouslySetInnerHTML={{ __html: html }} />
    }
}

export default HelpText
let localStorageState;

/**
 * #mergeReducers()
 *
 * @returns
 */

/**
 * creates a reducer function out of several reducers. Calls each reducer in turn and checks whether state value has changed. If it did - returns the result.
 * @param {reducer[]} allReducers array of several reducer functions
 * @param {string|undefined} localStorageKey
 * @returns reducer function:  (state, action) => updatedState
 */
const mergeReducers = (allReducers, localStorageKey) => {
  if (localStorageKey) {
    try {
      const localStorageData = localStorage.getItem(localStorageKey);
      localStorageState = localStorageData
        ? JSON.parse(localStorageData)
        : null;
    } catch (e) {
      localStorageState = undefined;
    }
  }

  console.debug("mergeReducers localStorageState", localStorageState);
  return (state, action = {}) => {
    // console.debug("mergeReducers passed state", state, ", action:", action);

    // let's fall back to localStorage state if it's passed
    state = state
      ? state
      : // check if localStorage state is set
      localStorageState
      ? // set - state comes from localStorage
        localStorageState
      : // not set - it's what it is
        state;
    // console.debug("mergeReducers state after LS fallback", state);

    // create copy of state
    let updatedState =
      // state passed - copy it
      state
        ? { ...state }
        : // otherwise it's what it is
          state;

          console.debug("allReducers:", allReducers);
    // console.debug("mergeReducers updatedState at declaration", state);
    let i = 0;
    // cycle throgh reducers and check for state change.
    // if state is changed or there's no more reducers left, return updated state.
    while (
      JSON.stringify(state) === JSON.stringify(updatedState) &&
      i < allReducers.length
    ) {
      const reducerResult = allReducers[i](updatedState, action);
      updatedState = { ...(updatedState || {}), ...reducerResult };
      i++;
    }

    // if localStorage key is set, update localStorage value
    if (localStorageKey) {
      localStorage.setItem(localStorageKey, JSON.stringify(updatedState));
    }

    return updatedState;
  };
};

export default mergeReducers;

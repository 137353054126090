import React from "react";
import UserService from "../../../services/users";
import {
  Chip,
  LinearProgress,
  Grid,
  ButtonGroup,
  Button,
  Checkbox,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
  Breadcrumbs,
  Container,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { Edit as EditIcon, GroupAdd as GroupAddIcon } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { GridOverlay } from "@material-ui/data-grid";
import { connect } from "react-redux";
import { showMessage, AdminTable } from "@ses-education/courses-components";
import ModalUserForm from "../../template/modalUserForm";
import "./users.scss";
import OrganizationService from "../../../services/organizations";

import Error from "../../template/Error";

class Users extends React.Component {
  state = {
    users: [],
    rows: [],
    loading: false,
    confirmDialog: false,
    delID: null,
    error: null,
    modalOpen: false,
    renderModal: false, // triggers the rendering of ModalUserForm
    availableCredentials: [],
    user: null,
  };

  async componentDidMount() {
    this.setState({ loading: true });
    const usersLoaded = await this.fetchUsers();
    const availableCredentials = await this.fetchCredentials();
    if (usersLoaded && availableCredentials) {
      this.setState({ error: null, loading: false, availableCredentials });
    }
  }

  async fetchCredentials() {
    const credentials = await UserService.getCredentialsList();

    if (!Array.isArray(credentials)) {
      return false;
    }

    // if (this.props.match.params && this.props.match.params.org_id) {
    //   // if it's an organization specific user,
    //   // filter out credentials that are not assigned to the organization
    //   return credentials.filter((c) =>
    //     ["organization", "student"].includes(c.credential_id)
    //   );
    // }

    // everything's allright, return all credentials
    return credentials;
  }

  async fetchUsers() {
    try {
      let users = [];

      const {user} = this.state;
      // fetch organization specific users
      if (this.props.match.params && this.props.match.params.org_id) {
        users = await OrganizationService.getOrganizationUsers(
          this.props.match.params.org_id
        );
      } else {
        users = await UserService.getUsers();
      }

      if (users) {
        this.setState({ users });
        if(user){
          this.setState({ user: users.find(u => u.id === user.id) });
        }
        this.populateRows(users);
        return true;
      } else {
        this.setState({ error: "Failed loading users" });
      }
    } catch (err) {
      console.error("fetchUsers:", err);
      this.props.onShowMessage(`Fetching users from server failed.`, "error");
      return false;
    }
  }

  /**
   * populates users and stores "rows" array to state
   * @param {*} users
   */
  populateRows(users) {
    if (!Array.isArray(users)) {
      console.error("popuateRows: fetched users is not an array:", users);
      throw new Error("popuateRows: fetched users is not an array");
    }

    // let rows = [];
    // users.forEach((u) => {
    //   const item = {
    //     id: u.id,
    //     is_active: [
    //       <Checkbox
    //         key={u.id}
    //         checked={u.active === 1 ? true : false}
    //         disabled
    //       />,
    //     ],
    //     user_name: u.name,
    //     user_email: u.email,
    //     credentials: this.populateCredentials(u.credentials),
    //   };
    //   rows.push(item);
    // });
    const rows = users.map((u) => ({
      ...u,
      status: u.status ? u.status.status_title : "-",
      // is_active: [
      //   <Checkbox
      //     key={u.id}
      //     checked={u.active === 1 ? true : false}
      //     disabled
      //   />,
      // ],
      user_name: `${u.name} ${u.last_name}`,
      user_email: u.email,
      credentials: this.populateCredentials(u.credentials),
    }));

    this.setState({ rows });
  }

  populateCredentials(credentials) {
    // let chipArr = [];
    // for (let i = 0; i < credentials.length; i++) {
    //   chipArr.push(
    //     <Chip
    //       label={credentials[i]}
    //       color={credentials[i] === "admin" ? "secondary" : "primary"}
    //       variant="outlined"
    //       style={{ margin: "4px" }}
    //       size="small"
    //       key={i}
    //       //avatar={<Avatar>{course.course_id}</Avatar>}
    //     />
    //   );
    // }

    if (!Array.isArray(credentials) || credentials.length === 0)
      return "no credentials";

    const chipArr = credentials.map((c, i) => (
      <Chip
        label={c}
        color={c === "admin" ? "secondary" : "primary"}
        variant="outlined"
        style={{ margin: "4px" }}
        size="small"
        key={`cred-${i}`}
        //avatar={<Avatar>{course.course_id}</Avatar>}
      />
    ));

    // if no credentials were set return message
    if (chipArr.length === 1 && !chipArr[0].props.label) {
      return "no credentials";
    }

    return chipArr;
  }

  loadingOverlay() {
    return (
      <GridOverlay>
        <div style={{ position: "absolute", top: 0, width: "100%" }}>
          <LinearProgress />
        </div>
      </GridOverlay>
    );
  }

  closeDialogHandler() {
    this.setState({ confirmDialog: false, delID: null });
  }

  async deleteUserHandler() {
    const { delID } = this.state;
    const result = await UserService.deleteUser(delID);
    if (result) {
      this.props.onShowMessage(`User with id ${delID} was deleted.`, "info");
      this.fetchUsers();
    } else {
      this.props.onShowMessage(
        `Error deleting user, please remove assigned programs before deletion.`,
        "error"
      );
    }
    this.closeDialogHandler();
  }

  closeModal() {
    this.setState({
      modalOpen: false,
      renderModal: false,
      user: null,
    });
  }

  render() {
    const {
      loading,
      rows,
      error,
      confirmDialog,
      delID,
      modalOpen,
      users,
      user,
      availableCredentials,
    } = this.state;
    const { org_id } = this.props.match?.params;
    const columns = [
      { field: "id", headerName: "#", flex: 1 },
      // {
      //   field: "is_active",
      //   headerName: "Active",
      //   flex: 1,
      //   renderCell: (params) => <div>{params.value}</div>,
      // },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
        type: "text",
        // renderCell: (params) => <div>{params.value}</div>,
      },
      { field: "user_name", headerName: "Name", flex: 2 },
      { field: "user_email", headerName: "Email", flex: 2 },
      { field: "citizen_id", headerName: "Citizen ID", flex: 2 },
      {
        field: "credentials",
        headerName: "Credentials",
        flex: 2,
        description: "Roles, assigned to the user",
        renderCell: (params) => (
          <div className="course-list">{params.value}</div>
        ),
      },
      {
        field: "buttons",
        headerName: "Actions",
        flex: 2,
        renderCell: (params) => (
          <div>
            <ButtonGroup className="table-row-buttons">
              <Button
                onClick={() =>
                  this.setState({
                    modalOpen: true,
                    renderModal: true,
                    user: users.find((u) => u.id == params.id),
                  })
                }
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
              >
                Edit
              </Button>
              <Button
                onClick={() =>
                  this.setState({ confirmDialog: true, delID: params.id })
                }
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
              >
                Delete
              </Button>
            </ButtonGroup>
          </div>
        ),
      },
    ];

    if (error) return <Error {...{ error }} />;

    return (
      <div className="page users-list">
        {org_id ? (
          // <Grid container style={{ margin: "0 0 10px 20px", padding: "5px" }}>

          <Breadcrumbs>
            <NavLink to="/organizations">Organizations</NavLink>
            {org_id && (
              <NavLink to={`/organizations/${org_id}`}>
                {org_id}
              </NavLink>
            )}

            {<span>Users</span>}
          </Breadcrumbs>
        ) : (
          <Typography variant="h1" color="primary">
            Users
          </Typography>
        )}
        <>
          <AdminTable
            autoHeight
            searchFields={["user_name", "user_email", "status", "citizen_id"]}
            rows={rows}
            columns={columns}
            pageSize={10}
            buttons={
              <Button
                className="add-user-button"
                onClick={() =>
                  this.setState({
                    modalOpen: true,
                    user: null,
                    renderModal: true,
                  })
                }
                variant="contained"
                startIcon={<GroupAddIcon />}
              >
                Add user
              </Button>
            }
          />
        </>
        {/* <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            // style={{
            //   width: "100%",
            //   display: "flex",
            //   justifyContent: "flex-start",
            // }}
          >
            <Button
              className="add-user-button"
              onClick={() =>
                this.setState({
                  modalOpen: true,
                  id: null,
                  renderModal: true,
                  org_id: this.props.match.params.org_id,
                })
              }
              // style={{
              //   backgroundColor: "green",
              //   color: "white",
              //   marginBottom: "18px",
              //   marginLeft: "20px",
              // }}
              variant="contained"
              startIcon={<GroupAddIcon />}
            >
              Add user
            </Button>
          </Grid>
          <Grid
            item
            style={{
              height: "70vh",
              width: "100%",
            }}
          >
            <DataGrid
              components={{
                LoadingOverlay: this.loadingOverlay,
              }}
              rows={rows}
              columns={columns}
              pageSize={10}
              rowHeight={56}
              //selectionModel={selectedRows}
              disableSelectionOnClick
              loading={loading}
              onRowSelected={(newSelection) =>
                this.rowsSelectionHandler(newSelection)
              }
            />
          </Grid>
        </Grid>
         */}
        <Dialog
          open={confirmDialog}
          onClose={this.closeDialogHandler.bind(this)}
        >
          <DialogTitle style={{ fontWeight: "bold" }}>
            {`Are you sure you want to delete user with id ${delID} ?`}
          </DialogTitle>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.closeDialogHandler()}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => this.deleteUserHandler()}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        {this.state.renderModal ? (
          <ModalUserForm
            onModalOpen={modalOpen}
            onModalClose={this.closeModal.bind(this)}
            onChanges={this.fetchUsers.bind(this)}
            org_id={org_id}
            user={user}
            availableCredentials={availableCredentials.map(
              (item) => item.credential_id
            )}
          />
        ) : null}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onShowMessage: (message, type) => dispatch(showMessage(message, type)),
  };
};

export default connect(null, mapDispatchToProps)(Users);

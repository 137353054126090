import { Button, TextField } from "@material-ui/core";
import React from "react";
import store from "../../../../redux/store";
import {ModalWindow} from "@ses-education/courses-components";
import { showMessage } from "@ses-education/courses-components";

/**
 * Form to edit category data (so far, it's only the title)
 */
class ContentCategoryForm extends React.Component {
  state = {
    category: null,
  };

  componentDidMount() {
    const { category } = this.props;
    this.setState({ category });
  }

  componentDidUpdate(prevProps) {
    if (this.props.category !== prevProps.category)
      this.setState({ category: this.props.category });
  }

  updateField = ({ target }) => {
    try {
      const { name, value } = target;

      let { category } = this.state;
      if (typeof category !== "object") throw new Error("No category to edit");

      // update field within editCategory
      category[name] = value;
      this.setState({ category });
    } catch (e) {
      console.error("updateField error:", e);
      store.dispatch(
        showMessage(
          "Error updating value" + target.name || "[unknown field]",
          "error"
        )
      );
    }
  };

  render() {
    let {
      onClose = console.debug,
      onSave = console.debug,
      namePlaceholder = "Type new category name here",
      saveText = "Save",
      cancelText = "Cancel",
      other,
    } = this.props;

    const { category } = this.state;

    return (
      <ModalWindow
        open={Boolean(category)}
        onClose={onClose}
        onSubmit={() => onSave(category)} // catch all types of form submit
        header={category?.content_cat_id ? "Edit category" : "Add category"}
        {...other}
      >
        <TextField
          id="outlined-title"
          label="Category title"
          variant="outlined"
          // style={{ minWidth: "70%" }}
          fullWidth
          name="title"
          onChange={this.updateField}
          value={category?.title}
          placeholder={namePlaceholder}
          className="m-top-5 m-bottom-5"
        />
        <div className="flex align-self-stretch align-items-center justify-content-space-between">
          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={() => onSave(category)}
          >
            {saveText}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={onClose}
            type="button"
          >
            {cancelText}
          </Button>
        </div>
      </ModalWindow>
    );
  }
}

export default ContentCategoryForm;

import React from "react";
import {
  // Grid,
  Button,
  Grid,
  // ButtonBase,
  // ButtonGroup,
  // CircularProgress,
  // Dialog,
  // Menu,
  // MenuItem,
  // Select,
  Tab,
  Tabs,
  // Checkbox,
  // Typography,
  // Modal,
  TextField,
  // Switch,
} from "@material-ui/core";
// import { Add } from "@material-ui/icons";
import { connect } from "react-redux";
import TransferList from "../../template/transferList/programsList";
// import { showMessage } from "@ses-education/courses-components";
import ProgramService from "../../../services/programs";
import OrganizationService from "../../../services/organizations";
import {ModalWindow, ImageSelector, AsyncButton, Spinner, showMessage} from "@ses-education/courses-components";
import "./modal-program-form.scss";
import TranslationsService from "../../../services/translations-service";
import TranslationSelect from "../translation-select";
// import Spinner from "../spinner";
// import AsyncButton from "../../template/AsyncButton";
import TranslationDesk from "../../pages/translations-component/translation-desk";

// const AddTranslationDialog = ({ onAdd, languages, translationMenuTarget, onClose }) => {

//   console.debug("AddTranslationDialog translationMenuTarget:", translationMenuTarget)
//   return Array.isArray(languages) ? (
//     <Menu anchorEl={translationMenuTarget} open={Boolean(translationMenuTarget)} {...{onClose}}>
//       {languages.map((l) => (
//         <MenuItem key={l["iso639-1"]} value={l["iso639-1"]} onClick={() => onAdd(l["iso639-1"])}>
//           {l["local_name"]}
//         </MenuItem>
//       ))}
//     </Menu>
//   ) : <span>no languages</span>;
// };

const  TabPanel = props => {
  const { children, value, index, tabname, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${tabname}-panel-${index}`}
      aria-labelledby={`${tabname}-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </div>
  );
}

const defaultImage = `${process.env.PUBLIC_URL}/static/images/course-no-image.png`;

class ModalProgramForm extends React.Component {
  state = {
    title: "",
    description: "",
    chosenCourses: [],
    currentProgram: null,
    programs: [],
    translation: "en",
    showAddTranslation: false,
    translationMenuTarget: null,
    languages: null,
    someValue: 0,
    selectedTab: 0,
  };

  async componentDidMount() {
    await this.fetchPrograms();
    // edit mode
    if (this.props.id) this.populateState();
    // console.log(
    //   "ModalProgramForm - componentDidMount - this.props: ",
    //   this.props
    // );
    // console.log(
    //   "ModalProgramForm - componentDidMount - this.state: ",
    //   this.state
    // );
  }

  // shouldComponentUpdate(prevProps, prevState) {
  //   const { title, description } = this.state;
  //   const { onModalOpen, id, org_id } = this.props;
  //   if (
  //     prevProps.onModalOpen !== onModalOpen ||
  //     prevProps.id !== id ||
  //     prevProps.org_id !== org_id
  //   ) {
  //     return true;
  //   }

  //   if (prevState.title !== title || prevState.description !== description) {
  //     return true;
  //   }
  //   return false;
  // }

  async fetchPrograms() {
    try {
      const languages = await TranslationsService.getLanguages();

      const programs = await ProgramService.getPrograms();
      if (!programs) {
        this.setState({ error: "Failed loading programs" });
        return;
      }

      //when in edit mode
      if (this.props.id) {
        console.debug("fetchPrograms id:", this.props.id)
        const currentProgram = programs.find(
          (program) => program.program_id === parseInt(this.props.id)
        );
        console.debug("fetchPrograms currentProgram:", currentProgram)

        this.setState({
          currentProgram,
          programs,
          languages,
        });
        return;
      }

      this.setState({ programs, currentProgram: null, languages });
    } catch (err) {
      this.props.onShowMessage(
        `Fetching programs from server failed.`,
        "error"
      );
    }
  }

  populateState() {
    const { currentProgram, translation } = this.state;
    if (currentProgram && currentProgram.translations && translation) {
      const { translations } = currentProgram;
      const currentTranslation = Array.isArray(translations)
        ? translations.find((t) => t.language_id === translation)
        : null;

      if (!currentTranslation) {
        this.setState({
          title: null,
          description: null,
          chosenCourses: null,
        });
      }

      this.setState({
        title: currentTranslation.title,
        description: currentTranslation.description,
        chosenCourses: currentProgram.course_ids,
      });
      // this.setState({
      //   title: currentProgram.title,
      //   description: currentProgram.description,
      //   chosenCourses: currentProgram.course_ids,
      // });
    }
  }

  closeModal() {
    this.setState({
      currentProgram: null,
      title: "",
      description: "",
      chosenCourses: [],
    });

    this.props.onModalClose();
  }

  chosenCoursesHandler(chosenCourses) {
    this.setState({ chosenCourses });
  }

  submitProgramHandler = async () => {
    const {
      title,
      description,
      chosenCourses: course_ids,
      currentProgram,
      translation,
    } = this.state;
    const { org_id } = this.props;

    if (title === "") {
      this.props.onShowMessage(
        `Please insert program title before submiting.`,
        "warning"
      );
      return;
    }

    const data = { title, description, course_ids, translation };

    if (currentProgram) {
      const updateResult = await ProgramService.updateProgram(
        currentProgram.program_id,
        data
      );
      if (updateResult) {
        // send update back to users page
        await this.props.onChanges();
        this.props.onShowMessage(
          `Program "${title}" was updated successfuly`,
          "success"
        );
      }
      // fetch programs
      this.fetchPrograms();

    } else {
      const addResult = org_id
        ? // adds new program and assigns to organization with given org_id
          await OrganizationService.updateOrganizationProgram(org_id, data)
        : // only adds new program to general list of programs
          await ProgramService.addProgram(data);
      if (addResult) {
        // send update back to users page
        await this.props.onChanges( addResult );
        this.props.onShowMessage(
          org_id
            ? `Program "${title}" added and assigned to organization with ID #${org_id}`
            : `Program "${title}" added successfuly`,
          "success"
        );
        // update current program
      }
    }
  };

  addTranslation = async (lang) => {
    // close menu
    this.setState({ translationMenuTarget: null });

    const { onShowMessage } = this.props;
    if (
      !(await ProgramService.addTranslation(
        this.state.currentProgram.program_id,
        lang
      ))
    ) {
      onShowMessage(ProgramService.error, "error");
      return;
    }
    // renew data
    this.fetchPrograms();
  };

  selectTranslation = (translation) =>
    this.setState({ translation }, () => this.populateState());

  onDeleteImage = async () => {
    const { onShowMessage, id,currentProgram } = this.props;

    if (!id) {
      onShowMessage("program not exist", "error");
      return false;
    }

    if (!(await ProgramService.deleteImage(id))) {
      onShowMessage(ProgramService.error || "Unknown error", "error");
      return false;
    }
    onShowMessage("Image successfully deleted");
    await this.props.onChanges();
    await this.fetchPrograms();
    return true;
  }

  onSelectImage = async ({ target }) => {
    const { onShowMessage, id } = this.props;

    if (!id) {
      onShowMessage("Course not saved", "error");
      return false;
    }

    const image = target.files && target.files[0] ? target.files[0] : null;

    if (!image) {
      onShowMessage("No file selected", "error");
      return false;
    }

    // build form data with file
    const data = new FormData();
    data.append("image", image);

    // console.debug("uploading data", data);
    if (!(await ProgramService.uploadImage(id, data))) {
      onShowMessage(ProgramService.error || "Unknown error", "error");
      return false;
    }

    onShowMessage("Image successfully updated");
    await this.props.onChanges();
    await this.fetchPrograms();
    return true;
  }

  render() {
    const { onModalOpen, id, org_id, courses,mode, ...other } = this.props;
    const {
      title,
      description,
      currentProgram,
      languages,
      // translationMenuTarget,
      translation,
      selectedTab,
    } = this.state;
    const { translations, images } = currentProgram || {};
    const image = Array.isArray(images) && images.length > 0 && images[0] && `${process.env.REACT_APP_MEDIA_URL}${images[0]}`;
    console.log("ModalProgramForm currentProgram",currentProgram)
    console.debug("ModalProgramForm id" , id)
    let header="";
    if (mode==="translation" && translation==="en"){header = "select a translation"}
    else {header = id ? "Edit program" : "Add program"}
    return (
      <>
        <ModalWindow
          open={onModalOpen}
          onClose={() => this.closeModal()}
          {...{header}}
          {...other}
          className="programs-assign-dialog"
        >
          <div className="modal-container">
            { id && !currentProgram ? <Spinner/> : (
              <>
                {mode==="translation"&&currentProgram && currentProgram.program_id && (translation === "en")  && (
                  <div className="centered">
                    <TranslationSelect
                      {...{
                        translations,
                        onAdd: this.addTranslation,
                        onSelect: this.selectTranslation,
                        languages,
                        translation,
                      }}
                      // className={"translation-selector-center"}
                    />
                  </div>
                )}
                {mode==="translation"&&(translation != "en") &&(
                  <>
                    <div className="modal-data">
                      <TranslationDesk
                        title="Program title"
                        type="input"
                        originalValue={currentProgram.title}
                        value={title || ''}
                        onUpdate={(value) => this.setState({title:value})}
                        originalLanguage="en"
                        translationLanguage={translation}
                        fullWidth
                      />
                      <TranslationDesk
                        title="Program Description"
                        type="input"
                        originalValue={currentProgram.description}
                        value={description || ''}
                        onUpdate={(value) => this.setState({description:value})}
                        originalLanguage="en"
                        translationLanguage={translation}
                        fullWidth
                      />
                    </div>
                  </>
                )}
                {mode==="edit"&&(
                  <>
                    <Tabs 
                      value={selectedTab} 
                      onChange={(event, newValue)=>{
                        this.setState({selectedTab:newValue});
                      }} 
                      aria-label="program tabs"
                    >
                      <Tab label="Properties" index={0}/>
                      <Tab label="Courses" index={1}/>
                    </Tabs>
                    <TabPanel tabname={"Properties"} value={selectedTab} index={0}>
                      <div className="modal-data">
                        <Grid container spacing={3}>
                          <Grid item md={4} xs={12}>
                          <ImageSelector
                          {...{ image, defaultImage }}
                          onSelect={this.onSelectImage}
                          onDelete={this.onDeleteImage}
                          confirmationText="Delete program image?"
                        />
                          </Grid>
                          <Grid item md={8} xs={12}>
                          <TextField
                          id="outlined-title"
                          label="Program Title"
                          variant="outlined"
                          fullWidth
                          style={{ minWidth: "70%" }}
                          onChange={(event) =>
                            this.setState({ title: event.target.value })
                          }
                          value={title}
                        />
                        <TextField
                          id="outlined-description"
                          label="Program Description"
                          variant="outlined"
                          fullWidth
                          style={{ minWidth: "70%", marginTop: "7px" }}
                          onChange={(event) =>
                            this.setState({ description: event.target.value })
                          }
                          value={description}
                        />
                          </Grid>
                        </Grid>
                       
                        
                      </div>
                    </TabPanel>
                    <TabPanel tabname={"Courses"} value={selectedTab} index={1}>
                      <div className="modal-transfer">
                        <TransferList
                          courses={courses}
                          chosenCourses={this.chosenCoursesHandler.bind(this)}
                          programForUpdate={currentProgram}
                        />
                      </div>
                    </TabPanel>
                    <div className="flex row justify-content-space-between m-top-6">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() =>{ 
                        if (mode==="translation"){
                        this.setState({translation: "en"})
                        }else if(mode==="edit"){
                          this.closeModal();
                        }
                      }}
                    >
                      Close
                    </Button>
                    <AsyncButton
                      onClick={this.submitProgramHandler.bind(this)}
                      variant="contained"
                      color="primary"
                      style={{ marginLeft: "7px" }}
                    >
                      {id ? "Update" : "Add"}
                    </AsyncButton>
                  </div>
                  </>
                )}
                {(mode==="translation"&&translation!="en")&&courses && 
                  <div className="modal-transfer">
                    <TransferList
                      courses={courses}
                      chosenCourses={this.chosenCoursesHandler.bind(this)}
                      programForUpdate={currentProgram}
                    />
                 </div>
                }
                {(mode==="translation"&&translation!="en")&&
                  <div className="modal-buttons align-self-stretch">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() =>{ 
                        if (mode==="translation"){
                        this.setState({translation: "en"})
                        }else if(mode==="edit"){
                          this.closeModal();
                        }
                      }}
                    >
                      Close
                    </Button>
                    <AsyncButton
                      onClick={this.submitProgramHandler.bind(this)}
                      variant="contained"
                      color="primary"
                      style={{ marginLeft: "7px" }}
                    >
                      {id ? "Update" : "Add"}
                    </AsyncButton>
                  </div>
                }
              </>
            )}
          </div>
        </ModalWindow>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onShowMessage: (message, type) => dispatch(showMessage(message, type)),
  };
};
export default connect(null, mapDispatchToProps)(ModalProgramForm);

import React from 'react'
import Spinner from '../../template/spinner';
import Editor from '@ses-education/content-editor';

class TranslationsInterfaceEditHtml  extends React.Component{


    render(){
        const { translation, updateInteraceTranslation } = this.props;
        console.log("TranslationsInterfaceEditHtml",this.props.translation);
        return(
            <>
                {!translation && <Spinner/>}
                {translation && translation.type === 'document' && ("Work in progress")}
                {translation && translation.type === 'html' && (
                    <Editor
                        content={translation.content || translation.original} 
                        onSave={(value)=>{
                            updateInteraceTranslation({
                                id:translation.translation_id,
                                value
                            });
                        }}
                    />
                )}
            </>
        );
    }
}

export default TranslationsInterfaceEditHtml 
import { Grid, TextField } from '@material-ui/core';
import {ImageSelector} from '@ses-education/courses-components';
import {debounce} from '@ses-education/content-parser';
import React from 'react';



const defaultImage = `${process.env.PUBLIC_URL}/static/images/course-no-image.png`;
const debounceUpdateTime = 500; // ms to debounce program update in HOC component

class ProgramFormProperties extends React.Component{

    state = {
        program_id: null,
        title: '',
        description: '',
        images: []
    }

    componentDidMount(){
        const {onChange} = this.props;

        this.debouncedUpdate = debounce( () => {
            if( typeof onChange === "function" ) onChange( this.state );
        }, debounceUpdateTime);

        this.populateForm();
    }

    componentDidUpdate( prevProps ){
        // console.debug("componentDidUpdate", prevProps.program, this.props);
        // only repopulate if program id has changed
        if( prevProps?.program?.program_id !== this.props?.program?.program_id ){
            this.populateForm();
        }
    }

    changeValue = ({target}) => {
        const {name, value} = target;
        
        // do nothing if name is wrong
        if( !["title", "description"].includes(name)) return;
        
        console.debug(name, value);
        this.setState({[name]: value}, this.debouncedUpdate())

    }

    populateForm() {
        // console.debug("populateForm", this.props.program);
        const { program_id, title = '', description = '', images = [] } = this.props?.program || {};
        this.setState({ program_id, title, description, images });
    }

    render(){
        const {program_id, title, description, images} = this.state;
        const isNew = !program_id;
        // console.debug("program id:", program_id);
        // console.debug("isNew:", isNew);

        const {onSelectImage, onDeleteImage} = this.props;

        const image = Array.isArray(images) && images.length > 0 ? images[0] : null;

        return (

            <Grid container spacing={3}>
                { !isNew &&
                <Grid item md={4} xs={12} className='flex' key={`image-selector-grid-item-${isNew}`}>
                    <ImageSelector
                    {...{ image, defaultImage }}
                    onSelect={onSelectImage}
                    onDelete={onDeleteImage}
                    confirmationText="Delete program image?"
                    disabled={isNew}
                    />
                </Grid>
                }
                {/* if the program is new, we don't show image selector, so the fields are always 100% wide */}
                <Grid item md={ isNew ? 12 : 8} xs={12} key={`properties-grid-item-${isNew}`} >
                <TextField
                id="outlined-title"
                label="Program Title"
                variant="outlined"
                fullWidth
                name="title"
                // style={{ minWidth: "70%" }}
                // onChange={(event) =>
                //   this.setState({ title: event.target.value })
                // }
                onChange={this.changeValue}
                value={title}
                />
                <TextField
                    id="outlined-description"
                    label="Program Description"
                    variant="outlined"
                    fullWidth
                    className='m-top-6'
                    // style={{ minWidth: "70%", marginTop: "7px" }}
                    name="description"
                    // onChange={(event) =>
                    //   this.setState({ description: event.target.value })
                    // }
                    onChange={this.changeValue}
                    value={description}
                />
                </Grid>
            </Grid>
        )
    }
}

export default ProgramFormProperties;
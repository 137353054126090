// COMMON UTILITY FUNCTIONS to use within tinymce plugins.


/**
 * Returns config[index] or falls back to _default if config[index] is empty.
 * Helps to populate initial data without errors. 
 * @param {*} index 
 * @param {*} _default default value. Empty string by default.
 * @returns 
 */
 export const valueOf = (index, config, _default = '' ) => config && config[index] ? config[index] : _default;


 
import React from 'react'
import LanguagesService from '../../../../services/languages-service'
import Actions from '../../../../redux/actions';
import {Home, Translate, Language, Edit, Add, Check } from '@material-ui/icons';
import {AutoBreadcrumbs, AdminTable} from "@ses-education/courses-components"
import {Typography, Switch, Button} from '@material-ui/core';
import Spinner from '../../../template/spinner';
import LanguageForm from './language-form';


const defaultLanguage = {
    "iso639-1": null,
    "english_name": null,
    "english_short": null,
    "local_name": null,
    "local_short": null,
    direction: "ltr",
    active: 0
}


class TranslationLanguages extends React.Component{

    state = {
        languages: null,
        iso_codes: null,
        editingLanguage: null
    }

    componentDidMount(){
        this.fetchData();
    }

    async fetchData(){
        let languages = await LanguagesService.getAll();
        let {iso_codes} = this.state;

        if( !iso_codes){
            iso_codes = await LanguagesService.getIso();
        }
        
        if( !languages ){
            return Actions.showMessage(LanguagesService.error || "Unknown error");
        }

        languages = languages.map( l =>({...l, id: l["iso639-1"]}));
        this.setState({languages, iso_codes});
    }


    addLanguage = async (lang) => {
        try{
            await LanguagesService.add(lang);
            this.fetchData();
            // close edit form
            this.setState({editingLanguage: null})
            return true;
        }catch(e){
            Actions.showMessage(e.message || "Unknown error", "error")
        }
    }

    updateLanguage = async (lang) => {
        try{
            await LanguagesService.update(lang);
            this.fetchData();
            // close edit form
            this.setState({editingLanguage: null})
        }catch(e){
            Actions.showMessage(e.message || "Unknown error", "error")
        }
    }

    deleteLanguage = async (id) => {
        try{
            await LanguagesService.delete(id);
            // renew language list
            this.fetchData();
            // close edit form
            this.setState({editingLanguage: null})
        }catch(e){
            Actions.showMessage(e.message || "Unknown error", "error")
        }
    }


    render(){

        let items = [
            { icon: <Home />, to: "/" },
            { icon: <Translate />, to: "/translations", text: "Translations" }, 
            { icon: <Language/>, to: "/translations/languages", text: "Languages" }, 
          ];

        const {languages, editingLanguage, iso_codes} = this.state;
          console.debug("editing: ", editingLanguage)

        const columns = [
            {
                field: "iso639-1",
                flex: 1,
                headerName: "iso639-1"
            },
            {
                field: "english_short",
                flex: 1,
                headerName: "3-letter english abbrev."
            },
            {
                field: "english_name",
                flex: 1,
                headerName: "English title"
            },
            {
                field: "local_name",
                flex: 1,
                headerName: "Local title"
            },
            {
                field: "active",
                flex: 1,
                headerName: "Active",
                renderCell: (params) => {
                    // don't show for English
                    if( params.row['iso639-1'] === "en") return <Check htmlColor='#ccc'/>
                    return params.value ? <Check color="primary"/> : <></>
                }
            },
            {
                field: "actions",
                flex: 1,
                headerName: "Actions",
                renderCell: (params) => {
                    // don't show button for English
                    if( params.row['iso639-1'] === "en") return <></>
        
                    return (
                        <Button onClick={() => this.setState({editingLanguage: params.row }) }><Edit color="primary"/></Button>
                    )
                }
            },
            
        ]

        return(
            <div className="page">
                <AutoBreadcrumbs {...{items}} />
                <Typography variant='h1'>Languages</Typography>

                {!languages && <Spinner/> }
                { languages && 
                <AdminTable 
                    rows={languages}
                    columns={columns}
                    autoHeight

                    buttons={
                        <>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                size="small" 
                                onClick={()=> this.setState({editingLanguage: defaultLanguage})}>
                                    <Add/>Add language
                            </Button>
                        </>
                    }

                    searchFields={[
                        "iso639-1", "english_name", "english_short"
                    ]}
                />
                }

                <LanguageForm 
                    open={Boolean(editingLanguage)} 
                    language={editingLanguage} 
                    iso_codes={iso_codes} 
                    onClose={()=> this.setState({editingLanguage: null})}
                    onSave={ (data) => { 
                        // if id is set - we're editing an existing language hence we update
                        editingLanguage && editingLanguage["iso639-1"] ? 
                        this.updateLanguage(data) : 
                        // no id set - we add a new language
                        this.addLanguage(data) }}
                    onDelete={this.deleteLanguage}
                />
            </div>
        )
    }
}

export default  TranslationLanguages;
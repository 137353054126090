/**
 * returns array of all special characters
 */
export function charMap() {
  const characters = [
    {
      text: 'Ω',
      value: 'omega',
      icon: 'Ω',
    },
    {
      text: 'Ψ',
      value: 'psy',
      icon: 'Ψ',
    },
    {
      text: 'Φ',
      value: 'phy',
      icon: 'Φ',
    },
    {
      text: 'Σ',
      value: 'sigma',
      icon: 'Σ',
    },
    {
      text: 'Θ',
      value: 'theta',
      icon: 'Θ',
    },
    {
      text: 'μ',
      value: 'mu',
      icon: 'μ',
    },
    {
      text: 'ρ',
      value: 'rho',
      icon: 'ρ',
    },
    {
      text: '•',
      value: 'dot',
      icon: '•',
    },
    {
      text: 'π',
      value: 'pi',
      icon: 'π',
    },
  ];

  return characters;
}

/**
 * receives string representation of special character ('sigma')
 * copies that char to clipboard ('Σ')
 */
export function copyToClipboard(char) {
  let el = document.createElement('textarea');

  switch (char) {
    case 'omega':
      el.value = 'Ω';
      break;
    case 'psy':
      el.value = 'Ψ';
      break;
    case 'phy':
      el.value = 'Φ';
      break;
    case 'sigma':
      el.value = 'Σ';
      break;
    case 'theta':
      el.value = 'Θ';
      break;
    case 'mu':
      el.value = 'μ';
      break;
    case 'rho':
      el.value = 'ρ';
      break;
    case 'dot':
      el.value = '•';
      break;
    case 'pi':
      el.value = 'π';
      break;
    default:
      console.debug('Wrong special char sent to charMap');
      break;
  }
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

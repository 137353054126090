import { Button } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";
import { Link } from "react-router-dom";
import CourseService from "../../../../services/course";
import {AdminTable, Spinner} from "@ses-education/courses-components";
import Error from "../../../template/Error";
// import Spinner from "../../../template/spinner";
//import experiments from "../../courses/experiments";
import TranslationsCoursesItemExperiment from "./translations-courses-item-experiment";

class TranslationsCoursesItemExperiments extends React.Component {
  state = {
    experiments: null,
    error: null,
    currentExperiment: null,
  };

  componentDidMount() {
    this.fetchExperiments();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.course_id !== this.props.course_id
    ) this.fetchExperiments();

    if( prevProps.experiment_id !== this.props.experiment_id  ){
      const currentExperiment = this.selectExperiment( this.state.experiments || [] )
      this.setState({currentExperiment});
    }
  }

  fetchExperiments = async () => {
    const { course_id, experiment_id } = this.props;

    const experiments = await CourseService.getCourseExperiments(course_id);
    const error = Array.isArray(experiments)
      ? null
      : CourseService.error || "Unknown experiments data error";

    const currentExperiment = error || !experiment_id ? null : this.selectExperiment(experiments)
    

    this.setState({ experiments, error, currentExperiment });
  };

  selectExperiment = (experiments) => {
    return experiments.find((e) => e.course_experiment_id === parseInt(this.props.experiment_id) );
  }

  shouldIClose = () => {
    // TODO: check editing state and confirm closing/saving
    this.props.onClose();
  };

  render() {
    const { course_id, experiment_id, page, question_id, currentCourse, error, onClose } = this.props;
    const { experiments, currentExperiment } = this.state;
    const columns = [
      { field: "id", flex: 0, headerName: "ID" },
      {
        field: "title",
        flex: 1,
        headerName: "Title",
        renderCell: (params) => (
          <Link to={`/translations/courses/${course_id}/experiments/${params.row.id}`}>
            {params.row.title}
          </Link>
        ),
      },
      {
        field: "translations",
        flex: 1,
        headerName: "Translations",
        renderCell: (row) => (
          <>
            {Array.isArray(row.value)
              ? row.value.join(", ")
              : "Data not available"}
          </>
        ),
      },
    ];

    
    return (
      <div className="item-course-experiments">
        <div className="modal-window full-screen">
          {!experiments && !error && <Spinner/> }
          {experiments && !error && !experiment_id && (
            <>
              <h2>{currentCourse.course_code}: {currentCourse.title} :: Experiments</h2>
              <Button className="close-button" onClick={this.shouldIClose}>
                <Close color="secondary" />
              </Button>
              <AdminTable
                autoHeight
                pageSize={6}
                rows={experiments.map((e) => ({
                  ...e,
                  id: e.course_experiment_id,
                }))}
                {...{ columns }}
              />
            </>
          )}
          {currentExperiment && (
            <TranslationsCoursesItemExperiment
              {...{
                course_id,
                experiment_id,
                currentExperiment,
                page,
                question_id
              }}
              onClose={() =>
                this.setState({
                  redirect: `/translations/courses/${course_id}/experiments`,
                })
              }
            />
          )}
          {error && <Error error={error} />}
        </div>
      </div>
    );
  }
}

export default TranslationsCoursesItemExperiments;

import React from 'react';
import AuthWrap from '../auth';
import { BrowserRouter as Router } from 'react-router-dom';
import Footer from '../template/footer';
import Navbar from '../template/navbar';
import Routes from '../routes';
import routes from '../../data/routes';
import Sidebar from '../template/sidebar';
import { connect } from 'react-redux';
import A from '../../redux/actionTypes';
import Actions from '../../redux/actions';
// import Notification from '../template/Notification';
import './main.scss';

let usageDefer = Date.now();
const deferUsageDelay = 1000; // ms

class Main extends React.Component {
  componentDidMount() {
    // monitor interface usage
    document.getElementById('root').addEventListener('click', () => {
      console.debug('UI interacted with');
      // defer consecutive interactions
      clearTimeout(usageDefer);
      usageDefer = setTimeout(() => this.props.setUsageTime(), deferUsageDelay);
    });

    // this is the first run (or a reloaded page)
    // so let's check if the token has to be renewed
    console.debug(
      'Main did mount, calling onTokenUpdate with ',
      this.props.tokenTimestamp
    );
    Actions.onTokenUpdate(this.props.tokenTimestamp);
  }

  render() {
    const { location, showDrawer } = this.props;
    return (
      <Router>
        <Navbar location={location} />
        <Sidebar />
        <div className={`main ${showDrawer ? 'drawer-open' : ''}`}>
          <Routes routes={routes} />
        </div>
        {/* <ContactForm /> */}
        {/* <ReduxModal/> */}
        {/* <Notification /> */}
        <Footer />
      </Router>
    );
  }
}

export default AuthWrap(
  connect(
    (state) => ({
      showDrawer: state.showDrawer,
      tokenTimestamp: state.tokenTimestamp,
    }),
    (dispatch) => ({
      setUsageTime: () => dispatch({ type: A.SET_USAGE_TIMESTAMP }),
    })
  )(Main)
);

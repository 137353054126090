import {
  Button,
  //   ButtonGroup,
  IconButton,
  List,
  ListItem,
  //   TextField,
} from "@material-ui/core";
import { Add, ChevronLeft } from "@material-ui/icons";
import React from "react";
import { NavLink } from "react-router-dom";
// import store from "../../../../redux/store";
// import { showMessage } from "@ses-education/courses-components/notifications/notificationActions";
import Spinner from "../../../template/spinner";
// import ContentCategoryForm from "./content-category-form";

const defaultCategory = {
  title: "",
  parent_id: null,
  course_id: null,
  config: {},
};

class ContentCategoryList extends React.Component {
  state = {
    editCategory: null,
  };

  saveForm = async (category) => {
    console.debug("Saving category:", category);
  };

  render() {
    let {
      categories,
      currentCategory,
      baseUrl = "",
      showEditCategory = console.debug,
      other,
    } = this.props;
    // const { editCategory } = this.state;

    const currentPath = currentCategory?.path?.length
      ? currentCategory.path.map((p) => p.content_cat_id)
      : []; // this should be an array

    const back_category_slug = currentPath.length
      ? currentPath.slice(0, currentPath.length - 1).join("|")
      : "";

    console.debug(
      "back_category_slug",
      back_category_slug,
      "currentPath",
      currentPath
    );

    const categoriesIsArray = Array.isArray(categories);
    return (
      <div {...other}>
        {/* categories side bar */}
        <List variant="persistent" anchor="left" className="categories-list">
          <h3>Categories</h3>
          {!categoriesIsArray && <Spinner />}

          {currentCategory && (
            // back button
            <li className="m-bottom-5">
              <IconButton
                color="primary"
                variant=""
                aria-label="back"
                to={`${baseUrl}${
                  back_category_slug ? `/${back_category_slug}` : ""
                  // if category_slug is set and it includes | separator...
                  //   category_slug && category_slug.includes("|")
                  //     ? // add slash and remove the last of the separated elements
                  //       `/` +
                  //       category_slug
                  //         .split("|")
                  //         .slice(0, category_slug.split("|").length - 1)
                  //         .join("|")
                  //     : // otherwise - add nothing
                  //       ""
                }`}
                component={NavLink}
              >
                <ChevronLeft />
              </IconButton>
            </li>
          )}
          {(!categoriesIsArray ||
            (categoriesIsArray && categories.length === 0)) && (
            <li>No categories</li>
          )}
          {categoriesIsArray &&
            categories.length > 0 &&
            categories
              // .filter((item) => item.parent_id === null)
              .filter(
                (item) =>
                  // show all categories within current category
                  item.parent_id ===
                  (currentCategory ? currentCategory.content_cat_id : null)
              )
              .map((item) => {
                const { content_cat_id, title } = item;

                const slug = `${baseUrl}/${
                  currentCategory
                    ? // add item's id to category path
                      `${currentCategory.path
                        .map((p) => p.content_cat_id)
                        .join("|")}|${content_cat_id}`
                    : content_cat_id
                }`;

                console.debug(
                  "currentCategory path (slug):",
                  currentCategory ? currentCategory.path : null
                );
                console.debug("slug:", slug);

                return (
                  <li>
                    <ListItem
                      className="drawer-menu-item"
                      button
                      key={title}
                      component={NavLink}
                      to={slug}
                    >
                      {title}{" "}
                    </ListItem>
                  </li>
                );
              })}
          {
            // only show Add button within a category, not in root
            currentCategory && (
              <li className="m-top-8">
                <Button
                  variant="contained"
                  // color="primary"
                  size="small"
                  onClick={() =>
                    // this.setState({ editCategory: { ...defaultCategory } })
                    showEditCategory({
                      ...defaultCategory,
                      parent_id: currentCategory.content_cat_id,
                    })
                  }
                  className="grow-1 bg-green"
                >
                  <Add /> Add category
                </Button>
              </li>
            )
          }
        </List>
      </div>
    );
  }
}

export default ContentCategoryList;

import React from 'react'
import { connect } from 'react-redux'; 
import {Notifications, hideMessage, clearAllMessages} from '@ses-education/courses-components';

class Notification extends React.Component{

    state = {
        messages: null
    }

    render(){
        const {messages, onCloseMessage, onCloseAll} = this.props;
        // const {messages} = this.state;.
        // const {messages} = []
        return(            
            <>
                <Notifications 
                    {
                        ...{
                            messages, onCloseMessage, onCloseAll
                        }
                    }
                    config={{
                        topToBottom: true                    
                    }}
                />
            </>
        )
    }
}


const mapStateToProps = (state) => {
    console.debug("global state:", state);
    return {
      messages: state.messages,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      onCloseMessage: (ind) => dispatch(hideMessage(ind)),
      onCloseAll: () => dispatch( clearAllMessages() )
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(Notification);
  
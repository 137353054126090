import { Button, CircularProgress, MenuItem, Select, TextField } from "@material-ui/core";
import React, {useState} from "react";


const AsyncButton = props => {

    const {onClick, children, ...other} = props

    let [ loading, setLoading ] = useState(false)

    const handleClick = async (ev) => {
        if( typeof onClick !== "function" ) return false;

        setLoading(true);
        const result = await onClick(ev);        
        setLoading(false)
        return result;
    }

    return(
        <Button onClick={handleClick} {...other}>{loading && <CircularProgress style={{maxHeight: 12}}/>}{children}</Button>
    )
}

class AddCourseForm extends React.Component {
  state = {
    course_code: "",
    title: "",
    course_type: 1,
  };

  onChange = ({ target }) => {
    const { name, value } = target;
    if (!["course_code", "title", "course_type"].includes(name)) {
      console.error("Wrong field name!!");
      return;
    }

    this.setState({ [name]: value });
  };

  onSubmit = async (ev) => {
    console.debug("onSubmit", ev );
    
    ev.preventDefault();
    ev.stopPropagation();

    console.debug("submit", this.state);
    const { onSubmit } = this.props;
    if (typeof onSubmit !== "function") {
      console.error("add course form: onSumbit function not set");
      return;
    }

    // attempt to submit and wait for a truthy answer
    if( ! await onSubmit(this.state) ){
        return false;
    }

    if( typeof this.props.onClose === "function" )
        this.props.onClose();
  };

  render() {
    const { onClose = () => console.debug("Closing"), types } = this.props;
    const { course_code, title, course_type } = this.state;

    return (
      <form>
        <div className="flex column m-bottom-10">
          <TextField
            name="course_code"
            value={course_code}
            onChange={this.onChange}
            label="Code"
          />
          <TextField
            name="title"
            value={title}
            label="Title"
            onChange={this.onChange}
          />
          <Select
            label="Types"
            onChange={this.onChange}
            value={course_type}
            name='course_type'
          >
          {Array.isArray(types) && types.map(type => {
            return (
              <MenuItem value={type.course_type_id}>{type.title}</MenuItem>
            )
          })}
        </Select>
        </div>
        <div className="flex row justify-content-space-between">
          <AsyncButton color="primary" type="button" variant="contained" onClick={this.onSubmit}>
            Add
          </AsyncButton>
          <Button color="secondary" variant="contained" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </form>
    );
  }
}

export default AddCourseForm;

import React from 'react'


class CourseTypesTypeConfig extends React.Component{

    render(){
        const {config} = this.props;
        return(JSON.stringify(config))
    }
}

export default CourseTypesTypeConfig
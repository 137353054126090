import { IconButton, Tab, Tabs } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Delete, Edit } from "@material-ui/icons";
import React from "react";
import { Link } from "react-router-dom";
import ConfirmDialog from "../../../template/ConfirmDialog";
import Spinner from "../../../template/spinner";
import Configuration from "./configuration";
import ContentCategoriesPages from "./content-categories-pages";
import ContentCategoryForm from "./content-category-form";
import ContentCategoryList from "./content-category-list";

/**
 * Constants to define category tabs
 */
const tabid = "cat-tab-";
const tabpanelid = "cat-tab-panel-";

const TabPanel = (props) => {
  const { id, children, value = 0, className } = props;

  return (
    <div
      role="tabpanel"
      aria-labelledby={`${tabid}${id}`}
      id={`${tabpanelid}${id}`}
      hidden={value !== id}
      className={value === id && className}
    >
      {children}
    </div>
  );
};

const TabComponent = ({ children, count, ...other }) => (
  <Link {...other}>
    {children}
    {count > 0 && <span className="badge">{count}</span>}
  </Link>
);

const category_tab_data = [
  //   { id: "categories", title: "Categories", count: 0 },
  { id: "pages", title: "Pages", count: 0 },
  { id: "configure", title: "Configure" },
];

/**
 * This class renders the complete interface for content editing.
 */
class ContentCategories extends React.Component {
  state = {
    editCategory: null,
    confirmHeader: null,
    confirmPrompt: null,
    onConfirm: null,
    beforeClose: null, // optional callback to call before closing the dialog
  };

  /**
   * This shows a confirm dialog by providing header, prompt and callback.
   * To close the dialog from the callback, use showConfirmDialog(null, null, null);
   * @param {*} confirmHeader
   * @param {*} confirmPrompt
   * @param {*} onConfirm
   * @param {function|undefined} beforeClose - optional callback to call before closing the dialog. Does not prevent it from closing but allows to do additional operations.
   */
  showConfirmDialog = (
    confirmHeader,
    confirmPrompt,
    onConfirm,
    beforeClose
  ) => {
    this.setState({ confirmHeader, confirmPrompt, onConfirm, beforeClose });
  };

  /**
   * makes a call to API to delete content element with given ID
   * @param {*} id
   */
  deleteContentElement = async (id) => {
    console.debug("Deleting content element", id);
  };

  saveCategory = (category) => {
    const { addCategory, updateCategory } = this.props;
    // if there's a category id in data - update, otherwise - add
    category.content_cat_id ? updateCategory(category) : addCategory(category);
  };

  render() {
    const {
      /**
       * object with current category's data
       */
      currentCategory,
      /**
       * Array of all available categories
       */
      categories,

      /**
       * Base URL for every link within the component
       */
      baseUrl,
      category_id,
      cat_tab,
      page_id,
      saveConfig = console.debug, // save config value

      // addCategory = console.debug, // addCategory(categoryData) - creates new category within the current one
      // updateCategory = console.debug, // updateCategory(categoryData) - updates current category
      deleteCategory = console.debug, // deletes current category

      updateContentElement = console.debug, // updateContentElement(element_id, data)
      deleteContentElement = console.debug, // deleteContentElement(element_id)

      addPage = console.debug,
      deletePage = console.debug,

      onUpdate = () => console.debug("Categories.onUpdate is called"),
    } = this.props;

    const {
      editCategory,
      confirmHeader,
      confirmPrompt,
      onConfirm,
      beforeClose,
    } = this.state;
    return (
      <div className="flex row grid-container">
        <div className="flex column grid-item left-panel" key={`left-panel`}>
          {/* categories side bar */}
          <ContentCategoryList
            {...{
              currentCategory,
              categories,
              className: "content-category-list",
              baseUrl,
              showEditCategory: (editCategory) => {
                console.debug("Setting edit category to:", editCategory);
                this.setState({ editCategory });
              },
            }}
          />
        </div>
        <div
          className={`flex column grid-item right-panel`}
          key={`right-panel`}
        >
          {category_id && !currentCategory && <Spinner />}
          {!currentCategory && (
            <Alert severity="info">Please select a category to display</Alert>
          )}
          {category_id && currentCategory && (
            <div className="category-view">
              <div className="category-header">
                <div className="title">
                  <div className="category-name">{currentCategory.title}</div>
                  <IconButton
                    color="secondary"
                    variant=""
                    aria-label="edit"
                    onClick={() =>
                      this.setState({
                        //   editCategoryTitle: !editCategoryTitle,
                        editCategory: { ...currentCategory },
                      })
                    }
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    variant=""
                    aria-label="edit"
                    onClick={() => {
                      this.showConfirmDialog(
                        "Deleting category",
                        `Are you sure you want to delete the category "${currentCategory.title}"?`,
                        () => {
                          deleteCategory(currentCategory.content_cat_id);
                          // TODO: show process spinner
                          this.showConfirmDialog(null, null, null);
                        }
                      );
                    }}
                  >
                    <Delete />
                  </IconButton>
                </div>

                <Tabs value={cat_tab} centered className="m-bottom-10">
                  {category_tab_data.map((t) => (
                    <Tab
                      label={t.title}
                      value={t.id}
                      // component={Link}
                      component={TabComponent}
                      to={`${baseUrl}/${category_id}${
                        t.id === "categories" ? "" : `/${t.id}`
                      }`}
                      id={`${tabid}${t.id}`}
                      aria-controls={`${tabpanelid}${t.id}`}
                      count={t.count}
                    />
                  ))}
                </Tabs>
              </div>
              {/* -----------------------------------PAGES--------------------------------------- */}
              <TabPanel id={`pages`} value={cat_tab}>
                <ContentCategoriesPages
                  {...{
                    baseUrl: `${baseUrl}/${category_id}`,
                    currentCategory,
                    page_id,
                    showConfirmDialog: this.showConfirmDialog,
                    // addCategory,
                    addPage,
                    deletePage,
                    deleteContentElement,
                    updateContentElement,
                    onUpdate,
                  }}
                />
              </TabPanel>
              {/* -----------------------------------CONFIGURE--------------------------------------- */}
              <TabPanel id={`configure`} value={cat_tab}>
                <Configuration
                  //   course_type={course.course_type}
                  source={currentCategory}
                  onSave={saveConfig}
                />
              </TabPanel>
            </div>
          )}
        </div>

        <ContentCategoryForm
          category={editCategory}
          onClose={() => this.setState({ editCategory: null })}
          onSave={() => {
            // save...
            this.saveCategory(editCategory);
            // ...and close form
            this.setState({ editCategory: null });
          }}
        />

        <ConfirmDialog
          open={Boolean(confirmHeader && confirmPrompt && onConfirm)}
          header={confirmHeader}
          prompt={confirmPrompt}
          onConfirm={() => {
            // confirm...
            onConfirm();
            // ...and close
            this.setState({
              confirmHeader: "",
              confirmPrompt: "",
              onConfirm: null,
            });
          }}
          onClose={() => {
            console.debug("Closing confirm dialog");
            if (typeof beforeClose === "function") beforeClose();
            this.setState({
              confirmHeader: "",
              confirmPrompt: "",
              onConfirm: null,
              beforeClose: null,
            });
          }}
        />
      </div>
    );
  }
}

export default ContentCategories;

import React from 'react'
import Login from './login'
import {connect} from 'react-redux'




/**
 * HOC to incapsulate authorization 
 */
const AuthComponent = Wrapped => {


    const mapStateToProps = (state) => {
        const { user } = state
        return { user  };
    }
    
    const mapDispatchToProps = (dispatch) => {
        return {        
            // onClose: () => dispatch({ type: A.HIDE_CONTACT }),
            // sent : () => dispatch({type: A.SEND_CONTACT} ), 
            // error : message => dispatch({type: A.SHOW_MESSAGE, payload:{message, type: "error"}}), 
            // resetMessage: () => dispatch( {type: A.RESET_CONTACT} )
        };
    };

    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        class extends React.Component{        
            render(){ 
                const {user} = this.props
                return user ? <Wrapped {...this.props}/> : <Login /> 
            }
        }
    )
}




export default AuthComponent


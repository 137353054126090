import React from "react";
import { Link } from "react-router-dom";
import { arraysIntersect } from "../../../utils/functions";

const MenuBlock = ({
  items,
  className = "menu-block",
  level = 1,
  user = {},
}) => {
  let { credentials } = user;
  // fail safe
  credentials = Array.isArray(credentials) ? credentials : [];

  const isAdmin = credentials.includes("admin");
  return (
    <ul {...{ className }}>
      {Array.isArray(items) &&
        items.map((item, index) => {
          console.debug(
            "menuBlock credentials intersection:",
            arraysIntersect(credentials, item.credentials),
            "\ncredentials:",
            credentials,
            "\nitem credentials:",
            item.credentials
          );

          const allowed =
            isAdmin ||
            (Array.isArray(item.credentials) &&
              // either there's a * in credentials
              item.credentials.includes("*")) ||
            // or user credentials intersect with item credentials
            arraysIntersect(credentials, item.credentials);
          return allowed ? (
            <li key={`level-${level}-item-${index}`}>
              <Link to={item.to}>
                {item.icon && item.icon}
                {item.title}
              </Link>
              {Array.isArray(item._children) && (
                <MenuBlock
                  className={`level-${level + 1}`}
                  level={level + 1}
                  items={item._children}
                  user={user}
                />
              )}
            </li>
          ) : null;
        })}
    </ul>
  );
};

export default MenuBlock;

import tinymce from '../../tiny';
import Service from '../../../../../../services/experiments';
import uniquid from 'uniquid';
import { charMap, copyToClipboard } from '../charMap';
import "./quiz-question.scss";
import { fixUrl } from '../../utils';

const defaultImageData = {
  meta: '',
  value: '',
};

const {
  REACT_APP_MEDIA_URL
} = process.env;

/**
 * Quiz type
 */
const emptyQuiz = {
  question_id: null,
  course_id: null,
  experiment_id: null,
  question_type: null,
  question_data: null,
  blocks_next: 1,
  ordering: null,
};

const questionData = {
  title: '',
  descr: '',
  questions: [],
};

/**
 * quiz question type
 */
const emptyQuestion = {
  id: null,
  title: '',
  description: '',
  qimage: defaultImageData,
  rightAnswerId: -1,
  answers: [],
};

/**
 * Quiz answer type
 */
const emptyAnswer = {
  id: null,
  text: '',
  aimage: defaultImageData,
};

/**
 * A smallhelper that creates DOM element with passed attributes
 * @param {*} tag
 * @param {*} attributes
 */
const _C = (tag, attributes) => {
  const el = document.createElement(tag);
  // populate attributes, if any
  if (typeof attributes === 'object') {
    Object.keys(attributes).map((key) => {
      el.setAttribute(key, attributes[key]);
    });
  }
  return el;
};

tinymce.PluginManager.add('quiz', function (editor) {
  // Internal var to store context menu selected id
  let contextMenuQuizId, contextMenuQuestionId, contextMenuQuestionType;

  /**
   * The quiz editor dialog.
   * Holds the overall quiz data including all questions.
   * This is the only object that updates data in database.
   * @param {*} config
   */
  const quizEditor = function (config) {
    const me = this;

    // all quiz data
    let Quiz = {
      // copy empty quiz
      ...emptyQuiz,
      // copy empty question data
      // question_data: {...questionData},
      // shallow-override with config data
      ...config,
    };

    let QuizData = { ...questionData, ...(config.question_data || {}) };

    // this will be the panel items for each one of the questions.
    let questionButtons = [];

    if (Array.isArray(QuizData.questions)) {
      QuizData.questions.map((q) => {
        const answers = Array.isArray(q.answers)
          ? q.answers.reduce((res, a) => {
              return `${res}<li class="answer ${
                a.id === q.rightAnswerId ? 'correct' : 'incorrect'
              }">${
                a.aimage ? `<img src="${REACT_APP_MEDIA_URL}${fixUrl(a.aimage.value)}" class="ans" />` : ''
              }${a.text}</li>\n`;
            }, '')
          : '-- no answers --';

        console.debug('Question data:', q);

        questionButtons = [
          ...questionButtons,
          {
            // each question is a panel
            type: 'panel',
            items: [
              // ...that contains a bar with title and two buttons (edit and delete)
              {
                type: 'bar',
                items: [
                  {
                    type: 'htmlpanel',
                    html: `<span class="quiz-question">${
                      q.title ? q.title : ''
                    }${
                      `${
                        q.qimage
                          ? `<img src="${REACT_APP_MEDIA_URL}${fixUrl(q.qimage.value)}" class="que"/>`
                          : ''
                      }` || 'untitled question'
                    }</span>`,
                  },
                  {
                    type: 'button',
                    primary: false,
                    text: `edit`,
                    name: `question_edit-${q.id}`,
                    borderless: true,
                    icon: 'edit-block',
                    classes: 'q-btn edit-button',
                    class: 'q-btn edit-button',
                  },
                  {
                    type: 'button',
                    primary: false,
                    text: `delete`,
                    name: `question_delete-${q.id}`,
                    icon: 'close',
                    borderless: true,
                    classes: 'q-btn delete-button',
                  },
                ],
              },

              // ...and an html panel with all answers as a ul list
              {
                type: 'htmlpanel',
                label: 'Quiz questions',
                html: `<div>anwsers:</div><ul>${answers}</ul>`,
              },
            ],
          },
        ];
      });
    }

    const Redraw = (api) => {
      // close this editor and open ew one with updated data
      api.close();
      quizEditor({
        ...Quiz,
        question_data: QuizData,
      });
    };

    return editor.windowManager.open(
      {
        title: config.question_id ? 'Edit quiz' : 'Add new quiz',
        size: '600px',
        body: {
          type: 'panel',
          items: [
            {
              type: 'input',
              name: 'title',
              label: 'Quiz Title',
            },
            {
              type: 'input',
              name: 'desc',
              label: 'Quiz description',
            },
            {
              type: 'button',
              primary: false,
              text: 'Add question',
              name: 'question_add',
              icon: 'plus',
              borderless: true,
            },
            {
              type: 'htmlpanel',
              label: 'Quiz questions',
              html: `<div data-quiz-content></div>`,
            },
            ...questionButtons,
          ],
        },

        initialData: QuizData,

        buttons: [
          {
            type: 'submit',
            name: 'addIt',
            text: 'Save',
            primary: true,
          },
          {
            type: 'cancel',
            name: 'Cancel',
            text: 'Cancel',
          },
        ],

        onCancel: (api) => api.close(), // TODO: check if data have changed

        onSubmit: (api) => {
          const data = api.getData();

          const { title, desc } = data;

          // update values in QuizData object
          Quiz.question_data = { ...QuizData, title, desc };

          // update the question data with current value
          updateQuiz(Quiz)
            .then((res) => {
              if (res) {
                // insert quiz on update
                InsertQuiz(Quiz.question_id, title);

                editor.notificationManager.open({
                  type: 'success',
                  text: 'Quiz saved',
                });
                api.close();
              } else {
                alert(Service.error);
                // editor.notificationManager.open({
                //   type: "error",
                //   text: Service.error
                // })
              }
            })
            .catch((e) => alert(e.message));
        },

        onAction: (api, details) => {
          console.debug(details, api);
          console.debug(editor);

          // remove first 9 symbols (question_) and split by -
          let [action, id] = details.name.substr(9).split('-');
          // id = parseInt(id) // cast to integer

          console.debug('action/id', action, id);

          switch (action) {
            case 'add':
              editQuestion({ ...emptyQuestion }, (result) => {
                console.debug('New question data:', result);
                // find all already existing ids
                // const existingIds = QuizData.questions.map( q => parseInt(q.id) )
                // const maxId = Math.max(existingIds)
                // let newId = isNaN(maxId)
                result.id = uniquid('qz_');

                QuizData.questions = [...QuizData.questions, result]; // add to questions
                Redraw(api);
              });

              break;

            case 'edit':
              const question_id = QuizData.questions.findIndex(
                (q) => q.id === id
              );
              const question =
                question_id >= 0 ? QuizData.questions[question_id] : null;
              if (question) {
                editQuestion(question, (result) => {
                  console.debug('old question data:', question);
                  console.debug('updated question data:', result);
                  QuizData.questions[question_id] = { ...question, ...result }; // overwite with new values
                  Redraw(api);
                });
              } else {
                console.debug(
                  'Not found question with id',
                  id,
                  'in',
                  QuizData.questions
                );
              }
              break;

            case 'delete':
              const question_index = QuizData.questions.findIndex(
                (q) => q.id === id
              );

              if (question_index >= 0) {
                editor.windowManager.confirm(
                  'Are you sure you want to delete the question?',
                  (res) => {
                    if (res) {
                      QuizData.questions.splice(question_index, 1);
                      Redraw(api);
                    }
                  }
                );
              } else {
                console.debug(
                  'Not found question with id',
                  id,
                  'in',
                  QuizData.questions
                );
              }
              break;

            default:
              break;
          }
        },
      },
      { passedThis: me }
    );
  };

  /**
   * Method to update quiz in database
   * @param {*} quizData
   */
  const updateQuiz = async (quizData) => {
    const { question_id, course_id, experiment_id, question_data } = quizData;

    if (!course_id || !experiment_id) {
      editor.notificationManager.open({
        type: 'error',
        text: 'Error updating quiz: wrong quiz identification data',
      });

      console.error(
        'updateQuiz: course_id or experiment_id not set: ',
        course_id,
        experiment_id
      );
    }

    // build the bizarre update data pack...
    let updateData = { ...question_data };

    // mixin question id if passed (if not passed, will add a new record)
    if (question_id) {
      updateData = { ...updateData, question_id };
    }

    // just to be sure...
    updateData.question_type = 'quiz'; // this shouldn't be changing at all chahge

    console.debug('Updating', course_id, experiment_id, 'with', updateData);

    return await Service.updateQuestion(course_id, experiment_id, updateData);
  };

  /**
   * Single question editor dialog.
   * Holds data for question properties and all of the answers.
   * Calls answer editor to manage each answer.
   * Returns data to Quiz editor via passed callback.
   * @param {*} question optional passed question data
   * @param {*} callback function to call on submit
   */
  const editQuestion = function (question = {}, callback) {
    console.log('editQuestion params: ', question, callback);

    const RedrawQuestion = (api) => {
      //update data from form

      const { title, description, rightAnswerId, qimage } = api.getData();

      question = { ...question, title, description, rightAnswerId, qimage };

      // close this question and open new one with updated data
      api.close();
      editQuestion(question, callback);
    };

    let answerButtons = [];

    // in case no data was passed, the empty question data is sublayed
    question = { ...emptyQuestion, ...question };

    let { rightAnswerId } = question;

    if (question.answers && question.answers.length > 0) {
      question.answers.map((a) => {
        answerButtons = [
          ...answerButtons,
          {
            type: 'panel',
            items: [
              {
                type: 'bar',
                items: [
                  {
                    type: 'htmlpanel',
                    html: `<span class="question-item ${
                      rightAnswerId === a.id ? ' right' : ''
                    }">${a.aimage ? `<img src="${REACT_APP_MEDIA_URL}${fixUrl(a.aimage.value)}" />` : ''}${
                      a.text || 'no text'
                    }<span class="ordering">${a.ordering || ""}</span></span>`,
                  },

                  {
                    type: 'button',
                    primary: false,
                    text: `edit`,
                    name: `editAnswer-${a.id}`,
                    borderless: true,
                    classes: 'q-btn edit-button',
                    class: 'q-btn edit-button',
                  },
                  {
                    type: 'button',
                    primary: false,
                    text: `delete`,
                    name: `deleteAnswer-${a.id}`,
                    borderless: true,
                    classes: 'q-btn delete-button',
                  },
                ],
              },
            ],
          },
        ];
      });
    }

    const answerIds =
      question.answers && question.answers.map
        ? question.answers.map((q) => ({
            text: q.text || 'no text',
            value: q.id.toString(),
          }))
        : [];

    return editor.windowManager.open({
      title: question.id !== null ? 'Edit quiz question' : 'Add quiz question',
      size: '400px',
      body: {
        type: 'panel',
        items: [
          {
            type: 'collection',
            name: 'charMapQuestions',
            label: 'Special characters',
          },
          {
            type: 'input',
            name: 'title',
            label: 'Question text',
          },
          {
            type: 'input',
            name: 'description',
            label: 'Question description',
          },
          {
            type: 'urlinput',
            name: 'qimage', // identifier
            filetype: 'file',
            label: 'Question image',
          },
          {
            name: 'rightAnswerId',
            type: 'selectbox',
            items: answerIds,
            label: 'Set correct answer',
          },
          {
            name: 'addAnswer',
            type: 'button',
            text: 'Add answer',
            icon: 'plus',
          },
          ...answerButtons,
        ],
      },
      buttons: [
        {
          type: 'cancel',
          name: 'cancel',
          text: 'Cancel',
        },
        {
          type: 'submit',
          name: 'addIt',
          text: question.id !== null ? 'Save' : 'Add',
          primary: true,
        },
      ],
      initialData: {
        title: question.title || '',
        description: question.description || '',
        qimage: question.qimage || defaultImageData,
        rightAnswerId: rightAnswerId.toString(),
        charMapQuestions: charMap(),
      },

      onSubmit: (api) => {
        let data = api.getData();

        console.debug('Edit question callback:', callback);
        // call callback passed through config
        if (typeof callback === 'function') {
          callback({ ...question, ...data });
        } else {
          console.debug('Edit question submit: Callback not passed');
        }

        // close the dialog
        api.close();
      },

      onCancel: (api) => api.close(),
      // editQuestion actions
      onAction: (api, details) => {
        let [action, id] = details.name.split('-');
        let answerIndex;

        if (details.value !== '') {
          copyToClipboard(details.value);
        }

        switch (action) {
          case 'addAnswer':
            editAnswer({}, (result) => {
              // add id
              result.id = uniquid('answ_');
              console.debug('New answer:', result);
              question.answers = [...question.answers, result];
              RedrawQuestion(api);
            });
            break;

          case 'editAnswer':
            answerIndex = question.answers.findIndex((a) => a.id === id);

            if (answerIndex >= 0) {
              const answer = question.answers[answerIndex];

              editAnswer(answer, (result) => {
                console.debug('Updated answer:', result);
                // replace existing answer
                question.answers[answerIndex] = result;
                // redraw
                RedrawQuestion(api);
              });
            } else {
              console.debug('editQuestion: answer with id ', id, 'not found');
            }
            break;

          case 'deleteAnswer':
            answerIndex = question.answers.findIndex((a) => a.id === id);

            if (answerIndex >= 0) {
              editor.windowManager.confirm(
                'Are you sure you want to delete the answer?',
                (res) => {
                  if (res) {
                    // remove answer
                    question.answers.splice(answerIndex, 1);
                    // redraw
                    RedrawQuestion(api);
                  }
                }
              );
            } else {
              console.debug('editQuestion: answer with id ', id, 'not found');
            }
            break;
          default:
            console.debug('editQuestion: Unknown action', details);
            break;
        }
      },
    });
  };

  /**
   * Answer editor dialog.
   * Allows to edit one answer.
   * Passes data to question editor via passed callback.   *
   * @param {*} answer
   * @param {*} callback
   */
  const editAnswer = function (answer = {}, callback) {
    // in case no data was passed, the empty answer data is sublayed
    answer = { ...emptyAnswer, ...answer };

    return editor.windowManager.open({
      title: answer.id !== null ? 'Edit answer' : 'Add answer',
      size: '300px',
      classes: 'edit-answer',
      body: {
        type: 'panel',
        items: [
          {
            type: 'collection',
            name: 'charMapAnswers',
            label: 'Special characters',
          },
          {
            type: 'input',
            name: 'text',
            label: 'Answer text',
          },
          {
            type: 'urlinput', // component type
            name: 'aimage', // identifier
            filetype: 'file', // allow any file types
            label: 'Answer image', // text for component label
          },
          {
            type: 'input',
            name: 'ordering',
            label: 'Ordering number',
          },
          {
            type: 'htmlpanel',
            html: `<div class="info">Ordering <strong>starts with 0</strong> and must be a number. 
            So if there are 5 answers, the first ordering number will be 0 and the last will be 4. 
            Any no-numbers and numbers outside this range will not be processed.
            If there are more than 1 answers with same ordering number, the first in the list will be fixed, the rest of them randomized. 
            </div>`
          }
        ],
      },
      buttons: [
        {
          type: 'cancel',
          name: 'cancel',
          text: 'Cancel',
        },
        {
          type: 'submit',
          name: 'addIt',
          text: answer.id !== null ? 'Save' : 'Add',
          primary: true,
        },
      ],
      initialData: {
        text: answer.text || '',
        charMapAnswers: charMap(),
        aimage: answer.aimage || defaultImageData,
        ordering: answer.ordering || ''
      },

      onSubmit: (api) => {
        let data = api.getData();
        // todo: fetch image as well
        let { text, aimage, ordering } = data;


        // validate ordering
        if( 
          // filter out non-integer ordering 
          isNaN( parseInt(ordering)) 
          // filter out negative ordering
          || ordering < 0  
          // TODO: find a way to limit ordering to number of questions
          ) ordering = "";

        // call callback passed through config
        if (typeof callback === 'function') {
          callback({ ...answer, text, aimage, ordering });
        } else {
          console.debug('Edit answer submit: Callback not passed');
        }

        // close the dialog
        api.close();
      },

      onCancel: (api) => api.close(),
      onAction: (api, details) => {
        if (details.value !== '') {
          copyToClipboard(details.value);
        }
      },
    });
  };

  /**
   * The 'Add new quiz' dialog.
   * Allows to enter title/description, then actually saves the quiz in DB and inserts in tinymce,
   * then calls quiz editor to edit the details.
   * Probably obsolete, we might want to use the quiz editor without this at some point.
   */
  const openDialog = () => {
    return editor.windowManager.open({
      title: 'Initiate Quiz',
      size: '900px',
      body: {
        type: 'panel',
        items: [
          {
            type: 'input',
            name: 'title',
            label: 'Quiz Title',
          },
          {
            type: 'input',
            name: 'desc',
            label: 'Quiz description',
          },
        ],
      },
      buttons: [
        {
          type: 'cancel',
          text: 'Close',
        },
        {
          type: 'submit',
          text: 'Go to quiz creation',
          primary: true,
        },
      ],
      onCancel: (api) => api.close(), // TODO: check if data have changed

      onSubmit: async function (api) {
        const data = api.getData(); // { desc, title }
        const { course_id, course_experiment_id } = editor.settings;
        //first server call to init a quiz
        const question_id = await Service.updateQuestion(
          course_id,
          course_experiment_id,
          data
        );

        if (question_id) {
          // fetch quiz data from service
          const quiz = await Service.getQuestion(
            course_id,
            course_experiment_id,
            question_id
          );

          // insert quiz
          InsertQuiz(question_id, data.title);

          // open quiz editor
          quizEditor(quiz);
        } else {
          // show error
          editor.notificationManager.open({
            text: Service.error,
            type: 'error',
          });
        }
        api.close();
      },
    });
  };

  /**
   * Inserts a quiz element into Tinymce HTML document.
   * Prevously removes the quiz elements with same ID from the document (removes first of all found, there shouldn't be more than 1)
   * @param {*} quizID
   */
  const InsertQuiz = (quizID, quizTitle) => {
    // prepare placeholder element
    const result = `
    <div data-question-id="${quizID}" data-question-type="quiz" data-quiz-title="${
      quizTitle || 'Untitled quiz'
    }" class="question quiz" data-updated="${Date.now()}"></div>        
    `;

    // find previosly added element with same id (if any)
    const elm = editor.dom.select(
      `[data-quiz-id="${quizID}"],[data-question-id="${quizID}"]` // data-question-id is to clean up old test elements
    )[0];
    

    // if found, remove it
    if (elm) {
      console.debug('Found and remove previous version of quiz:', elm);
      editor.dom.remove(elm);
    } else {
      console.debug(
        'Previously inserted item with id',
        quizID,
        'was not found'
      );
    }

    // insert new element
    editor.insertContent(result);

    // find the newly added element
    const elm2 = editor.dom.select(
      `[data-question-id="${quizID}"]`
    )[0];

    // add click listener
    addClickListener(elm2);
  };

  editor.ui.registry.addButton('quiz', {
    text: 'Quiz Question',
    //function fires when Quiz Question is pressed in menu
    onAction: function () {
      // Open window
      openDialog();
    },
  });

  const addClickListener = (element) => {
    element.addEventListener('click', () => {
      // quizId is legacy format, questionId + questionType is the current one
      contextMenuQuizId = element.dataset['quizId'];
      contextMenuQuestionId = element.dataset['questionId'];
      contextMenuQuestionType = element.dataset['questionType'];

      // open editor
      editQuiz();
    } );
  }

  editor.on("init", () => {
    console.debug("Initializing Quiz plugin");
    // Access the editor's content body
    var contentBody = editor.getBody();
  
    // Query for elements with 'data-question-id'
    var questionElements = contentBody.querySelectorAll('[data-question-id][data-question-type="quiz"],[data-quiz-id]');
    console.debug("...found", questionElements.length, "'quiz' questions on page");
    // Add click listener to each element
    questionElements.forEach( addClickListener );
  })

  editor.ui.registry.addContextMenu('quiz', {
    //function fires when context menu is opened (right clicking an element)
    update: function (element) {
      // parse the ID from the element, save in global variable

      // quizId is legacy format, questionId + questionType is the current one
      contextMenuQuizId = element.dataset['quizId'];
      contextMenuQuestionId = element.dataset['questionId'] || contextMenuQuizId;
      contextMenuQuestionType = element.dataset['questionType'];

      console.debug('context menu update', contextMenuQuizId);

      //if element has questionId OR quizId AND questionType is quiz - open context menu
      const result =
        Boolean(contextMenuQuizId) ||
        (Boolean(contextMenuQuestionId) && contextMenuQuestionType === 'quiz')
          ? 'quiz'
          : '';

      return result;
    },
  });

  const editQuiz = async () => {
    const { course_id, course_experiment_id } = editor.settings;

    if (!contextMenuQuestionId) {
      editor.windowManager.alert('Error: question ID was not extracted!');
      return;
    }

    // fetch data from server
    const serverRes = await Service.getQuestion(
      course_id,
      course_experiment_id,
      contextMenuQuizId || contextMenuQuestionId
    );

    if (serverRes) {
      // run quiz editor
      quizEditor(serverRes);
    } else {
      console.debug('Service.error:', Service.error || "Unknown error!");
      editor.notificationManager.open({
        text: Service.error || 'Unknown error',
        type: 'error',
      });
    }
  }
  
  editor.ui.registry.addMenuItem('quiz', {
    text: 'Edit Quiz',

    //function fires when quiz context menu selected
    onAction: editQuiz,
  });

  return {
    getMetadata: function () {
      return {
        name: 'Quiz',
      };
    },
  };
});

import React from "react";
import CourseService from "../../../services/course";
import {
  // Chip,
  LinearProgress,
  // Grid,
  ButtonGroup,
  Button,
  Checkbox,
  // Dialog,
  // DialogActions,
  // DialogContent,
  Typography,
  Avatar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  // Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddBoxIcon from "@material-ui/icons/AddBox";
import EditIcon from "@material-ui/icons/Edit";
import {
  // DataGrid,
  GridOverlay,
  // GridToolbarExport,
} from "@material-ui/data-grid";
import { connect } from "react-redux";
// import { showMessage } from "@ses-education/courses-components";
import Error from "../../template/Error";
import {AdminTable, showMessage, ModalWindow, ConfirmDialog, Spinner} from "@ses-education/courses-components";
// import ModalWindow from "@ses-education/courses-components/modal-window";
import { Link } from "react-router-dom";
import AddCourseForm from "./eb3000/add-course-form";
// import ConfirmDialog from "../../template/ConfirmDialog";
import EB3000Course from "./eb3000";
import AT5000Course from "./at5000";
// import {Spinner} from "@ses-education/courses-components";
// import GridSearchComponent from "@ses-education/courses-components/admin-table/grid-search-component";
const defaultImage = `${process.env.PUBLIC_URL}/static/images/course-no-image.png`;

/**
 * Components that render course types.
 * Add more renderers when more course types will be created.
 */
const courseRenderers = {
  1: EB3000Course,
  2: AT5000Course,
};

class Courses extends React.Component {
  state = {
    page: 1,
    perpage: 100,
    courses: null,
    rows: [],
    original_rows: [],
    loading: false,
    selectedRows: [],
    error: null,
    redirect: null,
    showAddCourse: false,
    showConfirmDeletion: false,
    course: null,
    types: null,
    selectedType: -1,
  };

  async componentDidMount() {
    // this.setState({ loading: true });
    // const coursesLoaded = await this.fetchCourses();
    this.fetchData();

    // if (coursesLoaded) {
    //   this.setState({ error: null, loading: false });
    // }
  }

  async componentDidUpdate(prevProps) {
    // if (
    //   this.props.match.params.id &&
    //   (!this.state.courses || !this.state.course) &&
    //   !this.state.loading
    // ) {
    //   this.setState({ loading: true });
    //   const coursesLoaded = await this.fetchCourses();

    //   if (coursesLoaded) {
    //     this.setState({ error: null, loading: false });
    //   }
    // }

    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.selectCourse();
    }
  }

  async fetchData(forceReload = false) {
    const { page, perpage, courses: existingCourses } = this.state;

    let courses;

    this.setState({ loading: true });
    // fetch types
    await this.fetchTypes();

    try {
      // fetch courses
      if (!Array.isArray(existingCourses) || forceReload)
        courses = await CourseService.getCourseListPage(page, perpage);

      this.setState(
        {
          courses,
          error: courses ? null : CourseService.error || "Unknown error",
          loading: false,
        },
        this.selectCourse
        // ,
        // async () => {
        // const { id } = this.props.match.params;
        // if (id) {
        //   this.editCourseHandler({ row: { id: parseInt(id) } });
        // }
        // await this.fetchTypes();
        // }
      );
      this.populateRows(courses);
      return true;
    } catch (err) {
      // this.props.onShowMessage(`Fetching courses from server failed.`, "error");
      this.setState({ error: "Failed loading courses", loading: false });
      return false;
    }
  }
  async fetchTypes() {
    try {
      const types = await CourseService.getCourseTypes();
      console.debug("fetched types:", types);
      this.setState({ types });
    } catch (err) {
      this.props.onShowMessage("Error loading course types");
      // this.setState({ error: "Failed loading course types" });
      return false;
    }
  }

  /**
   * populates courses and stores "rows" array to state
   * @param {*} users
   */
  populateRows(courses) {
    let rows = [];
    courses.forEach((c) => {
      const item = {
        id: c.course_id,
        is_active: [
          <Checkbox
            key={c.course_id}
            checked={c.active ? true : false}
            disabled
          />,
        ],
        image: c.image_url || defaultImage,
        course_title: c.title,
        course_code: c.course_code,
        type_title: c.course_type.title,
        type_id: c.course_type.course_type_id,
      };
      rows.push(item);
    });
    this.setState({ rows, original_rows: [...rows] }, this.filterCourses);
  }

  selectCourse = () => {
    const { courses } = this.state;
    const { id } = this.props.match.params;

    if (!id || !Array.isArray(courses)) return this.setState({ course: null });

    const course = courses.find((c) => c.course_id === parseInt(id));
    if (!course) {
      return this.setState({
        course: null,
        error: "Course not found!",
      });
    }

    this.setState({
      course,
      error: null,
    });
  };

  loadingOverlay() {
    return (
      <GridOverlay>
        <div style={{ position: "absolute", top: 0, width: "100%" }}>
          <LinearProgress />
        </div>
      </GridOverlay>
    );
  }

  // editCourseHandler(params) {
  //   const { courses } = this.state;
  //   // find id of selected program
  //   const course = courses.filter((c) => c.course_id === params.row.id)[0];
  //   if (course) {
  //     this.setState({ course });
  //     if (!this.props.match.params.id) {
  //       this.setState({ redirect: `/courses/${course.course_id}` });
  //     }
  //   }

  //   /* this.setState({
  //     programForUpdate: program[0],
  //     title: program[0].title,
  //     description: program[0].description,
  //     chosenCourses: program[0].course_ids,
  //     modalOpen: true,
  //   }); */
  // }

  rowsSelectionHandler(newSelection) {
    const { selectedRows } = this.state;
    const selectedId = newSelection.data.id;
    // unselect row
    if (selectedRows.includes(selectedId)) {
      this.setState({
        selectedRows: selectedRows.filter((rowId) => rowId !== selectedId),
      });
    } else {
      // select row
      this.setState({
        selectedRows: [...selectedRows, newSelection.data.id],
      });
    }
  }

  onAddFormclose = () => this.setState({ showAddCourse: false });

  onAddCourse = async ({ course_code, title, course_type }) => {
    const { onShowMessage } = this.props;

    // TODO: probably, move the validation to the form itself

    if (!course_code) {
      onShowMessage("Course code must not be empty!", "error");
      return false;
    }
    if (!title) {
      onShowMessage("Title cannot be empty!", "error");
      return false;
    }

    // add course
    if (
      !(await CourseService.addCourse({
        course_code,
        title,
        course_type_id: course_type,
      }))
    ) {
      onShowMessage(CourseService.error || "Unknown error", "error");
      return false;
    }

    // reload courses
    onShowMessage("Course successfully created");
    this.fetchData(true);
    return true;
  };

  confirmDeletion = ({ id }) => {
    console.debug("confirm deleting ", id);
    this.setState({ showConfirmDeletion: id });
  };

  deleteCourse = async (id) => {
    const { onShowMessage } = this.props;
    console.debug("deleting ", id);
    this.setState({ showConfirmDeletion: false });
    if (!(await CourseService.deleteCourse(id))) {
      onShowMessage(CourseService.error || "Unknown error", "error");
      return false;
    }

    onShowMessage("Course successfully deleted");
    this.fetchData(true);

    return true;
  };
  filterCourses = () => {
    const { original_rows, selectedType } = this.state;
    this.setState({
      rows: [
        ...original_rows.filter(
          (row) => row.type_id === selectedType || selectedType === -1
        ),
      ],
    });
  };
  render() {
    const {
      loading,
      rows,
      selectedRows,
      // redirect,
      error,
      showAddCourse,
      showConfirmDeletion,
      course,
      types,
      selectedType,
    } = this.state;

    const { id: course_id } = this.props.match.params;

    // when "edit" button is pressed - redirect to selected course
    // if (redirect) {
    //   return <Redirect to={redirect} />;
    // }

    // select course renderer
    const CourseRenderer = course
      ? courseRenderers[course.course_type_id]
      : false;
    if (course && course.course_type_id && CourseRenderer === false)
      return <Error error="Course renderer not found" />;

    const columns = [
      { field: "id", headerName: "ID" },
      // {
      //   field: 'is_active',
      //   headerName: 'Active',
      //   flex: 1,
      //   renderCell: (params) => <div>{params.value}</div>,
      // },
      // {
      //   field: "status",
      //   headerName: "Status",
      //   flex: 1,
      //   renderCell: ({ value }) => JSON.stringify(value),
      // },
      {
        field: "image_url",
        headerName: "Image",
        renderCell: (params) => <Avatar src={params.row.image} />,
      },
      { field: "course_title", headerName: "Title", flex: 2 },
      { field: "type_title", headerName: "Type", flex: 2 },
      { field: "course_code", headerName: "Course Code", flex: 1 },
      {
        field: "buttons",
        headerName: "Actions",
        flex: 2,
        renderCell: (params) => (
          <div>
            <ButtonGroup>
              <Button
                // onClick={this.editCourseHandler.bind(this, params)}
                component={Link}
                to={`/courses/${params.row.id}`}
                variant="contained"
                color="primary"
                size="small"
                startIcon={<EditIcon />}
              >
                Edit
              </Button>
              <Button
                onClick={() => this.confirmDeletion(params)}
                variant="contained"
                color="secondary"
                size="small"
                startIcon={<DeleteIcon />}
              >
                Delete
              </Button>
            </ButtonGroup>
          </div>
        ),
      },
    ];

    if (error) return <Error error={error} />;
    // console.debug("types:", types);
    const leftButtons = (
      <>
        <Button
          disabled={selectedRows.length > 0}
          variant="contained"
          size="large"
          startIcon={<AddBoxIcon />}
          onClick={() => this.setState({ showAddCourse: true })}
          className="green-btn"
        >
          Add course
        </Button>
        {/* <GridToolbarExport variant="contained" size="large" /> */}
        <FormControl style={{ minWidth: 120 }}>
          <InputLabel id="course-type-select-label">Types</InputLabel>
          <Select
            labelId="course-type-select-label"
            // label="Types"
            onChange={(e) =>
              this.setState(
                { selectedType: e.target.value },
                this.filterCourses
              )
            }
            value={selectedType}
          >
            <MenuItem value={-1}>All</MenuItem>
            {Array.isArray(types) &&
              types.map((type) => {
                return (
                  <MenuItem value={type.course_type_id}>{type.title}</MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </>
    );

    return (
      <>
        {/* {!course && !course_id && ( */}
        {!course_id && (
          <div className="courses page">
            <Typography variant="h1" color="primary">
              Courses
            </Typography>
            <div>
              <AdminTable
                components={{
                  LoadingOverlay: this.loadingOverlay,
                }}
                buttons={[leftButtons]}
                autoHeight
                rows={rows}
                columns={columns}
                searchFields={["course_title", "course_code"]}
                pageSize={10}
                loading={loading}
              />
            </div>
            <ModalWindow
              // add course form
              open={showAddCourse}
              onClose={this.onAddFormclose}
              header={"Add course"}
              className="width-auto"
            >
              <AddCourseForm
                onClose={this.onAddFormclose}
                onSubmit={this.onAddCourse}
                types={types}
              />
            </ModalWindow>
            <ConfirmDialog
              open={showConfirmDeletion}
              header="Confirm course deletion"
              prompt={
                showConfirmDeletion &&
                `Are you sure you want to delete the course with ID=${showConfirmDeletion}?`
              }
              onConfirm={() => this.deleteCourse(showConfirmDeletion)}
              onClose={() => this.setState({ showConfirmDeletion: false })}
            />
          </div>
        )}
        {course_id && !course && <Spinner />}
        {course && CourseRenderer && <CourseRenderer {...this.props} />}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onShowMessage: (message, type) => dispatch(showMessage(message, type)),
  };
};

export default connect(null, mapDispatchToProps)(Courses);

import React from 'react'
import CourseTypesTypeConfig from './course-types-type-config';


class CourseTypesType extends React.Component{

    render(){
        const {currentType} = this.props;
        return(<>
        {JSON.stringify(currentType)}
        <CourseTypesTypeConfig
            config = {currentType.config}         
        />
        </>)
    }
}

export default CourseTypesType
import Spinner from "../../../template/spinner";
import { Button, Modal } from "@material-ui/core";
import React from "react";
import CourseService from "../../../../services/course";
import Error from "../../../template/Error";
import TranslationSelect from "../../../template/translation-select";
import { Close, FormatListBulleted } from "@material-ui/icons";
import TranslationDesk from "../translation-desk";
import store from "../../../../redux/store";
import { showMessage } from "@ses-education/courses-components";
import {Link} from 'react-router-dom';

class TranslationsCoursesItem extends React.Component {
  state = {
    course: null,
    language_id: null,
    translation_id: null,
    currentTranslation: null,
  };

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    const { course_id } = this.props;
    console.debug("course fetchData item id:", course_id);
    const course = await CourseService.getCourseTranslations(course_id);
    const error = course
      ? null
      : CourseService.error || "Unknown course data error";
    let currentTranslation = null;

    // if course translations have more than one (english) translations, use the first non-english one
    if( course && course?.translations && course.translations.length > 1 ){ 
      currentTranslation = course.translations.find( t => t.language_id !== "en") || null;
    }
    this.setState({ course, error, currentTranslation });
  }

  selectTranslation = (language_id) => {
    const { translations } = this.state.course || {};

    if (!Array.isArray(translations)) {
      store.dispatch(showMessage("Translation not loaded yet!"));
      return false;
    }

    const currentTranslation = translations.find(
      (t) => t.language_id === language_id
    );
    const { translation_id } = currentTranslation || {}; //translations.find( t => t.language_id === language_id )

    if (!translation_id) {
      store.dispatch(
        showMessage("Indicated translation not found: " + language_id, "error")
      );
      return false;
    }

    this.setState({ language_id, translation_id, currentTranslation });
  };

  copyToLanguage = async (language_id) => {
    const { course_id } = this.props;
    try {
      await CourseService.copyToLanguage(course_id, language_id);
      this.fetchData();
    } catch (e) {
      console.debug("copyToLanguage error:\n", e);
      store.dispatch(showMessage(e.message, "error"));
    }
  };

  shouldIClose = () => {
    // TODO: check editing state and confirm closing/saving
    this.props.onClose();
  };

  onUpdateField = async (name, value) => {
    const { translation_id } = this.state;
    if (!translation_id) {
      store.dispatch(showMessage("Translation not selected!"));
      return false;
    }

    try {
      await CourseService.updateCourse({
        course_id: translation_id,
        [name]: value,
      });
      this.fetchData();
    } catch (e) {
      console.debug("onUpdateField error:\n", e);
      store.dispatch(showMessage(e.message, "error"));
    }
  };

  render() {
    const { course_id, onClose, onUpdate } = this.props;
    const { course, error, language_id, currentTranslation } = this.state;
    console.debug("Course item id:", course_id);
    const { translations } = course || {};

    if (!Array.isArray(translations)) return <Spinner />;

    const original = translations.find((t) => t.language_id === "en") || {
      title: "Unknown course!",
    };
    // const currentTranslation = translations.find( t=> t.translation_id === translation_id)
    return (
      // <Modal open={Boolean(course_id)} {...{ onClose }}>
        <div className="modal-window full-screen">
          <Button className="close-button" onClick={this.shouldIClose}>
            <Close color="secondary" />
          </Button>
          {!course && <Spinner />}
          {course && !error && (
            <div className="course">
              {Array.isArray(course.translations) && (
                <TranslationSelect
                  // pass only not english languages to selector
                  {...{
                    translations: translations.filter(
                      (t) => t.language_id !== "en"
                    ),
                  }}
                  translation={currentTranslation ? currentTranslation?.language_id : null }
                  showEnglish={false}
                  onSelect={this.selectTranslation}
                  onAdd={this.copyToLanguage}
                />
              )}

              <h2>
                <span>    Course {course.course_code} : {original.title}</span>
                <div className="nav">
                  <Button variant="contained" 
                  color="primary" 
                  component={Link} 
                  to={`/translations/courses/${course_id}/experiments`}
                  startIcon={<FormatListBulleted/>}
                  size="small"
                  >
                    Experiments
                  </Button>
                </div>
              </h2>
              {!currentTranslation && (
                <p>Please select translation language to edit translation</p>
              )}
              {currentTranslation && (
                <div className="course-content">
                  <TranslationDesk
                    title="Title"
                    type="input"
                    originalValue={original.title}
                    value={currentTranslation.title}
                    fullWidth
                    onUpdate={(value) => this.onUpdateField("title", value)}
                  />
                  <TranslationDesk
                    title="Description"
                    type="input"
                    originalValue={original.description}
                    value={currentTranslation.description}
                    multiline
                    fullWidth
                    onUpdate={(value) =>
                      this.onUpdateField("description", value)
                    }
                  />
                  <TranslationDesk
                    title="Intro"
                    type="input"
                    originalValue={original.intro}
                    value={currentTranslation.intro}
                    multiline
                    fullWidth
                    onUpdate={(value) => this.onUpdateField("intro", value)}
                  />
                  <TranslationDesk
                    title="Html"
                    type="html"
                    originalValue={original.html}
                    value={currentTranslation.html}
                    onUpdate={(value) => this.onUpdateField("html", value)}
                  />
                </div>
              )}
            </div>
          )}
          {error && <Error error={error} />}
        </div>
      // </Modal>
    );
  }
}

export default TranslationsCoursesItem;

import React from "react";
import {
  Button,
  // Grid,
  Typography,
  // Modal,
  Card,
  CardContent,
  CardActions,
  Checkbox,
  Breadcrumbs,
  // Container,
} from "@material-ui/core";
// import ModalForm from "../../template/modalForm";
import OrganizationService from "../../../services/organizations";
import { showMessage, Spinner, ModalWindow } from "@ses-education/courses-components";
// import Spinner from "../../template/spinner";
import { NavLink, Link } from "react-router-dom";
import "./editOrganization.scss";
// import ModalWindow from "@ses-education/courses-components/modal-window";
import OrganizationForm from "./organization-form";
import {connect} from "react-redux";

const {
  REACT_APP_MEDIA_URL = "https://images.ses-education.com/courses/organizations",
} = process.env;

const makeImgUrl = (src) => `${REACT_APP_MEDIA_URL}/${src}`;

class EditOrganization extends React.Component {
  state = {
    currentOrganization: null,
    title: "",
    modalOpen: false,
    modalType: null,
    renderModal: null,
    loading: false,
    organizations: null,
    programs: null,
    users: null,
  };

  async componentDidMount() {
    this.setState({ loading: true });
    const loaded = await this.fetchData();

    if (loaded) {
      this.setState({ error: null, loading: false });
    }
  }

  async fetchData() {
    const { org_id } = this.props.match.params;
    const organizations = await OrganizationService.getOrganizations();
    const programs = await OrganizationService.getOrganizationPrograms(org_id);
    const users = await OrganizationService.getOrganizationUsers(org_id);
    // populate state right after fetching so the editOrganization page data updates
    if (organizations) {
      this.setState({ organizations, programs, users }, () =>
        this.populateState()
      );
      return true;
    } else {
      this.setState({ error: "Failed to load data" });
      return false;
    }
  }

  populateState() {
    const { org_id } = this.props.match.params;
    const { organizations } = this.state;

    const currentOrganization = organizations.find(
      (org) => org.organization_id === parseInt(org_id)
    );

    this.setState({
      currentOrganization,
    });
  }

  closeModalHandler() {
    this.setState({ modalOpen: false });
  }

  editDetailsHandler() {
    this.setState({ modalType: "details", modalOpen: true });
  }

  closeModal() {
    this.setState({
      modalOpen: false,
      id: null,
      renderModal: false,
      modalType: null,
    });
  }

  onSaveOrganization = async (data) => {
    const { onShowMessage } = this.props;
    console.debug(data);

    if (!(await OrganizationService.updateOrganization(data))) {
      onShowMessage(OrganizationService.error, "error");
      return false;
    }
    onShowMessage("Organization successfully updated");    
    this.closeModalHandler();
  };

  render() {
    const {
      currentOrganization,
      modalOpen,
      modalType,
      loading,
      users,
      programs,
    } = this.state;

    if (!currentOrganization || loading) return <Spinner />;

    const { organization_logo_images: images } = currentOrganization;

    return (
      <div className="edit-org-root-container">
        <Breadcrumbs>
          <NavLink to="/organizations">Organizations</NavLink>
          {currentOrganization.organization_id && (
            <span>{currentOrganization.organization_id}</span>
          )}
        </Breadcrumbs>
        <Typography variant="h1" className="dark50">
          Organization {currentOrganization.organization_id}{" "}
          <span className="highlight">
            {currentOrganization.organization_title}
          </span>{" "}
        </Typography>
        <div>
          <div className="flex row justify-content-space-between">
            <div className="item information-container">
              <Card className="grow-1 flex column align-items-center">
                <CardContent className="flex column align-items-center">
                  <Typography className="title">
                    {currentOrganization.organization_title}
                  </Typography>
                  <img
                    src={
                      images && images.logo_small
                        ? makeImgUrl(images.logo_small)
                        : makeImgUrl("no-logo.svg") // "https://images.ses-education.com/courses/organizations/no-logo.svg"
                    }
                    alt="logo"
                    style={{ width: "120px", height: "auto" }}
                  />
                  <Typography>
                    Slug:{" "}
                    {currentOrganization.url_slug
                      ? currentOrganization.url_slug
                      : "no slug"}
                  </Typography>
                  <Typography>
                    Activity status:{" "}
                    {
                      <Checkbox
                        key={currentOrganization.organization_id}
                        checked={currentOrganization.active ? true : false}
                        disabled
                      />
                    }
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.editDetailsHandler.bind(this)}
                  >
                    EDIT DETAILS
                  </Button>
                </CardActions>
              </Card>
            </div>
            <div item xs={12} className="item users-container">
              <Card style={{ width: "100%" }}>
                <CardContent>
                  <Typography>{`Number of users: ${users.length}`}</Typography>
                </CardContent>
                <CardActions>
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`/organizations/${currentOrganization.organization_id}/users`}
                  >
                    EDIT USERS
                  </Button>
                </CardActions>
              </Card>
            </div>

            <div className="item programs-container">
              <Card style={{ width: "100%" }}>
                <CardContent>
                  <Typography>{`Number of programs: ${programs.length}`}</Typography>
                </CardContent>
                <CardActions>
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`/organizations/${currentOrganization.organization_id}/programs`}
                  >
                    EDIT PROGRAMS
                  </Button>
                </CardActions>
              </Card>
            </div>
          </div>
          <ModalWindow
            open={modalOpen}
            onClose={this.closeModalHandler.bind(this)}
            header="Edit organization"
            formClassName="stretch"
          >
            <OrganizationForm
              data={currentOrganization}
              onClose={this.closeModalHandler.bind(this)}
              onSave={this.onSaveOrganization}
            />
          </ModalWindow>
          {/* {!modalType && modalOpen ? (
            <Spinner />
          ) : (
            <Modal
              open={
                modalOpen &&
                (modalType === "details" || modalType === "creation")
              }
              onClose={this.closeModalHandler.bind(this)}
            >
              <ModalForm
                type={modalType}
                onClose={() => this.closeModalHandler()}
                currentOrganization={currentOrganization}
                onOrganizationEdit={() => this.fetchData()}
              />
            </Modal>
          )} */}
        </div>
      </div>
    );
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    onShowMessage: (message, type) => dispatch(showMessage(message, type)),
  };
};

export default connect(null, mapDispatchToProps)(EditOrganization);



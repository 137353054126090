import React from "react";
import TranslationsCourses from "./translations-courses";
import TranslationsIndex from "./translations-index";
import TranslationsPrograms from "./translations-programs";
import TranslationsInterface from "./translations-interface";
import "./translation-component.scss";
import { showMessage } from "@ses-education/courses-components";
import { connect } from "react-redux";

class TranslationsComponent extends React.Component {
  render() {
    const {
      type,
      program_id,
      course_id,
      section,
      experiment_id,
      page,
      question_id,
      language_id,
      translation_id,
    } = this.props.match.params;
    const { onShowMessage } = this.props;

    return (
      <div className="translations-component page">
        {!type && <TranslationsIndex />}
        {type === "programs" && (
          <TranslationsPrograms {...{ program_id, onShowMessage }} />
        )}
        {type === "courses" && (
          <TranslationsCourses
            {...{
              course_id,
              section,
              experiment_id,
              page,
              question_id,
              onShowMessage,
            }}
          />
        )}
        {type === "interface" && (
          <TranslationsInterface {...{ onShowMessage, language_id, translation_id }} />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onShowMessage: (message, type) => dispatch(showMessage(message, type)),
  };
};

export default connect(null, mapDispatchToProps)(TranslationsComponent);

import { Grid, AppBar, Menu, MenuItem } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { showMessage } from "@ses-education/courses-components";
import {
  AccountCircleRounded as UserIcon,
  Menu as MenuIcon,
} from "@material-ui/icons";
import Service from "../../../services/auth";
import A from "../../../redux/actionTypes";

import "./navbar.scss";
import { NavLink } from "react-router-dom";
class Navbar extends React.Component {
  state = {
    menuTarget: null,
  };

  show = (ev) => {
    this.setState({ menuTarget: ev.currentTarget });
  };

  hide = (ev) => {
    this.setState({ menuTarget: null });
  };

  logout = async (ev) => {
    this.setState({ menuTarget: null });
    const result = await Service.logout();
    if (!result) {
      this.props.onShowMessage(
        `Session wasn't closed via API. You don't have to do anything, but something went wrong at server and we're sorry about it.`,
        "warning"
      );
    }
  };

  render() {
    // console.log(this.props)
    const { name } = this.props.user;
    const { menuTarget } = this.state;
    const { toggleDrawer, lastUsed } = this.props;

    return (
      <AppBar className="navbar" position="fixed">
        <Grid container>
          <Grid item>
            <MenuIcon className="menu-button" onClick={toggleDrawer} />
          </Grid>
          <Grid item>
            <NavLink to="/">SES Courses Admin Panel</NavLink>
          </Grid>
          {/* <Grid item >
                        <NavLink to="/catalog">Catalog</NavLink>
                    </Grid> */}
          <Grid item>
            <div className="user-icon" onClick={this.show}>
              <UserIcon />
              {name}
            </div>
            <Menu
              id="simple-menu"
              anchorEl={menuTarget}
              keepMounted
              open={Boolean(menuTarget)}
              onClose={this.hide}
            >
              <MenuItem onClick={this.hide}>Profile</MenuItem>
              {/* <MenuItem onClick={hide}>My account</MenuItem> */}
              <MenuItem onClick={this.logout}>Logout</MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </AppBar>
    );
  }
}

const stateToProps = (state) => {
  const { user, lastUsed } = state;

  return { user, lastUsed };
};

const dispToProps = (dispatch) => ({
  toggleDrawer: () => dispatch({ type: A.TOGGLE_DRAWER }),
  onShowMessage: (message, type) => dispatch(showMessage(message, type)),
});

export default connect(stateToProps, dispToProps)(Navbar);

import Home from "../components/home";
import Courses from "../components/pages/courses";
import Programs from "../components/pages/programs";
import Users from "../components/pages/users";
import Course from "../components/pages/courses/eb3000";
import Settings from "../components/pages/settings";
import P404 from "../components/template/404";
import Tests from "../components/tests";

import Organizations from "../components/pages/organizations";
import EditOrganization from "../components/pages/organizations/editOrganization";
import TranslationsComponent from "../components/pages/translations-component";
import CourseTypes from "../components/pages/settings/course-types";
import experiment_pages from "./experiment_pages.json";
import TranslationLanguages from "../components/pages/translations-component/translation-languages";
// import store from "../redux/store";
// import { arraysIntersect } from "../utils/functions";

// import Sense from '../components/pages/home/sense'
// import Sensors from '../components/pages/home/sensors'
// import ExpCategory from '../components/experiments/experimentCat'
// import Experiment from '../components/experiments/experiment'
// import Labs from '../components/pages/sets/solutions'
// import Lab from '../components/pages/sets/lab'
// import Course from '../components/pages/sets/course'
// import LabMain from '../components/pages/sets/main'
// import Downloads from '../components/pages/software/index'
// import Firmware from '../components/pages/software/firmware'
// import DownloadsItem from '../components/pages/software/softwareItem'
// import Catalog from '../components/pages/catalog'
// import Product from '../components/pages/products/product'
// import Blog from '../components/blog'
// import About from '../components/blog/about'
// import Article from '../components/blog/article'

const components = {
  Home,
  Courses,
  Course,
  Programs,
  Users,
  Organizations,
  EditOrganization,
  CourseTypes,

  Settings,
  Tests,
  P404,
};

// const defaultReveal  = {distance: "30px", bottom: true }

export const sets = ["robotics", "science", "science-and-robotics"];
export const experimentCategories = [
  "physics",
  "biology",
  "chemistry",
  "environment",
  "children",
];
const expTabs = [
  "properties",
  "objectives",
  "equipment",
  "discussion",
  "prep_questions",
  "experiment",
  "summary_questions",
].join("|");

export const routes = [
  { exact: true, path: "/", component: components["Home"], credentials: ["*"] },
  { exact: true, path: "/courses", component: components["Courses"] },
  { exact: true, path: "/programs", component: components["Programs"] },

  { exact: true, path: "/users", component: components["Users"] },
  {
    exact: true,
    path: "/organizations",
    component: components["Organizations"],
  },
  {
    exact: true,
    path: "/organizations/:org_id",
    component: components["EditOrganization"],
  },
  {
    exact: true,
    path: "/organizations/:org_id/users",
    component: components["Users"],
  },
  {
    exact: true,
    path: "/organizations/:org_id/programs",
    component: components["Programs"],
  },

  {
    exact: true,
    // path: `/courses/:id/:tab_id(content|experiments|relations|requirements|formulas)?`,
    path: `/courses/:id/:tab_id(experiments|relations|requirements|formulas)?`,
    component: components["Courses"],
    key: `courses`,
  },
  //{ exact: true, path: "/courses/:id/:tab_id(experiments)/:exp_id" , component: components["Course"]  },

  {
    exact: true,
    path: `/courses/:id/:tab_id(experiments)/:exp_id?/:exp_tab(${expTabs})?`,
    component: components["Courses"],
    key: `courses`,
  },

  {
    exact: true,
    path: `/courses/:id/:tab_id(properties|content)/:category_id?/:cat_tab(pages|configure)?/:page_id?`,
    component: components["Courses"],
    key: `courses`,
  },

  {
    exact: true,
    path: `/translations`,
    component: TranslationsComponent,
    credentials: ["content-translator", "interface-translator"],
  },
  {
    exact: true,
    path: `/translations/:type(programs)/:program_id?/`,
    component: TranslationsComponent,
    credentials: ["content-translator"],
  },
  {
    exact: true,
    path: `/translations/:type(interface)/:language_id?/:translation_id?`,
    credentials: ["interface-translator"],
    component: TranslationsComponent,
    credentials: ["interface-translator"],
  },
  {
    exact: true,
    path: `/translations/:type(courses)/:course_id?/:section(experiments)?/:experiment_id?/:page(${Object.values(
      experiment_pages
    )
      .map((p) => p.id)
      .join("|")})?`,
    component: TranslationsComponent,
    credentials: ["content-translator"],
  },

  {
    exact: true,
    path: `/translations/languages/:language_id?`,
    component: TranslationLanguages,
    credentials: ["content-translator"],
  },

  {
    exact: true,
    path: "/settings/:page(notifications)?",
    component: components["Settings"],
    credentials: ["*"],
  },

  {
    path: "/settings/:page(course-types)/:type_id?/:tab(config)?",
    component: components["Settings"],
    // credentials: ["*"],
  },

  // Testing grounds
  { exact: true, path: "/test", component: components["Tests"] },

  {
    exact: false,
    path: "*",
    component: components["P404"],
    credentials: ["*"],
  },
];

/**
 * credentials check:
 * if route does not include credentials, it is only accessible to admins
 * if route includes credentials, it is also accessible to users with those credentials
 */

// export const routes = uncheckedRoutes.filter(
//   (route) => hasCredentials(route.credentials)
//   // // admin has access to any route
//   // userCredentials.includes("admin") ||
//   // (Array.isArray(route.credentials) && // if route has credentials, check if user has one of them
//   //   (arraysIntersect(route.credentials, userCredentials) ||
//   //     // ...or whether route credentials include "*", any user has access to it
//   //     route.credentials.includes("*")))
// );
// console.debug("routes", routes);
export default routes;

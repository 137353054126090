import React from "react";
import { Typography } from "@material-ui/core";
import { connect } from "react-redux";
import Error from "../template/Error";
import MenuBlock from "../template/menu-block";

const menu = [
  {
    to: "/courses",
    title: "Courses",
    credentials: [],
  },
  {
    to: "/programs",
    title: "Programs",
    credentials: [],
  },
  {
    to: "/users",
    title: "Users",
    credentials: [],
  },
  {
    to: "/organizations",
    title: "Organizations",
    credentials: [],
  },
  {
    to: "/translations",
    title: "Translations",
    credentials: ["content-translator", "interface-translator"],
    _children: [
      {
        to: "/translations/programs",
        title: "Programs",
        credentials: ["content-translator"],
      },
      {
        to: "/translations/courses",
        title: "Courses",
        credentials: ["content-translator"],
      },
      {
        to: "/translations/interface",
        title: "Interface",
        credentials: ["interface-translator"],
      },
    ],
  },
];

class Home extends React.Component {
  render() {
    const { user } = this.props;
    if (!user) return <Error />;
    return (
      <div className="dashboard page">
        <Typography variant="h2">Dashboard</Typography>
        <MenuBlock items={menu} user={user} />
      </div>
    );
  }
}

const stateToProps = (state) => ({
  user: state.user,
});

export default connect(stateToProps)(Home);

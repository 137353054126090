import A from "./actionTypes";
import Actions from "./actions";
// import uniquid from "uniquid";
// const localStorageKey = "SEScoursesAdminApp";

// let localStorageState;

// try {
//   localStorageState = localStorage.getItem(localStorageKey)
//     ? JSON.parse(localStorage.getItem(localStorageKey))
//     : {};
// } catch (e) {
//   localStorageState = {};
// }

const initialState = {
  ...{
    user: null,
    authorization: null,
    showModal: false,
    showDrawer: true,
    modalContents: null,
    messages: [], // array of {message: String, type: String (danger|warning|success|info)}
    maxMessages: 5,
    lastUsed: null,
    tokenTimestamp: Date.now(),
  },
  // overwrite values from local state, if any
  // ...localStorageState,
};

// const setToken = ( state, token, tokenTimestamp, other = {} ) => {
//     console.debug("reducers / setToken: token renewal")
//     // onTokenUpdate(state.tokenTimestamp)
//     Actions.onTokenUpdate(state.tokenTimestamp)
//     return {...state, authorization: `Bearer ${token}`, tokenTimestamp,  ...other }
//     // return {...state, authorization: `Bearer ${token}`, tokenTimestamp: Date.now(), ...other }
// }

// export const onTokenUpdate = state => Actions.onTokenUpdate(state) // renewTokenIn, usageThreshold )

export default function root(state, action = {}) {
  // each time overwrite initial state with passed state.
  // It ensures that if an empty or partial object is in localStorage, the values are all present.
  state =
    typeof state === "object"
      ? { ...initialState, ...state }
      : { ...initialState };

  let newState = { ...state };
  let now = Date.now();

  let { type, payload } = action;

  switch (type) {
    case A.SHOW_MODAL:
      newState = { ...state, showModal: true, modalContents: action.payload };
      break;
    case A.HIDE_MODAL:
      newState = { ...state, showModal: false, modalContents: null };
      break;

    case A.TOGGLE_DRAWER:
      newState = { ...state, showDrawer: !state.showDrawer };
      break;

    case A.RENEW_TOKEN:
      console.debug("A.RENEW_TOKEN renewal");
      // newState = setToken(state, action.payload, now ) //{...state, authorization: `Bearer ${action.payload}`, tokenTimestamp: Date.now() }
      newState = {
        ...state,
        authorization: `Bearer ${action.payload}`,
        tokenTimestamp: now,
      };
      Actions.onTokenUpdate(now);
      break;

    case A.LOGIN:
      const { token, user } = action.payload;
      // put token into state as authorization header value
      // newState = setToken(state, token, now, {user} ) //{...state, user, authorization : `Bearer ${token}`, tokenTimestamp: Date.now() }
      newState = {
        ...state,
        user,
        authorization: `Bearer ${token}`,
        tokenTimestamp: now,
      };
      Actions.onTokenUpdate(now);
      // Actions.onTokenUpdate( renewTokenIn, usageThreshold )
      break;

    case A.LOGOUT:
      newState = { ...state, authorization: null, user: null };
      Actions.clearTokenUpdateTimer();
      break;

    case A.SET_USAGE_TIMESTAMP:
      try {
        newState = { ...state, lastUsed: Date.now() };
      } catch (e) {
        console.error(e.message);
      }
      break;

    default:
    //newState = state;
  }

  // localStorage.setItem(localStorageKey, JSON.stringify(newState));

  return newState;
}

import { notificationsActionTypes as A } from '@ses-education/courses-components';
const ActionTypes = {
  ...A,
  
  SHOW_CONTACT: 'SHOW_CONTACT',
  HIDE_CONTACT: 'HIDE_CONTACT',
  SEND_CONTACT: 'SEND_MESSAGE',
  RESET_CONTACT: 'RESET_MESSAGE',

  SHOW_MODAL: 'SHOW_MODAL',
  HIDE_MODAL: 'HIDE_MODAL',

  TOGGLE_DRAWER: 'TOGGLE_DRAWER',

  SET_USAGE_TIMESTAMP: 'USETIME',

  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  RENEW_TOKEN: 'RENEWTOKEN',
};

export default ActionTypes;

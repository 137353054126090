import Base from './baseClientService';

class OrganizationService extends Base {
  static async getOrganizations() {
    try {
      const organizations = await this.GET(`/admin/organizations/all`);
      return organizations;
    } catch (e) {
      console.error('Error fetching programs: ', e.message);
      this.Error(e.message);
      return false;
    }
  }

  // only returns programs that are assigned to given organization id
  static async getOrganizationPrograms(id) {
    try {
      const programs = await this.GET(`/admin/organizations/${id}/programs`);

      return programs;
    } catch (e) {
      console.error('Error fetching organization programs: ', e.message);
      this.Error(e.message);
      return false;
    }
  }
  // only returns users that are assigned to given organization id
  static async getOrganizationUsers(id) {
    try {
      const users = await this.GET(`/admin/organizations/${id}/users`);

      return users;
    } catch (e) {
      console.error('Error fetching organization users: ', e.message);
      this.Error(e.message);
      return false;
    }
  }

  // unassigns program from organization and deletes it from database
  // TODO: prompt user deletion confirmation
  static async unassignAndDeleteProgram(org_id, prog_id) {
    try {
      const result = await this.POST(
        `/admin/organizations/${org_id}/programs/${prog_id}/delete`
      );
      return result;
    } catch (err) {
      console.error(
        'Error removing program from organization and deleting it: ',
        err.message
      );
      this.Error(err.message);
      return err;
    }
  }

  //assign program with given id to organization with given id
  static async assignProgramToOrganization(org_id, prog_id) {
    try {
      const result = await this.POST(
        `/admin/organizations/${org_id}/programs/${prog_id}/assign`
      );
      return result;
    } catch (err) {
      console.error('Error assigning program to organization: ', err.message);
      this.Error(err.message);
      return err;
    }
  }

  static async removeProgramFromOrganization(org_id, prog_id) {
    try {
      const result = await this.POST(
        `/admin/organizations/${org_id}/programs/${prog_id}/remove`
      );
      return result;
    } catch (err) {
      console.error('Error removing program from organization: ', err.message);
      this.Error(err.message);
      return err;
    }
  }

  static async updateOrganization(data, id) {
    console.debug("updateOrganization data", data)
    try {
      // let newOrganization = {
      //   active: data.activityStatus,
      //   organization_title: data.name,
      //   url_slug: data.slug,
      //   organization_logo_images: {},
      // };
      // // append id in case of editing
      // if (id) newOrganization = { ...newOrganization, organization_id: id };

      // const result = await this.POST(
      //   `/admin/organizations/update`,
      //   newOrganization
      // );
      const result = await this.POST(
        `/admin/organizations/update`,
        data
      );
      return result;
    } catch (e) {
      console.error('Error updating organization: ', e.message);
      this.Error(e.message);
      return false;
    }
  }

  static async deleteOrganization(id) {
    try {
      const result = await this.POST(`/admin/organizations/${id}/delete`);

      return result;
    } catch (e) {
      console.error('Error deleting organization: ', e.message);
      this.Error(e.message);
      return false;
    }
  }

  // creates and assigns program to existing organization with given id
  static async updateOrganizationProgram(id, data) {
    try {
      const result = await this.POST(
        `/admin/organizations/${id}/programs/update`,
        data
      );

      return result;
    } catch (e) {
      console.error('Error creating program for organization: ', e.message);
      this.Error(e.message);
      return false;
    }
  }
  // creates or update organization user
  static async updateOrganizationUser(data, id, org_id) {
    // when editing existing user
    if (id) {
      data = { ...data, id };
    }
    try {
      const result = await this.POST(
        `/admin/organizations/${org_id}/users/update`,
        data
      );
  
      return result;
    } catch (e) {
      console.error('Error creating or updating user for organization: ', e.message);
      this.Error(e.message);
      return false;
    }
  }
  static async assignProgramToUser(org_id, user_id, prog_id) {
    try {
      const result = await this.POST(
        `admin/organizations/${org_id}/users/${user_id}/assign-program/${prog_id}`
      );
      return result;
    } catch (err) {
      console.error('Error assigning program to organization user: ', err.message);
      this.Error(err.message);
      return err;
    }
  } 

  static async unassignProgramToUser(org_id, user_id, prog_id) {
    try {
      const result = await this.POST(
        `admin/organizations/${org_id}/users/${user_id}/remove-program/${prog_id}`
      );
      return result;
    } catch (err) {
      console.error('Error unassigning program to organization user: ', err.message);
      this.Error(err.message);
      return err;
    }
  } 
}

Base.extend(OrganizationService);

export default OrganizationService;

import {
  Container,
  Tabs,
  Tab,
  Typography,
  Button,
  Breadcrumbs,
} from "@material-ui/core";
import { EditRounded as EditIcon } from "@material-ui/icons";
import React from "react";
import Service from "../../../../services/course";
import Loader from "../../../template/common/loader";
import Form from "../form";

import TinyMceEditor from "@ses-education/content-editor";
import { defaultCoursesConfig } from "../../../../data/tinymce-configs";

import Experiments from "./experiments";
import { connect } from "react-redux";
import { showMessage } from "@ses-education/courses-components";
import { NavLink, Link } from "react-router-dom";
import Requirements from "./requirements";
import Formulas from "./formulas";
import CourseService from "../../../../services/course";

const tabid = "tab-";
const tabpanelid = "tab-field-";

const tinymceKey = process.env.REACT_APP_TINYMCE;

const TabPanel = (props) => {
  const { id, children, value = 0 } = props;

  return (
    <div
      role="tabpanel"
      aria-labelledby={`${tabid}${id}`}
      id={`${tabpanelid}${id}`}
      hidden={value !== id}
    >
      {children}
    </div>
  );
};

/**
 * default editor with course config
 * @param {*} param0
 */
const Editor = ({ init = {}, ...props }) => {
  return (
    <TinyMceEditor init={{ ...defaultCoursesConfig, ...init }} {...props} />
  );
};

const tab_data = [
  { id: "properties", title: "Properties" },
  { id: "content", title: "Preface (Intro)" },
  { id: "experiments", title: "Experiments" },
  { id: "requirements", title: "Requirements" },
  { id: "formulas", title: "Formulas" },
  // { id: 'relations', title: 'Relations' },
];
/**
 * each course consist of 4 tabs
 */
class Course extends React.Component {
  state = {
    course: null,
    editingHtml: false,
    savingHtml: false,
    errorText: null,
  };

  componentDidMount() {
    this.fetchData();
  }

  /**
   * gets course information from server and stores in state
   */
  fetchData = async () => {
    console.debug("\n\n\nfetching course data\n\n")
    //get the id of the selected course
    const { id } = this.props.match.params;
    const course = await Service.getCourse(id);
    if (!course) {
      this.setState({ course, errorText: Service.error });
      this.props.onShowMessage(
        "Error fetching course: " + Service.error,
        "error"
      );
    } else this.setState({ course });
  };

  /**
   * only activated when updating "content" tab html
   */
  updateHtml = async (html) => {
    const { course } = this.state;
    const { course_id } = course;
    //update course html
    course.html = html;
    this.setState({ course, savingHtml: true });
    //send changes to server
    const result = await Service.updateCourse({ course_id, html });
    if (!result) {
      this.props.onShowMessage("Error: " + Service.error, "error");
      // continue editing
      this.setState({ savingHtml: false });
    } else {
      // stop editing
      this.setState({ savingHtml: false, editingHtml: false });
    }
  };

  onUploadFormulas = async (course_id, event) => {
    const { onShowMessage } = this.props;

    // extract file data from event
    const file = event.target.files && event.target.files[0]  ? event.target.files[0] : null;

    // if nothing extracted, show error
    if (!file) {
      onShowMessage("Error fetching file data", "error");
      return false;
    }
    console.debug("uploading file:", file);

    // build form data with file
    const data = new FormData();
    data.append("formulas", file);

    // console.debug("uploading data", data);
    if (!(await CourseService.uploadFormulas(course_id, data))) {
      onShowMessage(CourseService.error || "Unknown error", "error");
      return false;
    }

    onShowMessage("Formulas sheet uploaded");
    this.fetchData();
    return true;
  };

  onDeleteFormulas = async (course_id) => {
    const { onShowMessage } = this.props;
    console.debug("deleting formulas for course", course_id);
    if (!(await CourseService.deleteFormulas(course_id))) {
      onShowMessage(CourseService.error || "Unknown error", "error");
      return false;
    }

    onShowMessage("Formulas sheet deleted");
    this.fetchData();
    return true;
  };

  /**
   * when admin cancels changes in editor, original content is restored
   * because no server call is made
   */
  cancel = () => {
    this.setState({ editingHtml: false });
  };

  render() {
    const { course, editingHtml, savingHtml, errorText } = this.state;
    // currently selected experiment
    const {
      exp_id,
      tab_id = "properties",
      exp_tab = "properties",
    } = this.props.match.params;

    const { onShowMessage } = this.props;

    return (
      <Loader data={course} errorText={errorText} onClick={this.cancel}>
        {(props) => {
          const { course_id, title, course_code, html } = props;
          return (
            <Container className="test" key={`course-${course_id}`}>
              <Breadcrumbs>
                <NavLink to="/courses">Courses</NavLink>
                {course_id && (
                  <NavLink to={`/courses/${course_id}`}>{course_code}</NavLink>
                )}
                {course_id && tab_id && (
                  <NavLink
                    to={`/courses/${course_id}${
                      tab_id === "properties" ? "" : `/${tab_id}`
                    }`}
                  >
                    {tab_data.find((t) => t.id === tab_id).title}
                  </NavLink>
                )}
                {exp_id && <span>{exp_id}</span>}
                {exp_id && exp_tab && <span>{exp_tab}</span>}
              </Breadcrumbs>
              <Typography variant="h1" gutterBottom>
                {course_code} : {title}
              </Typography>
              <Tabs value={tab_id} centered className="m-bottom-10">
                {tab_data.map((t, ind) => (
                  <Tab
                    label={t.title}
                    value={t.id}
                    component={Link}
                    to={`/courses/${course_id}${
                      t.id === "properties" ? "" : `/${t.id}`
                    }`}
                    id={`${tabid}${t.id}`}
                    aria-controls={`${tabpanelid}${t.id}`}
                  />
                ))}
              </Tabs>
              <div>
                {/* -----------------------------------PROPERTIES--------------------------------------- */}
                <TabPanel id={`properties`} value={tab_id}>
                  <Form {...course} onUpdate={this.fetchData} />
                </TabPanel>
                {/* -----------------------------------CONTENT--------------------------------------- */}
                <TabPanel id={`content`} value={tab_id}>
                  {editingHtml ? (
                    <Editor
                      apiKey={tinymceKey}
                      content={html}
                      disabled={savingHtml}
                      onSave={this.updateHtml}
                      onCancel={this.cancel}
                    />
                  ) : (
                    <>
                      <div>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => this.setState({ editingHtml: true })}
                        >
                          <EditIcon fontSize="small" />
                          Edit
                        </Button>
                      </div>
                      <div
                        className="course-html-preview"
                        dangerouslySetInnerHTML={{ __html: html }}
                      ></div>
                    </>
                  )}
                </TabPanel>
                {/* -----------------------------------EXPERIMENTS--------------------------------------- */}
                <TabPanel id={`experiments`} value={tab_id}>
                  <Experiments
                    id={course_id}
                    exp_id={exp_id}
                    exp_tab={exp_tab}
                  />
                </TabPanel>
                {/* -----------------------------------REQUIREMENTS--------------------------------------- */}
                <TabPanel id={`requirements`} value={tab_id}>
                  <Requirements course_id={course_id} message={onShowMessage} />
                </TabPanel>
                {/* -----------------------------------RELATIONS--------------------------------------- */}
                {/* <TabPanel id={`relations`} value={tab_id}>
                  Relations editor here
                </TabPanel> */}
                {/* -----------------------------------FORMULAS---------------------------------------- */}
                <TabPanel id={`formulas`} value={tab_id}>
                  <Formulas
                    {...{
                      course,
                      onShowMessage,
                      onUploadFormulas: this.onUploadFormulas,
                      onDeleteFormulas: this.onDeleteFormulas,
                    }}
                  />
                </TabPanel>
              </div>
            </Container>
          );
        }}
      </Loader>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onShowMessage: (message, type) => dispatch(showMessage(message, type)),
  };
};

export default connect(null, mapDispatchToProps)(Course);

import Base from './baseClientService';
import TranslationsService from './translations-service';

class LanguagesService extends Base {
  
    static async getAll(){
        const index = "all";
        
        try{
            return await this.GET("/admin/languages/");
        } catch(e){
            return this.Error(e.message);
        }
        
    }

    static async getIso(){
        const index = "iso";
        
        try{
            return await this.GET("/admin/languages/iso-codes");
        } catch(e){
            return this.Error(e.message);
        }
        
    }

    static async add(language){
        try{
            const result = await this.POST("/admin/languages/add",{language});
            TranslationsService.clearCache("languageList");
            return result;
        } catch(e){
            return this.Error(e.message);
        }
    }

    static async update(language){
        try{
            const result = await this.POST("/admin/languages/update",{language});
            TranslationsService.clearCache("languageList");
            return result;
        } catch(e){
            return this.Error(e.message);
        }
    }

    static async delete(language_id){
        const result = await this.POST(`/admin/languages/delete/${language_id}`);
        TranslationsService.clearCache("languageList");
        return result;
        // try{
        // } catch(e){
        //     return this.Error(e.message);
        // }
    }
}
Base.extend(LanguagesService);

export default LanguagesService;

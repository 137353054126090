import TinyEditor, {tinymce}  from './editor';
import './editor.scss';

// import plugins
// import './plugins/text-field';
import './plugins/question';
import './plugins/colorDrop';
import './plugins/quiz-question';
import './plugins/multiple-question';
// import './plugins/binary-question';

export default TinyEditor
export {tinymce};

import React from "react";
import {
  Button,
  List,
  ListItem,
  Tab,
  Tabs,
  TextField,
} from "@material-ui/core";
import { connect } from "react-redux";
import "./modal-content.scss";
import { showMessage } from "@ses-education/courses-components";
import CourseService from "../../../../../services/course";
import {ModalWindow} from "@ses-education/courses-components";
import {AsyncButton} from "@ses-education/courses-components";
import { getObjectDifference } from "../../../../../utils/functions";
import { Check, Close, Save } from "@material-ui/icons";

const TabPanel = (props) => {
  const { children, value, index, tabname, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${tabname}-panel-${index}`}
      aria-labelledby={`${tabname}-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

class ModalContent extends React.Component {
  state = {
    title: "",
    chosenContent: 0,
    contentElements: null,
    selectedTab: 0,
    search: "",
    originalElement: null,
    currentElement: null,
    changes: {}, // difference between original and current
    hasChanged: false, // whether there was a change since mount/update
  };

  async componentDidMount() {
    const { currentElement } = this.props;
    console.debug("modalContent did mount currentElement:", currentElement);
    // store passed element both as current and original element
    this.setState({
      currentElement,
      originalElement: { ...currentElement },
      changes: {},
      hasChanged: false,
    });
    await this.contentElements();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.onModalOpen && !prevProps.onModalOpen) {
      await this.contentElements();
    }

    if (prevProps.currentElement !== this.props.currentElement) {
      console.debug(
        "modalContent did mount currentElement:",
        this.props.currentElement
      );
      this.setState({
        currentElement: this.props.currentElement,
        originalElement: { ...this.props.currentElement },
        changes: {},
        hasChanged: false,
      });
    }
  }
  async contentElements() {
    try {
      // featch all the contentElements available
      const contentElements = await CourseService.getAllContentElements();
      // if didnt resive result show error
      if (!contentElements) {
        this.props.onShowMessage(CourseService.error, "error");
        return;
      }
      // put all content elements to state
      this.setState({ contentElements });
    } catch (err) {
      // if unknown error accure
      this.props.onShowMessage(
        `Fetching content elements from server failed.`,
        "error"
      );
    }
  }

  closeModal = () => {
    // reset state and closing the modal
    this.setState({
      title: "",
      chosenContent: 0,
      contentElements: null,
      selectedTab: 0,
      search: "",
    });
    this.props.onModalClose();
  };

  // create a new content element and assign it to page
  createContentElement = async () => {
    try {
      // geting changed data from state
      const { changes } = this.state;
      const { page_id, onUpdate, currentElement } = this.props;
      // adding a new content element with data from state to the current page
      // or updating current element
      const contentElementId = currentElement.element_id
        ? // current element has element_id - update it
          await CourseService.updateContentElement(
            currentElement.element_id,
            changes
          )
        : // no element_id - crceate new
          await CourseService.addContentElement(page_id, changes);

      // if no result show error and get out
      if (!contentElementId) {
        this.props.onShowMessage(CourseService.error, "error");
        return;
      }
      // if add the content element succesfolly show message and close modal and update the view
      this.props.onShowMessage(
        `element with id ${contentElementId} successfully added`
      );
      this.closeModal();
      onUpdate(); // no need to await here
    } catch (err) {
      // unknown error accure
      this.props.onShowMessage(`creating Content Element failed.`, "error");
    }
  };
  // assign existed content element to page
  AssignContentElement = async () => {
    try {
      // get existed content element id from state
      const { chosenContent } = this.state;
      console.debug("assignContentElement", chosenContent);
      if (
        !chosenContent ||
        typeof chosenContent !== "number" ||
        chosenContent <= 0
      ) {
        return this.props.onShowMessage("Wrong type of element selected");
      }
      const { page_id, onUpdate } = this.props;
      if (!page_id) {
        console.debug("AssignCOntentElement page id not found. Props:", {
          ...this.props,
        });
        return this.onShowMessage("Page ID not found!");
      }
      // assign content element with id chosenContent to current page
      const contentElementId = await CourseService.assignContentElement(
        page_id,
        chosenContent
      );

      // if result not exist show error
      if (!contentElementId) {
        this.props.onShowMessage(CourseService.error, "error");
        return;
      }
      // succesfolly assign content element to page and close modal and update view
      this.props.onShowMessage(
        `element with id ${contentElementId} succesfully assigned to page ${page_id}`
      );
      this.closeModal();
      await onUpdate();
    } catch (err) {
      // unknown error accure
      this.props.onShowMessage(`assigning Content Element failed.`, "error");
    }
  };

  updateField = ({ target }) => {
    if (!target || !target.name) {
      return this.props.onShowMessage("Error updating field");
    }

    // extract name and value from event
    const { name, value } = target;
    const { currentElement = {}, originalElement = {} } = this.state;
    // rewrite value of name in current element in state

    console.debug("updating", name, "to", value);
    const updated = {
      ...currentElement,
      [name]: value,
    };
    console.debug("curentElement after update:", currentElement);

    const changes = getObjectDifference(originalElement, updated);
    console.debug("changes ater update:", changes);
    const hasChanged = !changes || Object.keys(changes).length > 0;

    this.setState({
      currentElement: updated,
      changes,
      hasChanged,
    });
  };

  render() {
    const {
      onModalOpen,
      currentElement: propsCurrentElement, // this is just to remove it from [other]
      course_id,
      // category_id,
      page_id,
      ...other
    } = this.props;

    const {
      contentElements,
      selectedTab,
      search,
      chosenContent,
      currentElement,
      hasChanged,
    } = this.state;

    const elementHasId = Boolean(currentElement?.element_id);
    return (
      <>
        <ModalWindow
          open={onModalOpen}
          onClose={() => this.closeModal()}
          header={elementHasId ? "Edit content element" : "Add content element"}
          {...other}
          className="Add-content-element-daialog"
        >
          <div className="modal-container">
            <Tabs
              value={selectedTab}
              onChange={(event, newValue) => {
                this.setState({ selectedTab: newValue });
              }}
              aria-label="program tabs"
              centered
            >
              <Tab
                label={elementHasId ? "Change Element" : "Create New"}
                index={0}
              />
              <Tab
                label={
                  elementHasId ? "Replace With Existing" : "Select Existing"
                }
                index={1}
              />
            </Tabs>
            {/* ------------CREATE TAB---------------- */}
            <TabPanel tabname={"Create New"} value={selectedTab} index={0}>
              <div className="modal-data">
                <TextField
                  id="outlined-title"
                  label="Content Element title"
                  className="m-y-10"
                  variant="outlined"
                  name="element_title"
                  onChange={this.updateField}
                  fullWidth
                  value={currentElement?.element_title}
                />
                <div className="modal-buttons flex row align-items-center justify-content-space-between grow-1">
                  <AsyncButton
                    onClick={this.createContentElement}
                    variant="contained"
                    color="primary"
                    disabled={!hasChanged}
                  >
                    <Save />
                    {elementHasId ? "Update" : "Create"}
                  </AsyncButton>
                  <AsyncButton
                    onClick={this.closeModal}
                    variant="contained"
                    color="secondary"
                  >
                    <Close />
                    Close
                  </AsyncButton>
                </div>
              </div>
            </TabPanel>
            {/* ----------ASSIGN TAB---------- */}
            <TabPanel tabname={"Select Existing"} value={selectedTab} index={1}>
              <TextField
                id="outlined-search"
                label="Search"
                className="m-top-10" // 1em top margin
                variant="outlined"
                fullWidth // make full width
                onChange={(event) =>
                  this.setState({ search: event.target.value })
                }
                value={search}
              />
              <List>
                {Array.isArray(contentElements) &&
                  contentElements
                    .filter((f) => {
                      return (f.element_title || "")
                        .toLowerCase()
                        .includes((search || "").toLowerCase());
                    })
                    .map((item) => {
                      const { element_id, element_title } = item;
                      return (
                        <ListItem
                          className="list-item"
                          button
                          key={element_id}
                          selected={element_id === chosenContent}
                          onClick={() =>
                            this.setState({ chosenContent: element_id })
                          }
                        >
                          {element_title}
                        </ListItem>
                      );
                    })}
              </List>
              <div className="modal-buttons flex row align-items-center justify-content-space-between grow-1">
                <AsyncButton
                  onClick={this.AssignContentElement}
                  variant="contained"
                  color="primary"
                  disabled={!chosenContent}
                >
                  <Check /> Select
                </AsyncButton>
                <AsyncButton
                  onClick={this.closeModal}
                  variant="contained"
                  color="secondary"
                >
                  <Close /> Close
                </AsyncButton>
              </div>
            </TabPanel>
          </div>
        </ModalWindow>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onShowMessage: (message, type) => dispatch(showMessage(message, type)),
  };
};
export default connect(null, mapDispatchToProps)(ModalContent);

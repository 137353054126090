import React from 'react'
import Error from '../Error'


class P404 extends React.Component{

    render(){
        const {
            title = "Error 404",
            error = "Page not found"
        } = this.props;
        // return("Error 404: Page not found")
        return <Error {...{title, error}} />
    }
}


export default P404
import { Button, IconButton, Input } from "@material-ui/core";
import { Close, PictureAsPdf } from "@material-ui/icons";
import React from "react";
// import { Link } from "react-router-dom";

class Formulas extends React.Component {
  render() {
    let {
      course,
      onUploadFormulas,
      onDeleteFormulas,
      filename = "formulas",
    } = this.props;
    course = course || {};
    const { formulas, course_id } = course;

    return (
      <div className="formulas">
        <h4>Formulas file</h4>
        {formulas ? (
          <>
            <Button
              color="primary"
              component={"a"}
              href={formulas}
              target="_blank"
            >
              <PictureAsPdf /> View file
            </Button>
            <Button
              size="small"
              color="secondary"
              variant="contained"
              aria-label="delete pdf"
              onClick={() => onDeleteFormulas(course_id)}
            >
              <Close /> delete
            </Button>
          </>
        ) : (
          <label htmlFor="icon-button-file">
            <Input
              inputProps={{ accept: "application/pdf" }}
              id="icon-button-file"
              type="file"
              style={{ display: "none" }}
              name={filename}
              onChange={(event) => onUploadFormulas( course_id, event )}
            />
            <IconButton
              color="primary"
              variant=""
              aria-label="upload pdf"
              component="span"
            >
              <PictureAsPdf /> Upload file
            </IconButton>
          </label>
        )}
      </div>
    );
  }
}

export default Formulas;

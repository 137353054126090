// import { CircularProgress, Container } from '@material-ui/core'
import React from 'react'
import {Spinner} from '@ses-education/courses-components';

// const Spinner = props => {
//     return(
//         <Container>
//             <CircularProgress color="primary" />
//         </Container>
//     )
// }

export default Spinner;